import React from "react";
import AppHeader from "../Components/AppHeader";
import { useEffect } from "react";
import { Row } from "react-bootstrap";

const CancellationRefundPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AppHeader />
      <div className="content mb-5">
        <div className="container">
            <Row>
            <h2 className="about-content pt-3 pb-3"> Cancellation And Refund Policy </h2>
            </Row>
          <div className="row">
            <p>We accept all cancellations made before the cut-off time of our shipping slot (3 hours). </p>
            <p>Please reach out to our customer services at support@sabkamunafa.com for cancellation requests and refunds. We refund all payments made for orders placed as a store credit, which can be used by customers for future purchases.</p>
            <p>If we suspect any fraudulent transaction or transactions violating the terms of website use, we are at sole discretion to cancel such orders. These customers/accounts will be denied access from the use of site or purchase of products in the future. Refund processing time will be 15 days after refund/exchange request.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancellationRefundPolicy;
