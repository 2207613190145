import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { createPostComment } from "../reducers/commonReducer";

function CommentPopup(props) {
  const [show, setShow] = useState(false);
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <p className="mb-0 pt-1 float-right" onClick={handleShow}>
        <i className="bi bi-chat"></i> Comment
      </p>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Comment</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Label>Enter Comment</Form.Label>
          <Form.Control
            type="text"
            placeholder="Write your comment...."
            name="comment"
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
            }}
            as="textarea"
            rows={3}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() => {
              dispatch(
                createPostComment({
                  post: props?.postid,
                  comment,
                })
              );
              handleClose();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CommentPopup;
