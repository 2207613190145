import { createAsyncThunk, createSlice, createReducer } from "@reduxjs/toolkit";
import {
  postApi,
  messages,
  postApiFile,
  fileWithObjArray,
} from "../helpers/apiStructure";

const initialState = {
  localStorageCartItem: !!localStorage.getItem("cartItem")
    ? JSON.parse(localStorage.getItem("cartItem"))
    : [],

  // Product Mgt
  getProductsData: [],
  getCatWiseProductsData: [],
  getEnabledProductsData: [],
  getContactsData:[],
  getContactsBlank:[],
  // Categories Mgt
  getCategoriesData: [],
  getEnabledCategoriesData: [],
  // Users Mgt
  getUsersData: [],
  getEnabledUsersData: [],
  // Post Mgt
  getPostsData: [],
  getEnabledPostsData: [],
  getOwnPostsList: [],
  //FrontEndAPI
  getFrontEndPostsData: [],
  getFrontEndPostData: [],
  getFrontEndbrokersData: [],
  // Order Mgt
  getOrdersData: [],
  getOrdersBlank:[],
  addOrderStatus: "",
  getMyOrdersData: [],
  getMyOrdersBlank:[],
  getEnabledOrdersData: [],
  // Dashboard
  getDashboardData: [],
  // State
  getStatesData: [],
  // City
  getCitiesData: [],
  //settings
  getSettingsList: [],
  //market
  getMarketsList: [],
  marketSinglData: [],
  //profile
  userProfileData: [],
  userSelectProduct: [],
  //BarginList
  getBarginList: [],
  //market data
  getMarketlist: [],
  //common states
  showCreateGenPost: 1,
  selectedbtn: "",
  selectdTab: "",
  appMenuTab: "",
  appPopupTab:"",
  getFrontEndPostsBlank: [],
  loading:false,
  verifiedtranspotersList: [],
  alltranspotersList: [],
  getSubscriptionList: [],
  usersBlank:[],
  getNewsList:[]
};

var baseUrl = "http://localhost:3000";
// In case instead of localhost
if (window.location.host) {
  baseUrl = window.location.protocol + "//" + window.location.host;
}

// Only for local
// baseUrl = "http://sabkamunafa.havflyinvitation.com";

//commone local state update
export const menuBottomTab = createAsyncThunk("menuBottomTab", async (body) => {
  return body;
});
//popupclose
export const popUpCloseFoot = createAsyncThunk("popUpCloseFoot", async (body) => {
  return body;
});
//resetdata
export const restPosts = createAsyncThunk("restPosts", async (data) => {
  return [];
});
export const restRecord = createAsyncThunk("restRecord", async (data) => {
  return [];
});
//login checker
export const areYouLogin = createAsyncThunk(
  "areYouLogin",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/verify`, "post", body);
    return result;
  }
);
//FrontEnd api
export const getFrontEndPosts = createAsyncThunk(
  "getFrontEndPosts",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/posts`, "post", body);
    return result;
  }
);
export const getFrontEndPost = createAsyncThunk(
  "getFrontEndPost",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/post`, "post", body);
    return result;
  }
);
export const deletePost = createAsyncThunk(
  "deletePost",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/post/delete`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const getFrontEndbrokers = createAsyncThunk(
  "getFrontEndbrokers",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/brokers`, "post", body);
    return result;
  }
);
// Login
export const adminLogin = createAsyncThunk("adminLogin", async (body) => {
  const result = await postApi(`${baseUrl}/auth/login`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const sendOtp = createAsyncThunk("sendOtp", async (body) => {
  const result = await postApi(`${baseUrl}/api/otpSend`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const verifyOtp = createAsyncThunk("verifyOtp", async (body) => {
  const result = await postApi(`${baseUrl}/api/otpVerify`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const createNewPassword = createAsyncThunk(
  "createNewPassword",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/user/forgetPassword`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const userLogOut = createAsyncThunk("userLogOut", async (body) => {
  const result = await postApi(`${baseUrl}/auth/logout`, "get", body);
  return result;
});
export const getSingleNews = createAsyncThunk("getSingleNews", async (body) => {
  const result = await postApi(`${baseUrl}/api/getnews`, "post", body);
  return result;
});
export const getNews = createAsyncThunk("getNews", async (body) => {
  const result = await postApi(`${baseUrl}/api/news/list`, "post", body);
  return result;
});
export const deleteNews = createAsyncThunk("deleteNews", async (body) => {
  const result = await postApi(`${baseUrl}/api/news/delete/${body}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
export const updateNews = createAsyncThunk("updateNews", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/news/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const addNews = createAsyncThunk("addNews", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/news/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getAdvertisement = createAsyncThunk("getAdvertisement", async (body) => {
  const result = await postApi(`${baseUrl}/api/advertisement/list`, "post", body);
  return result;
});
export const getAdvertisementsWithoutPage = createAsyncThunk("getAdvertisementsWithoutPage", async (body) => {
  const result = await postApi(`${baseUrl}/api/advertisements`, "post", body);
  return result;
});
export const addAdvertisement = createAsyncThunk("addAdvertisement", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/advertisement/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateAdvertisement = createAsyncThunk("updateAdvertisement", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/advertisement/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const deleteAdvertisement = createAsyncThunk("deleteAdvertisement", async (body) => {
  const result = await postApi(`${baseUrl}/api/advertisement/delete/${body}`, "delete");
  await messages(result?.message, result?.status);
  return result;
});
export const deletePermanentUser = createAsyncThunk("deletePermanentUser", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/permanent/delete/${body?.id}`, "delete", body);
  await messages(result?.message, result?.status);
  return result;
});
export const userSignup = createAsyncThunk("userSignup", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/signup`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const loginSingup = createAsyncThunk("loginSingup", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/defaultsignup`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const transpoterSignup = createAsyncThunk(
  "transpoterSignup",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/signup/transpoter`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const verifiedtranspoters = createAsyncThunk(
  "verifiedtranspoters",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/verifed/Transpoter`,
      "post",
      body
    );
    return result;
  }
);
export const alltranspoters = createAsyncThunk(
  "alltranspoters",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/transpoters`, "post", body);
    return result;
  }
);
export const transporterVerification = createAsyncThunk(
  "transporterVerification",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/transpoter/varification`,
      "post",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
//Add new product Request
export const addNewProductRequest = createAsyncThunk(
  "addNewProductRequest",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/prodectrequest/create`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const newRequestProducts = createAsyncThunk(
  "newRequestProducts",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/prodectrequest/list`, "post", body);
    return result;
  }
);
export const newRequestProductDelete = createAsyncThunk("newRequestProductDelete", async (body) => {
  const result = await postApi(`${baseUrl}/api/prodectrequest/delete/${body?.id}`, "delete", body);
  await messages(result?.message, result?.status);
  return result;
});
//subscription
export const buySellSubscription = createAsyncThunk(
  "buySellSubscription",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/payment/buysubscription`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const paymentReturnUrl = createAsyncThunk(
  "paymentReturnUrl",
  async (body) => {
    console.log("body", body)
    const result = await postApi(`${baseUrl}/api/payment/returnurl/${body?.id}`, "post", body);
    // await messages(result?.message, result?.status);
    return result;
  }
);
export const subscriptionStatus = createAsyncThunk("subscriptionStatus", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/subscription/apply`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const paymentsList = createAsyncThunk(
  "paymentsList",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/payments`, "post", body);
    return result;
  }
);
export const updatePayment = createAsyncThunk(
  "updatePayment",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/payment/update/${body?.id}`, "put", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
//contact us 
export const contactForm = createAsyncThunk("contactForm", async (body) => {
  const result = await postApi(`${baseUrl}/api/contact/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getContacts = createAsyncThunk("getContacts", async (body) => {
  const result = await postApi(`${baseUrl}/api/contact/list`, "post", body);
  return result;
});
//subsctiption
export const activatePlan = createAsyncThunk("activatePlan", async (body) => {
  const result = await postApi(`${baseUrl}/api/subscription`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const getSubscriptionSetting = createAsyncThunk(
  "getSubscriptionSetting",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/subscription/setting`,
      "get",
      body
    );
    return result;
  }
);
//market data
export const getMarketDetails = createAsyncThunk(
  "getMarketDetails",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/market/list`, "get", body);
    return result;
  }
);
export const marketSingleRecord = createAsyncThunk(
  "marketSingleRecord",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/record/details`, "post", body);
    return result;
  }
);
//user Profile
export const userProfile = createAsyncThunk("userProfile", async (body) => {
  const result = await postApi(`${baseUrl}/api/getuser`, "get", body);
  return result;
});
export const updateUserProfile = createAsyncThunk(
  "updateUserProfile",
  async (body) => {
    const result = await fileWithObjArray(
      `${baseUrl}/api/user/updateprofile`,
      "put",
      body
    );
    await messages(result?.message, result?.status);
    return result;
  }
);
export const deleteUser = createAsyncThunk("deleteUser", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/delete`, "delete", body);
  await messages(result?.message, result?.status);
  return result;
});
export const deleteUserProduct = createAsyncThunk("deleteUserProduct", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/product/remove`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
// settings
export const getSettings = createAsyncThunk("getSettings", async (body) => {
  const result = await postApi(`${baseUrl}/api/settings`, "post", body);
  return result;
});
export const addSettings = createAsyncThunk("addSettings", async (body) => {
  const result = await postApi(`${baseUrl}/api/setting/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateSetting = createAsyncThunk("updateSetting", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/setting/update/${body?.id}`,
    "put",
    body
  );
  // await messages(result?.message, result?.status);
  return result;
});
// market
export const getMarkets = createAsyncThunk("getMarkets", async (body) => {
  const result = await postApi(`${baseUrl}/api/marketurls`, "get", body);
  return result;
});
// Products Mgt
export const getProducts = createAsyncThunk("getProducts", async (body) => {
  const result = await postApi(`${baseUrl}/api/products/main`, "post", body);
  return result;
});
export const getCatWiseProducts = createAsyncThunk(
  "getCatWiseProducts",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/cat-wise-products`,
      "get",
      body
    );
    return result;
  }
);
export const getEnabledProducts = createAsyncThunk(
  "getEnabledProducts",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/products`, "post", body);
    return result;
  }
);

export const addProduct = createAsyncThunk("addProduct", async (body) => {
  const result = await postApi(`${baseUrl}/api/product/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});

export const updateProduct = createAsyncThunk("updateProduct", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/product/update/${body?.id}`,
    "put",
    body
  );
  return result;
});
// End for products

// Categories Mgt
export const getCategories = createAsyncThunk(
  "getCategories/",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/categories/main`,
      "post",
      body
    );
    return result;
  }
);
export const getEnabledCategories = createAsyncThunk(
  "getEnabledCategories/",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/categories/`, "post", body);
    return result;
  }
);
export const addCategory = createAsyncThunk("addCategory", async (body) => {
  const result = await postApi(`${baseUrl}/api/category/add`, "post", body);
  return result;
});
export const updateCategory = createAsyncThunk(
  "updateCategory",
  async (body) => {
    const result = await postApi(
      `${baseUrl}/api/category/update/${body?.id}`,
      "put",
      body
    );
    return result;
  }
);
// End of categories

// User Mgt
//{"name":"U1002", "city":"jsjs","state": "Haryana","status":true}
export const getUsers = createAsyncThunk("getUsers", async (body) => {
  const result = await postApi(`${baseUrl}/api/users/main`, "post", body);
  return result;
});
export const agentUserVerified = createAsyncThunk(
  "agentUserVerified",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/select/agent`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
export const agentDetails = createAsyncThunk("agentDetails", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/agent/account/details`,
    "post",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
export const getEnabledUsers = createAsyncThunk(
  "getEnabledUsers",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/users`, "post", body);
    return result;
  }
);

export const addUser = createAsyncThunk("addUser", async (body) => {
  const result = await postApi(`${baseUrl}/api/user/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});

export const updateUser = createAsyncThunk("updateUser", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/user/update/${body?.id}`,
    "put",
    body
  );
  return result;
});

// Post Mgt
export const getPosts = createAsyncThunk("getPosts", async (body) => {
  const result = await postApi(`${baseUrl}/api/posts/main`, "post", body);
  return result;
});

export const getEnabledPosts = createAsyncThunk(
  "getEnabledPosts",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/posts`, "post", body);
    return result;
  }
);
export const getOwnPosts = createAsyncThunk("getOwnPosts", async (body) => {
  const result = await postApi(`${baseUrl}/api/ownposts`, "post", body);
  return result;
});
export const addPost = createAsyncThunk("addPost", async (body) => {
  const result = await postApiFile(`${baseUrl}/api/post/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});

export const updatePost = createAsyncThunk("updatePost", async (body) => {
  const result = await postApiFile(
    `${baseUrl}/api/post/update/${body?.id}`,
    "put",
    body
  );
  await messages(result?.message, result?.status);
  return result;
});
//create post comment
export const createPostComment = createAsyncThunk(
  "createPostComment",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/review/create`, "post", body);
    //await messages(result?.message, result?.status);
    return result;
  }
);
//bargins
export const addBargin = createAsyncThunk("addBargin", async (body) => {
  const result = await postApi(`${baseUrl}/api/bargin/create`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const updateBargin = createAsyncThunk("updateBargin", async (body) => {
  const result = await postApi(`${baseUrl}/api/bargin/edit`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
export const barginList = createAsyncThunk("barginList", async (body) => {
  const result = await postApi(`${baseUrl}/api/bargin`, "post", body);
  return result;
});
export const deleteBargin = createAsyncThunk("deleteBargin", async (body) => {
  const result = await postApi(`${baseUrl}/api/bargin/delete`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});
//post status like and dislike
export const postLikeDislike = createAsyncThunk(
  "postLikeDislike",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/user/likes`, "post", body);
    await messages(result?.message, result?.status);
    return result;
  }
);
// Orders Mgt
export const getOrders = createAsyncThunk("getOrders/", async (body) => {
  const result = await postApi(`${baseUrl}/api/orders/main`, "post", body);
  return result;
});

export const getEnabledOrders = createAsyncThunk(
  "getEnabledOrders",
  async (body) => {
    const result = await postApi(`${baseUrl}/api/orders/`, "post", body);
    return result;
  }
);
export const getMyOrders = createAsyncThunk("getMyOrders", async (body) => {
  const result = await postApi(`${baseUrl}/api/orders/ownlist`, "post", body);
  return result;
});
export const addOrder = createAsyncThunk("addOrder", async (body) => {
  const result = await postApi(`${baseUrl}/api/order/add`, "post", body);
  await messages(result?.message, result?.status);
  return result;
});

export const updateOrder = createAsyncThunk("updateOrder", async (body) => {
  const result = await postApi(
    `${baseUrl}/api/order/update/${body?.id}`,
    "put",
    body
  );
  return result;
});

export const getDashboard = createAsyncThunk("getDashboard", async (body) => {
  const result = await postApi(`${baseUrl}/api/dashboard`, "get", body);
  return result;
});
//user
// State
export const getStates = createAsyncThunk("getStates", async (body) => {
  const result = await postApi(`${baseUrl}/api/statecity/states`, "post", body);
  return result;
});

// Cities
export const getCities = createAsyncThunk("getCities", async (body) => {
  const result = await postApi(`${baseUrl}/api/statecity/cities`, "post", body);
  return result;
});


const userReducer = createReducer(
  {
    // counter: 0,
    // sumOfNumberPayloads: 0,
    // unhandledActions: 0,
  },
  (builder) => {
    builder
      // Login
      .addCase(adminLogin.fulfilled, (state, action) => {
        if (action.payload.status === 1) {
          localStorage.setItem("x-auth-token", action.payload.token);
          localStorage.setItem("userRole", action.payload?.me?.role?.slug);
          localStorage.setItem("userid", action.payload?.me?.id);
          localStorage.setItem("username", action.payload?.me?.username);
        }
      })
      .addCase(restPosts.fulfilled, (state, action) => {
        state.getFrontEndPostsData = [];
      })
      .addCase(restRecord.fulfilled, (state, action) => {
        state.getUsersData = [];
        state.getPostsData=[];
        state.getOrdersData=[];
        state.getContactsData=[];
        state.getMyOrdersData=[];
        state.getNewsList=[];
        state.getAdvertisementList=[];
      })
      //market
      .addCase(getMarketDetails.pending, state => {
        state.loading = true;
      })
      .addCase(getMarketDetails.fulfilled, (state, action) => {
        state.getMarketlist = action.payload?.list;
        state.loading = false;
      })
      .addCase(marketSingleRecord.fulfilled, (state, action) => {
        state.marketSinglData = action.payload?.details;
      })
      // Products list
      .addCase(getProducts.fulfilled, (state, action) => {
        state.getProductsData = action.payload;
      })
      .addCase(getCatWiseProducts.fulfilled, (state, action) => {
        state.getCatWiseProductsData = action.payload;
      })
      .addCase(getEnabledProducts.fulfilled, (state, action) => {
        state.getEnabledProductsData = action.payload;
      })
      // Categories list
      .addCase(getCategories.fulfilled, (state, action) => {
        state.getCategoriesData = action.payload;
      })
      .addCase(getEnabledCategories.fulfilled, (state, action) => {
        state.getEnabledCategoriesData = action.payload;
      })
      //transporter
      .addCase(verifiedtranspoters.fulfilled, (state, action) => {
        state.verifiedtranspotersList = action.payload?.list;
      })
      .addCase(alltranspoters.fulfilled, (state, action) => {
        state.alltranspotersList = action.payload?.list;
      })
      //subscription
      .addCase(getSubscriptionSetting.fulfilled, (state, action) => {
        state.getSubscriptionList = action.payload?.settings;
      })
      .addCase(paymentsList.fulfilled, (state, action) => {
        state.paymentsData = action.payload?.list;
      })
      // User list
      .addCase(getUsers.fulfilled, (state, action) => {
        if (!Array.isArray(state.getUsersData)) {
            state.getUsersData = [];
          }
        const newUserList = Array.isArray(action.payload.list) ? action.payload.list : [];
        state.getUsersData = [...state.getUsersData, ...newUserList];
        state.usersBlank = action.payload?.list;
      })
      .addCase(userSignup.fulfilled, (state, action) => {
        action.payload.status === 1 &&
          localStorage.setItem("userSignupStatus", action.payload.status);
      })
      .addCase(getEnabledUsers.fulfilled, (state, action) => {
        state.getEnabledUsersData = action.payload;
      })
      //get contact 
      .addCase(getContacts.fulfilled, (state, action) => {
        if (!Array.isArray(state.getContactsData)) {
          state.getContactsData = [];
        }
        const newUserList = Array.isArray(action.payload.list) ? action.payload.list : [];
        state.getContactsData = [...state.getContactsData, ...newUserList];
        state.getContactsBlank = action.payload?.list;
      })
      // Post list
      .addCase(getPosts.fulfilled, (state, action) => {
        if (!Array.isArray(state.getPostsData)) {
          state.getPostsData = [];
        }
        const newUserList = Array.isArray(action.payload.list) ? action.payload.list : [];
        state.getPostsData = [...state.getPostsData, ...newUserList];
        state.getPostsBlank = action.payload?.list;
      })
      .addCase(getEnabledPosts.fulfilled, (state, action) => {
        state.getEnabledPostsData = action.payload;
      })
      .addCase(getOwnPosts.pending,state => {
        state.loading = true;
      })
      .addCase(getOwnPosts.fulfilled, (state, action) => {
        state.getOwnPostsList = action.payload?.list;
        state.selectedbtn = action.meta.arg.type;
        if ("general" == action.meta.arg.type) {
          state.selectdTab = 1;
        } else {
          state.selectdTab = 0;
        }
        state.loading = false;
      })
      //barginList
      .addCase(barginList.pending, state => {
        state.loading = true;
      })
      .addCase(barginList.fulfilled, (state, action) => {
        state.getBarginList = action.payload?.bargin;
        state.loading = false;
      })
      //add order
      .addCase(getMyOrders.pending, state => {
        state.loading = true;
      })
      .addCase(getMyOrders.fulfilled, (state, action) => {
        if (!Array.isArray(state.getMyOrdersData)) {
          state.getMyOrdersData = [];
        }
        const newUserList = Array.isArray(action.payload.list) ? action.payload.list : [];
        state.getMyOrdersData = [...state.getMyOrdersData, ...newUserList];
        state.getMyOrdersBlank = action.payload?.list;
        state.loading = false;
      })
      //FrontEnd
      .addCase(getFrontEndPosts.pending, state => {
        state.loading = true;
      })
      .addCase(getFrontEndPosts.fulfilled, (state, action) => {
        state.getFrontEndPostsData = [
          ...state.getFrontEndPostsData,
          ...action.payload?.list,
        ];
        state.loading = false;
        state.getFrontEndPostsBlank = action.payload?.list;
        state.selectedbtn = action.meta.arg.type;
        if ("general" == action.meta.arg.type) {
          state.showCreateGenPost = 1;
        } else {
          state.showCreateGenPost = 0;
        }
      })
      .addCase(getNews.pending, state => {
        state.loading = true;
      })
      .addCase(getNews.fulfilled, (state, action) => {
        if (!Array.isArray(state.getNewsList)) {
          state.getNewsList = [];
        }
        const newNewsList = Array.isArray(action.payload.list) ? action.payload.list : [];
        state.getNewsList = [...state.getNewsList, ...newNewsList];
        state.getNewsBlank = action.payload?.list;
        state.loading = false;
      })
      .addCase(getAdvertisement.fulfilled, (state, action) => {
        if (!Array.isArray(state.getAdvertisementList)) {
          state.getAdvertisementList = [];
        }
        const newNewsList = Array.isArray(action.payload.list) ? action.payload.list : [];
        state.getAdvertisementList = [...state.getAdvertisementList, ...newNewsList];
        state.getAdvertisementBlank = action.payload?.list;
        state.loading = false;
      })
      .addCase(getAdvertisementsWithoutPage.fulfilled, (state, action) => {
        state.getAdvertisementsWithoutPageList = action.payload?.list;
      })
      .addCase(getSingleNews.fulfilled, (state, action) => {
        state.getSingleNewsData = action.payload?.news;
      })
      .addCase(getFrontEndPost.fulfilled, (state, action) => {
        state.getFrontEndPostData = action.payload?.post;
      })
      .addCase(getFrontEndbrokers.fulfilled, (state, action) => {
        state.getFrontEndbrokersData = action.payload?.list;
      })
      //newRequestProducts
      .addCase(newRequestProducts.fulfilled, (state, action) => {
        state.newRequestProductsList = action.payload;
      })
      //settings
      .addCase(getSettings.fulfilled, (state, action) => {
        state.getSettingsList = action.payload?.list;
      })
      //markets
      .addCase(getMarkets.fulfilled, (state, action) => {
        state.getMarketsList = action.payload?.list;
      })
      // Order list
      .addCase(getOrders.fulfilled, (state, action) => {
        if (!Array.isArray(state.getOrdersData)) {
          state.getOrdersData = [];
        }
        const newUserList = Array.isArray(action.payload.list) ? action.payload.list : [];
        state.getOrdersData = [...state.getOrdersData, ...newUserList];
        state.getOrdersBlank = action.payload?.list;
      })
      .addCase(getEnabledOrders.fulfilled, (state, action) => {
        state.getEnabledOrdersData = action.payload;
      })
      // Dashboard
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.getDashboardData = action.payload;
      })
      // State
      .addCase(getStates.fulfilled, (state, action) => {
        state.getStatesData = action.payload;
      })
      // Cities
      .addCase(getCities.fulfilled, (state, action) => {
        state.getCitiesData = action.payload;
      })
      //user Profile
      .addCase(userProfile.fulfilled, (state, action) => {
        state.userProfileData = action.payload?.user;
        state.userSelectProduct = action.payload?.selectedProducts;
      })
      //common local states
      .addCase(menuBottomTab.fulfilled, (state, action) => {
        state.appMenuTab = action.payload;
      })
      .addCase(popUpCloseFoot.fulfilled, (state, action) => {
        state.appPopupTab = action.payload;
      });
  }
);

export default userReducer;
