import React, { useEffect } from "react";
import { Col, Button, Row } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import SBK from "../../images/sbka-munafa.png";
import { useDispatch } from "react-redux";
import { createNewPassword } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  phone: yup.string().required(),
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], 'Must match "password" field value')
    .required(),
});

const CreatePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const apiresp = async (values) => {
    const apiResponse = await dispatch(createNewPassword(values));
    if (apiResponse?.payload?.status === 1) {
      return setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
    return null;
  };

  return (
    <>
      <div className="content login-bg">
        <div className="row">
          <div className="col-md-5  login-form p-4">
            <div className="text-center mt-2">
              <img src={SBK} alt="logo" />
            </div>
            <div className="text-center">
              <p>
                <b> Create New Password </b>
              </p>
            </div>

            <Formik
              validationSchema={schema}
              onSubmit={(values, { resetForm }) => {
                apiresp(values);
                resetForm({ values: "" });
              }}
              initialValues={{
                phone: localStorage.getItem("phone"),
                password: "",
                confirmPassword: "",
              }}
            >
              {({ handleSubmit, handleChange, values, touched, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="col-12  mb-4">
                    <Form.Group as={Col} md="12">
                      <Form.Label>New Password</Form.Label>
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-lock-fill"></i>
                        </div>
                        <Form.Control
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                          className="form-control"
                          placeholder="Password"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12  mb-4">
                    <Form.Group as={Col} md="12">
                      <Form.Label>Confirm Password</Form.Label>
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-lock-fill"></i>
                        </div>
                        <Form.Control
                          type="password"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          isInvalid={!!errors.confirmPassword}
                          className={
                            errors.confirmPassword &&
                            touched.confirmPassword &&
                            "errors"
                          }
                          placeholder="Confirm Password"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmPassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12 text-center mt-3">
                    <Button className="btn-color w-100" type="submit">
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePassword;
