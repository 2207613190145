import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { barginList } from "../reducers/commonReducer";

function Confirmation(props) {
  const dispatch = useDispatch();
  return (
    <>
      <i
        onClick={props?.handleShow}
        className="bi bi-trash3 p-1 cursor-pointer"
      ></i>

      <Modal show={props.showhide} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this item?</Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={props.handleClose}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={async () => {
              await dispatch(props?.deleteApi({ id: props?.id }));
              await dispatch(barginList());
              props.handleClose();
            }}
          >
            Yes, Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Confirmation;
