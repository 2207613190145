import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSingleNews } from "../../reducers/commonReducer";
import moment from "moment";
import dummy from "../../images/dummy.png"
import {  Col, Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import AppHeader from "../../Components/AppHeader";

export default function SingleNews() {
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  const { id } = useParams();
  useEffect(() => {
    dispatch(getSingleNews({
        id: id,
    }));
  },[])
  const { getSingleNewsData } = useSelector((state) => state.commonReducer);

  return (
    <>
    <AppHeader/>
    <Container>
    <div className="row">
              <React.Fragment>
              <Col md={12} className="align-item mt-3 mb-3">
                        <div className="border">
                          <div className="news-img">
                            <img className="w-100" src={getSingleNewsData?.urlToImage ? `/${getSingleNewsData?.urlToImage}` : `/${dummy}`} alt={getSingleNewsData?.title} />
                          </div>
                          <div className="blog-content p-3">
                            <div className="d-flex">
                              <ul className="list-inline">
                                <li className="list-inline-item">
                                  <button className="p-1 news-button border">
                                    {moment(getSingleNewsData?.createdAt).format("MMM Do YY")}
                                  </button>
                                </li>
                                <li className="list-inline-item">{getSingleNewsData?.author}</li>
                              </ul>
                            </div>
                            <div className="news-heading">
                                <h6 >
                                  {getSingleNewsData?.title}
                                </h6>
                                <p>{
                                  getSingleNewsData?.description
                                  }</p>
                            </div>
                          </div>
                        </div>
                      </Col>
              </React.Fragment>
      </div>
      </Container>
    </>
  );
}
