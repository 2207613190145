import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getProducts, getEnabledCategories, getNews, updateNews, addNews, restRecord, deleteNews } from "../../reducers/commonReducer";
import NewsAction from "./NewsAction";
import moment from "moment";

const NewsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(restRecord())
    dispatch(getNews());
    dispatch(getEnabledCategories());
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
  }, []);
  const { getNewsList, getNewsBlank } = useSelector((state) => state.commonReducer);
  const [page, setPage] = useState(1);
  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table"> 
        <Col md={12}>
        <h3>News List</h3>
          </Col> 
          <hr/>
          <Col md={6}>
            <NewsAction popupTitle={"Add"} api={addNews}/>
          </Col>
          <Col className="text-end" md={6}>
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
                onChange={(e)=>{
                   dispatch(getProducts({
                     search:e.target.value.charAt(0).toUpperCase() +
                     e.target.value.slice(1)
                   }))
                }}
              />
          </Col>
        </Row>
          
          <div className="overflow-auto">
        <Table striped bordered hover size="sm" className="overflow-scroll">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Author</th>
              <th>Date</th>
              <th>Title</th>
              <th>Img</th>
              <th>Description</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!!getNewsList ?
              getNewsList?.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td>{index + 1}.</td>
                    <td>
                      {data?.author}
                    </td>
                    <td>{moment(data?.createdAt).format("LL")}</td>
                    <td>{data?.title}</td>
                    <td><img src={`/${data?.image}`}/></td>
                   <td>
                    {data?.description}
                    </td>
                    <td>
                      <NewsAction
                        api={updateNews}
                        popupTitle="Update"
                        id={data?.id}
                        description={data?.description}
                        author={data?.author}
                        title={data?.title}
                      />
                      <Button onClick={async() => {
                        await dispatch(deleteNews(data?.id))
                        await dispatch(restRecord())
                        await dispatch(getNews())
                        }} variant="danger"><i className="bi bi-trash3-fill"></i></Button>
                    </td>
                  </tr>
                );
              }) : 'No record found'} 
          </tbody>
        </Table> 
        {!!getNewsBlank && getNewsBlank.length > 0 ? (
                <Row>
                  <Button
                    className="w-100"
                    onClick={() => {
                      setPage(page + 1);
                      dispatch(getNews({
                        page: page + 1,
                      }));
                    }}
                  >
                    Load More
                  </Button>
                </Row>
              ) : (
                <p className="text-center">
                  <b>No record found 😔.</b>
                </p>
              )}
        </div>
      </div>
    </>
  );
};

export default NewsList;
