import React from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userLogOut } from "../reducers/commonReducer";
import Button from "react-bootstrap/Button";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import AP from "../images/weblogo.png";

const HeaderMenu = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = async () => {
    const apiResponse = await dispatch(userLogOut());
    if (apiResponse?.payload?.status === 1) {
      localStorage.clear();
      return navigate("/");
    }
    return null;
  };
  return (
    <>
      <div className="container">
        <div className="row mt-3 mb-3 align-baseline">
          <div className="col-6">
            <Navbar.Brand>
              <NavLink to="/users">
                <img src={AP} />
              </NavLink>
            </Navbar.Brand>
          </div>
          <div className="col-6 text-end">
            <Button type="submit" className="button-color" onClick={logout}>
              Log Out{" "}
            </Button>
          </div>
        </div>
      </div>

      <Navbar
        collapseOnSelect
        className="header-bg-color"
        expand="lg"
        variant="dark"
      >
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto menu-bar dashboard">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink to="/users">
                    <i className="bi bi-people"></i>
                    Users
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/payments">
                  <i className="bi bi-cash"></i>
                    Payments
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/products">
                    <i className="bi bi-bag-dash"></i>
                    Products
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/request-porducts">
                    <i className="bi bi-bag-dash"></i>
                    Request Product
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/categories">
                    <i className="bi bi-bookmark-plus"></i>
                    Categories
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/posts">
                    <i className="bi bi-sticky"></i>
                    Posts
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/orders">
                    <i className="bi bi-handbag"></i>
                    Orders
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/contact-list">
                    <i className="bi bi-handbag"></i>
                    Contacts
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/news-list">
                    <i className="bi bi-handbag"></i>
                    News
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/advertisement">
                    <i className="bi bi-handbag"></i>
                    Advertisement
                  </NavLink>
                </li>
              </ul>
            </Nav>
            <Nav>
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item"></li>
                <li className="nav-item settings">
                  <NavLink to="/settings">
                    Settings{" "}
                    <span>
                      <i className="bi bi-gear-fill"></i>
                    </span>
                  </NavLink>
                </li>
              </ul>
            </Nav>
          </Navbar.Collapse>
        {/* </Container> */}
      </Navbar>
    </>
  );
};

export default HeaderMenu;
