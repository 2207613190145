import React, { useEffect } from "react";
import { Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import SBK from "../../images/sbka-munafa.png";
import { useDispatch } from "react-redux";
import { adminLogin, loginSingup } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  phone: yup.number().required().min(1000000000, 'Phone number must be exactly 10 digits').max(9999999999, 'Phone number must be exactly 10 digits'),
});

const SignupLoginWithPhone = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = async (values) => {
    const apiResponse = await dispatch(loginSingup(values));
    if (apiResponse?.payload?.status === 1) {
      localStorage.setItem("phone", values?.phone);
      return setTimeout(() => {
        apiResponse?.payload?.user === 1 ?  navigate("/login") :  navigate("/verify-otp");
      }, 1000);
    }
    return null;
  };

  return (
    <>
      <div className="content login-bg">
        <div className="row">
          <div className="col-md-5  login-form p-4">
            <div className="text-center mt-2">
              <img src={SBK} alt="logo" />
            </div>
            <Formik
              validationSchema={schema}
              onSubmit={(values, { resetForm }) => {
                login(values);
                // resetForm({ values: "" });
              }}
              initialValues={{
                phone: "",
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="col-12 mt-5">
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-phone-fill"></i>
                        </div>
                        <Form.Control
                          type="number"
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          isInvalid={!!errors.phone}
                          className="form-control"
                          placeholder="Enter Phone"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.phone}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12 text-center mt-4">
                    <Button className="btn-color w-100" type="submit">
                      Continue
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>

          <div className="col-12 text-center">
            <p className="text-color-brown">
            <b>Sabka Munafa</b> - An app for buyers, sellers, and brokers. We provide <b>live market rates for KLCE and CBOT, along with real-time tracking</b>.
            </p>
            <p className="text-success">
            <b>You are already registered, so please</b><br/> <Link to="/login"><Button variant="success">Login</Button></Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignupLoginWithPhone;
