import React, { useEffect, useRef } from "react";
import AppHeader from "../../Components/AppHeader";
import { Container, Row } from "react-bootstrap";
import "../../market.css";
import { useDispatch, useSelector } from "react-redux";
import { marketSingleRecord } from "../../reducers/commonReducer";
import moment from "moment";
import { useParams } from "react-router-dom";
import { getMonthName } from "../../const";

const MarketProductDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(
      marketSingleRecord({
        _id: id,
      })
    );
  }, []);
  const { marketSinglData } = useSelector((state) => state.commonReducer);
  return (
    <>
      <AppHeader />
      <Container>
        <Row className="mt-4 px-2">
          <table className="table responsive ">
            <thead>
              <tr>
                <td>Exnm</td>
                <td>Exchange</td>
                <td>Contract</td>
                <td>Last</td>
                <td>Buy</td>
                <td>Sell</td>
                <td>Chg</td>
                <td>Prev Sett</td>
                <td>Open</td>
                <td>High</td>
                <td>Low</td>
                <td>Volume</td>
                <td>Time</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Exnm">
                  <b>{marketSinglData?.exnm}</b>
                </td>
                <td data-label="Exchange">{marketSinglData?.nm}</td>
                <td data-label="Contract">
                  {" "}
                  {marketSinglData?.mnth > 0
                    ? getMonthName(Number(marketSinglData?.mnth))
                    : ""}{" "}
                  {marketSinglData?.yr}
                </td>
                <td
                  data-label="Last"
                  className={`${
                    (Number(marketSinglData?.LAST) ===
                      Number(marketSinglData?.settlementprice) &&
                      "bg-secondary") ||
                    (Number(marketSinglData?.settlementprice) <
                      Number(marketSinglData?.LAST) &&
                      "bg-success") ||
                    (Number(marketSinglData?.settlementprice) >
                      Number(marketSinglData?.LAST) &&
                      "bg-danger")
                  }`}
                >
                  <span className="text-light">{marketSinglData?.LAST}</span>
                </td>
                <td
                  data-label="Buy"
                  className={`${
                    (Number(marketSinglData?.buy) ===
                      Number(marketSinglData?.settlementprice) &&
                      "bg-secondary") ||
                    (Number(marketSinglData?.settlementprice) <
                      Number(marketSinglData?.buy) &&
                      "bg-success") ||
                    (Number(marketSinglData?.settlementprice) >
                      Number(marketSinglData?.buy) &&
                      "bg-danger")
                  }`}
                >
                  <span className="text-light">{marketSinglData?.buy}</span>
                </td>
                <td
                  data-label="Sell"
                  className={`${
                    (Number(marketSinglData?.sell) ===
                      Number(marketSinglData?.settlementprice) &&
                      "bg-secondary") ||
                    (Number(marketSinglData?.settlementprice) <
                      Number(marketSinglData?.sell) &&
                      "bg-success") ||
                    (Number(marketSinglData?.settlementprice) >
                      Number(marketSinglData?.sell) &&
                      "bg-danger")
                  }`}
                >
                  <span className="text-light">{marketSinglData?.sell}</span>
                </td>
                <td
                  data-label="Chg"
                  className={`${
                    Number(marketSinglData?.chgn) > 0
                      ? "bg-success"
                      : "bg-danger"
                  }`}
                >
                  <span className="text-light">{marketSinglData?.chgn}</span>
                </td>
                <td data-label="Prev Sett">
                  {marketSinglData?.settlementprice}
                </td>
                <td
                  data-label="Open"
                  className={`${
                    (Number(marketSinglData?.OPEN) ===
                      Number(marketSinglData?.settlementprice) &&
                      "bg-secondary") ||
                    (Number(marketSinglData?.settlementprice) <
                      Number(marketSinglData?.OPEN) &&
                      "bg-success") ||
                    (Number(marketSinglData?.settlementprice) >
                      Number(marketSinglData?.OPEN) &&
                      "bg-danger")
                  }`}
                >
                  <span className="text-light">{marketSinglData?.OPEN}</span>
                </td>
                <td
                  data-label="High"
                  className={`${
                    (Number(marketSinglData?.high) ===
                      Number(marketSinglData?.settlementprice) &&
                      "bg-secondary") ||
                    (Number(marketSinglData?.settlementprice) <
                      Number(marketSinglData?.high) &&
                      "bg-success") ||
                    (Number(marketSinglData?.settlementprice) >
                      Number(marketSinglData?.high) &&
                      "bg-danger")
                  }`}
                >
                  <span className="text-light">{marketSinglData?.high}</span>
                </td>
                <td
                  data-label="Low"
                  className={`${
                    (Number(marketSinglData?.low) ===
                      Number(marketSinglData?.settlementprice) &&
                      "bg-secondary") ||
                    (Number(marketSinglData?.settlementprice) <
                      Number(marketSinglData?.low) &&
                      "bg-success") ||
                    (Number(marketSinglData?.settlementprice) >
                      Number(marketSinglData?.low) &&
                      "bg-danger")
                  }`}
                >
                  <span className="text-light">{marketSinglData?.low}</span>
                </td>
                <td data-label="Volume">{marketSinglData?.volume}</td>
                <td data-label="Time">
                  {moment(marketSinglData?.entrydate).format(
                    "DD-MM-YYYY HH:mm"
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </Row>
      </Container>
    </>
  );
};

export default MarketProductDetails;
