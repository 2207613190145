import React, { useEffect } from "react";
import { Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import SBK from "../../images/sbka-munafa.png";
import {
  getCatWiseProducts,
  getFrontEndbrokers,
  getStates,
  transpoterSignup,
} from "../../reducers/commonReducer";
import AppHeader from "../../Components/AppHeader";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string(),
  phone: yup.string().min(10).max(10).required(),
  email: yup.string().email("Invalid email address").required(),
  gst: yup
    .string()
    .matches(/^\d{15}$/, "GST must be exactly 15 digits")
    .required("GST is required"),
  firmname: yup.string().required(),
  address: yup.string().required(),
  city: yup.string().required(),
  state: yup.string().required(),
  zipcode: yup.string().required(),
});

const TranspoterSignup = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getStates());
    dispatch(getFrontEndbrokers());
    dispatch(getCatWiseProducts());
  }, []);

  const apiresp = async (values) => {
    const apiResponse = await dispatch(transpoterSignup(values));
    if (apiResponse?.payload?.status === 1) {
      return setTimeout(() => {
        navigate("/");
      }, 1000);
    }
    return null;
  };

  return (
    <>
      <AppHeader />
      <div className="content sign-up">
        <div className="row sign-up">
          <div className="col-md-5  login-form p-4">
            <div className="text-center mt-2">
              <img src={SBK} alt="logo" />
            </div>
            <div className="text-center">
              <p>
                <b> Transpoter Sign Up </b>
              </p>
            </div>

            <Formik
              validationSchema={schema}
              onSubmit={(values, { resetForm }) => {
                apiresp(values);
                setTimeout(() => {
                  localStorage.getItem("userSignupStatus") === "1" &&
                    navigate("/");
                }, 2000);
              }}
              initialValues={{
                firstName: "",
                lastName: "",
                phone: "",
                email: "",
                gst: "",
                firmname: "",
                address: "",
                city: "",
                state: "",
                zipcode: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                setFieldValue,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="col-12 mt-4">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-person-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          isInvalid={!!errors.firstName}
                          className="form-control"
                          placeholder="FirstName"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-12 mt-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-person-check-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          isInvalid={!!errors.lastName}
                          className="form-control"
                          placeholder="LastName"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-12 mt-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-envelope-fill"></i>
                        </div>
                        <Form.Control
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                          className="form-control"
                          placeholder="Email"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12 mt-2 mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-phone-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          isInvalid={!!errors.phone}
                          className="form-control"
                          placeholder="Phone"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.phone}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12  mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-piggy-bank-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="gst"
                          value={values.gst}
                          onChange={handleChange}
                          isInvalid={!!errors.gst}
                          className="form-control"
                          placeholder="GST"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.gst}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12  mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-building-fill-check"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="firmname"
                          value={values.firmname}
                          onChange={handleChange}
                          isInvalid={!!errors.firmname}
                          className="form-control"
                          placeholder="Enter FirmName"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.firmname}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12  mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-geo-alt-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="address"
                          value={values.address}
                          onChange={handleChange}
                          isInvalid={!!errors.address}
                          className="form-control"
                          placeholder="Address"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.address}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12  mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-geo-alt-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="city"
                          value={values.city}
                          onChange={handleChange}
                          isInvalid={!!errors.city}
                          className="form-control"
                          placeholder="City"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.city}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12  mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-geo-alt-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="zipcode"
                          value={values.zipcode}
                          onChange={handleChange}
                          isInvalid={!!errors.zipcode}
                          className="form-control"
                          placeholder="PIN Code"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.zipcode}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12  mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-geo-alt-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="state"
                          value={values.state}
                          onChange={handleChange}
                          isInvalid={!!errors.state}
                          className="form-control"
                          placeholder="State"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.state}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-12 text-center">
                    <Button className="btn-color w-100" type="submit">
                      Sign Up Transpoter
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="col-12 text-center">
            <p>
              <Link to={"/"}>
                <b>
                  <i className="bi bi-arrow-left"></i> Back to Home
                </b>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TranspoterSignup;
