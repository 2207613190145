import React, { useRef } from "react";
import "./app.css";
import { Routes, Route } from "react-router-dom";
import LoginPage from "./Pages/LoginPage";
import { ToastContainer } from "react-toastify";
import NotFoundPage from "./Components/NotFoundPage";
import CategoryList from "./Pages/Category/List";
import ProductsList from "./Pages/Product/List";
import UserList from "./Pages/User/List";
import PostList from "./Pages/Post/List";
import OrderList from "./Pages/Order/List";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Homepage from "./Pages/HomePage/Homepage";
import Market from "./Pages/Market/Market";
import MyOrders from "./Pages/My-Order/My-Order";
import Profile from "./Pages/Profile/Profile";
import Contactus from "./Pages/Contact/Contact";
import AboutUs from "./Pages/About/About";
import Applogin from "./Pages/Applogin/AppLogin";
import SignUp from "./Pages/SignUp/Signup";
import BlogDetails from "./Pages/BlogDetails";
import NewsDetails from "./Pages/NewsDetails";
import Footer from "./Pages/Footer/Footers";
import { useLocation } from "react-router-dom";
import News from "./Pages/News/News";
import MarketReport from "./Pages/Market/MarketReport";
import MainNews from "./Pages/News/MainNews";
import BarginList from "./Pages/Bargin/BarginList";
import MyPosts from "./Pages/Post/MyPosts";
import OtpSend from "./Pages/Applogin/OtpSend";
import OTPverify from "./Pages/Applogin/OTPverify";
import CreatePassword from "./Pages/Applogin/CreatePassword";
import TranspoterSignup from "./Pages/SignUp/TranspoterSignup";
import TransporterList from "./Pages/Transporter/TransporterList";
import AllTransporters from "./Pages/Transporter/AllTransporters";
import RequestNewProduct from "./Pages/Product/RequestNewProduct";
import AgentDetails from "./Pages/User/AgentDetails";
import Settings from "./Pages/Settings/Settings";
import MarketProductDetails from "./Pages/Market/MarketProductDetails";
import Disclaimer from "./Pages/Disclaimer";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsOfUse from "./Pages/TermsOfUse";
import CancellationRefundPolicy from "./Pages/CancellationRefundPolicy";
import ShippingDeliveryPolicy from "./Pages/ShippingDeliveryPolicy";
import PaymentDetails from "./Pages/Contact/PaymentDetails";
import ContactList from "./Pages/Contact/ContactList";
import Refferal from "./Pages/Profile/Refferal";
import NewsList from "./Pages/News/NewsList";
import SingleNews from "./Pages/News/SingleNews";
import RequestProducts from "./Pages/Product/RequestProducts";
import PaymentResp from "./Pages/Subscription/PaymentResp";
import SubscriptionNew from "./Pages/Subscription/SubscriptionNew";
import Payments from "./Pages/Payments/Payments";
import Advertisements from "./Pages/Advertisement/Advertisements";
import SignupLoginWithPhone from "./Pages/Applogin/SignupLoginWithPhone";

function App() {
  const location = useLocation();
  const width = useRef(window.innerWidth);
  const showFooter =
    // location.pathname !== "/" &&
    location.pathname !== "/dashboard" &&
    location.pathname !== "/users" &&
    location.pathname !== "/products" &&
    location.pathname !== "/categories" &&
    location.pathname !== "/posts" &&
    location.pathname !== "/orders" &&
    location.pathname !== "/login" &&
    location.pathname !== "/login-singup" &&
    location.pathname !== "/signup" &&
    location.pathname !== "/sent-otp" &&
    location.pathname !== "/verify-otp" &&
    location.pathname !== "/create-new-password" &&
    location.pathname !== "/agent-details/:id";
  return (
    <>
      <Routes>
        {/* web routes start*/}
        <Route path="/admin-login" element={<LoginPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/products" element={<ProductsList />} />         
        <Route path="/contact-list" element={<ContactList />} />
        <Route path="/users" element={<UserList />} />
        <Route path="/agent-details/:id" element={<AgentDetails />} />
        <Route path="/all-transporter" element={<AllTransporters />} />
        <Route path="/categories" element={<CategoryList />} />
        <Route path="/payments" element={<Payments />} />
        <Route path="/request-porducts" element={<RequestProducts />} />
        <Route path="/posts" element={<PostList />} />
        <Route path="/orders" element={<OrderList />} />
        <Route path="*" element={<NotFoundPage />} />

        {/* // mobile-app router  */}

        <Route path="/" element={<Homepage />} />
        {/* <Route path="/subscription" element={isIOS === true ? <Homepage /> : <Subscription />} /> */}
        <Route path="/payment/returnurl" element={<PaymentResp />} />
        <Route path="/subscription" element={<SubscriptionNew />} />
        {/* <Route path="/subscription" element={<Subscription />} /> */}
        <Route path="/refferal" element={<Refferal />} />
        <Route path="/download-app" element={<Refferal />} />
        <Route path="/my-orders" element={<MyOrders />} />
        <Route path="/my-posts" element={<MyPosts />} />
        <Route path="/my-profile" element={<Profile />} />
        <Route path="/request-new-product" element={<RequestNewProduct />} />
        <Route path="/market" element={<Market />} />
        <Route path="/market/:id" element={<MarketProductDetails />} />
        <Route path="/news" element={<MainNews />} />
        <Route path="/news/:id" element={<SingleNews />} />
        <Route path="/news-list" element={<NewsList />} />
        <Route path="/advertisement" element={<Advertisements />} />
        <Route path="/market-report" element={<MarketReport />} />
        <Route path="/contact-us" element={<Contactus />} />
        <Route path="/payment-details" element={<PaymentDetails />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/login" element={<Applogin />} />
        <Route path="/login-singup" element={<SignupLoginWithPhone />} />
        <Route path="/sent-otp" element={<OtpSend />} />
        <Route path="/verify-otp" element={<OTPverify />} />
        <Route path="/create-new-password" element={<CreatePassword />} />
        <Route path="/transpoters" element={<TransporterList />} />
        <Route path="/transpoter-signup" element={<TranspoterSignup />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/blog-details" element={<BlogDetails />} />
        <Route path="/news" element={<News />} />
        <Route path="/news-details" element={<NewsDetails />} />
        <Route path="/bargins" element={<BarginList />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/cancellation-and-refund" element={<CancellationRefundPolicy />} />
        <Route path="/shipping-delivery" element={<ShippingDeliveryPolicy />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/terms_of_use" element={<TermsOfUse />} />
        {/* // my router  */}
      </Routes>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      {/* <Footer /> */}
      {width.current < 668 && showFooter && <Footer />}
    </>
  );
}
export default App;
