import React from "react";
import "../app.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { adminLogin } from "../reducers/commonReducer";
import SABKAMUNAFA from "../images/first-bargain.png";
import { Row } from "react-bootstrap";
import SBK from "../images/first-dial.jpg";

// Validation by Yup
const schema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});

// Login Page
const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let dateYear = new Date();
  const login = async (values) => {
    const apiResponse = await dispatch(adminLogin(values));
    if (
      apiResponse?.payload?.status === 1 &&
      localStorage.getItem("x-auth-token") &&
      localStorage.getItem("userRole") == "admin"
    ) {
      return setTimeout(() => {
        navigate("/users");
      }, 1000);
    }
    return null;
  };
  return (
    <>
      <div className="">
        <Row>
          <Col md={6} className="login-form p-5">
            <div className="form-logo text-center">
              <img src={SABKAMUNAFA} />
            </div>
            <div className="text-center mt-4">
              <h4>
                Welcome <span className="back-color"> Back !</span>
              </h4>
            </div>
            <div className="text-center">
              <p>Sign in to panel</p>
            </div>

            <Formik
              validationSchema={schema}
              onSubmit={(values, { resetForm }) => {
                login(values);
                resetForm({ values: "" });
              }}
              initialValues={{
                username: "",
                password: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="col-12 mt-5">
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-person-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="username"
                          value={values.username}
                          onChange={handleChange}
                          isInvalid={!!errors.username}
                          className="form-control"
                          placeholder="UserName"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.username}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-12 mt-4">
                    <Form.Group as={Col} md="12" controlId="validationCustom03">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-lock-fill"></i>
                        </div>
                        <Form.Control
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                          className="form-control"
                          placeholder="Password"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12 text-center">
                    <Button
                      type="submit"
                      className=" button-color login-btn px-4 mt-4"
                    >
                      Log in
                    </Button>
                  </div>

                  <div className="col-12 text-center mt-4">
                    <p>
                      All Rights Reserved by 💗 SABKAMUNAFA ©{" "}
                      {dateYear.getFullYear()}
                    </p>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
          <Col md={6}>
            <img className="w-100 h-100vh" src={SBK}/>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LoginPage;
