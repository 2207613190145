import React, { useEffect } from "react";
import { useNavigate, NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../Components/Header";
import Cards from "../../Components/Card";
import { getDashboard } from "../../reducers/commonReducer";

const Dashboard = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDashboard());
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
  }, []);
  const { getDashboardData } = useSelector((state) => state.commonReducer);

  return (
    <>
      <Header />
      <div className="container content mt-3">
        <div className="row">
          <div className="col-md-4">
            <NavLink to="/posts">
              <Cards
                title="Total Sell Post"
                icon="bi-mailbox"
                background="bg-c-green"
                subtitle={getDashboardData?.sell}
              />
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink to="/posts">
              <Cards
                title="Total General Post"
                icon="bi-file-post-fill"
                background="bg-c-blue"
                subtitle={getDashboardData?.general}
              />
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink to="/posts">
              <Cards
                title="Total Buy Post"
                icon="bi-shield-plus"
                background="bg-c-green"
                subtitle={getDashboardData?.buy}
              />
            </NavLink>
          </div>

          <div className="col-md-4">
            <NavLink to="/users">
              <Cards
                title="Total Users"
                icon="bi-people"
                background="bg-c-blue"
                subtitle={getDashboardData?.users}
              />
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink to="/users">
              <Cards
                title="Total Brokers"
                icon="bi-person-gear"
                background="bg-c-green"
                subtitle={getDashboardData?.brokers}
              />
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink to="/users">
              <Cards
                title="Total Traders"
                icon="bi-cart-plus"
                background="bg-c-blue"
                subtitle={getDashboardData?.traders}
              />
            </NavLink>
          </div>

          <div className="col-md-4">
            <NavLink to="/users">
              <Cards
                title="Total Transpoters"
                icon="bi-bus-front"
                background="bg-c-green"
                subtitle={getDashboardData?.transpoter}
              />
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink to="/orders">
              <Cards
                title="Total Orders"
                icon="bi-bag-plus"
                background="bg-c-blue"
                subtitle={getDashboardData?.orders}
              />
            </NavLink>
          </div>
          <div className="col-md-4">
            <NavLink to="/products">
              <Cards
                title="All Products"
                icon="bi-clipboard-plus"
                background="bg-c-green"
                subtitle={getDashboardData?.products}
              />
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
