import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getNews, restRecord } from "../../reducers/commonReducer";
import moment from "moment";
import dummy from "../../images/dummy.png"
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function News() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(restRecord());
    dispatch(getNews());
  },[])
  const { getNewsList, loading } = useSelector((state) => state.commonReducer);
  return (
    <>
    {(loading === undefined || loading) ?  <h3 className="text-info text-center mt-5">
       Loading.....
     </h3> : 
      <div className="row">
        {
          !!getNewsList && getNewsList?.map((data, index) =>{
            return(
              <React.Fragment key={index}>
              <div className="col-md-4 align-item mt-3 mb-3">
                        <div className="border">
                          <div className="news-img">
                            <img src={data?.urlToImage ? data?.urlToImage : dummy} alt={data?.title} />
                          </div>
                          <div className="blog-content p-3">
                            <div className="d-flex">
                              <ul className="list-inline">
                                <li className="list-inline-item">
                                  <button className="p-1 news-button border">
                                    {moment(data?.createdAt).format("MMM Do YY")}
                                  </button>
                                </li>
                                <li className="list-inline-item">{data?.author}</li>
                              </ul>
                            </div>
                            <div className="news-heading">
                                <h6 >
                                  {data?.title}
                                </h6>
                                <p>{data?.description.substring(0,100)}...</p>
                                  {data?.url ? <a href={`${data?.url}`} target="_blank"> <Button variant="info">Read More</Button>
                                  </a> : <Link to={`/news/${data?.id}`}><Button variant="info">Read More</Button></Link> }
                            </div>
                          </div>
                        </div>
                      </div>
              </React.Fragment>
            )
          })
        }
        
      </div>}
    </>
  );
}
