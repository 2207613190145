import { useNavigate } from "react-router-dom";
import {Fragment} from 'react';
import {
  createPostComment,
  getFrontEndPosts,
  menuBottomTab,
  popUpCloseFoot,
  restPosts,
} from "../reducers/commonReducer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Button, Col, Row } from "react-bootstrap";
import React, { useRef, useState } from "react";
import market from "../images/market.jpg";
import ads from "../images/ads.jpg";
import CreatePost from "../Pages/HomePage/CreatePost";
import GeneralPost from "../Pages/HomePage/GeneralPost";
import userprofile from "../images/userprofile.png";
import CommentPopup from "./CommentPopup";

function PostTabs() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("");
  const [postType, setPostType] = useState(false);
  const width = useRef(window.innerWidth);
  const dispatch = useDispatch();
  const { getFrontEndPostsData, getAdvertisementsWithoutPageList, appPopupTab,selectedbtn, showCreateGenPost } =
    useSelector((state) => state.commonReducer);
  let desc;
  const queryParameters = new URLSearchParams(window.location.search);
  const postTypeName = queryParameters.get("postType");
  // Function to intersperse elements from arr and arr2
  function intersperse(arr1, arr2) {
    let narr = [];
    let arr2Index = 0;
  
    for (let i = 0; i < arr1.length; i++) {
      narr.push(arr1[i]);
      if ((i + 1) % 5 === 0 && arr2Index < arr2.length) {
        narr.push(arr2[arr2Index]);
        arr2Index++;
      }
      if (arr2Index === arr2.length) {
        arr2Index = 0;
      }
    }
  
    return narr;
  }
  
  let generalPostWithAdsPic = intersperse(!!getFrontEndPostsData && getFrontEndPostsData, !!getAdvertisementsWithoutPageList && getAdvertisementsWithoutPageList);
  
  return (
    <>
      {width.current < 470 &&
        (showCreateGenPost === 1 ? (
          <GeneralPost />
        ) : (
          <Row>
            <Col xs={6} sm={6} md={6}>
              <Button
                className={`w-100 maintheme-bg border-none ${
                  selectedbtn == "sell" && "selectedColor"
                }`}
                onClick={() => {
                  dispatch(restPosts());
                  dispatch(
                    getFrontEndPosts({
                      type: "sell",
                    })
                  );
                }}
              >
                Seller
              </Button>
            </Col>
            <Col xs={6} sm={6} md={6}>
              <Button
                className={`w-100 maintheme-bg border-none ${
                  selectedbtn == "buy" && "selectedColor"
                }`}
                onClick={() => {
                  dispatch(restPosts());
                  dispatch(
                    getFrontEndPosts({
                      type: "buy",
                    })
                  );
                }}
              >
                Buyer
              </Button>
            </Col>
          </Row>
        ))}
       {(selectedbtn === "sell" || selectedbtn === "buy") && <Row>
          <h4 className="selected-strip">
            {selectedbtn == "sell" && "Seller"}
            {selectedbtn == "buy" && "Buyer"}
          </h4>
        </Row>}
      <Row>
        {width.current > 470 && (
          <Col md={2} className="mt-2">
            <img className="w-100" src={market} />
          </Col>
        )}
        <Col
          md={8}
          sm={12}
          xs={12}
        >
          {width.current > 500 && (
            <Row className="mt-2">
              <Col md={4} sm={4} xs={4} className="tabs-blue-border">
                <Row>
                  <Col
                    className={`post-tabs ${
                      selectedbtn == "general" && "selectedColor"
                    }`}
                    onClick={() => {
                      dispatch(restPosts());
                      dispatch(
                        getFrontEndPosts({
                          type: "general",
                        })
                      );
                      dispatch(menuBottomTab({ homepost: "general" }));
                      setPostType(false);
                    }}
                  >
                    <p
                      className={`${
                        width.current < 470 && "text-center"
                      } m-2 mbl-tabs`}
                    >
                      <span
                        className={`float-left ${
                          activeTab === "general" ? "active" : ""
                        }`}
                      >
                        General
                      </span>
                    </p>
                  </Col>
                  <Col className={`text-center add-post-btn`}>
                    <GeneralPost />
                  </Col>
                </Row>
              </Col>
              <Col md={4} sm={4} xs={4} className="tabs-blue-border">
                <Row>
                  <Col
                    className={`post-tabs ${
                      selectedbtn == "sell" && "selectedColor"
                    }`}
                    onClick={() => {
                      dispatch(restPosts());
                      dispatch(
                        getFrontEndPosts({
                          type: "sell",
                        })
                      );
                      dispatch(menuBottomTab({ homepost: "sell" }));
                      setPostType(false);
                    }}
                  >
                    <p
                      className={`${
                        width.current < 470 && "text-center"
                      } m-2 mbl-tabs`}
                    >
                      <span className={`${activeTab === "sell" ? "active" : ""}`}>
                        Sell
                      </span>
                    </p>
                  </Col>
                  {/* <Col className={`text-center add-post-btn`}>
                    <CreatePost postType={"sell"} buttonTitle={"Create Sell"} />
                  </Col> */}
                </Row>
              </Col>
              <Col md={4} sm={4} xs={4} className="tabs-blue-border">
                <Row>
                  <Col
                    className={`post-tabs ${
                      selectedbtn == "buy" && "selectedColor"
                    }`}
                    onClick={() => {
                      dispatch(restPosts());
                      dispatch(
                        getFrontEndPosts({
                          type: "buy",
                        })
                      );
                      dispatch(menuBottomTab({ homepost: "buy" }));
                      setPostType(false);
                    }}
                  >
                    <p
                      className={`${
                        width.current < 470 && "text-center"
                      } m-2 mbl-tabs`}
                    >
                      <span
                        className={`float-left ${
                          activeTab === "buy" ? "active" : ""
                        }`}
                      >
                        Buy
                      </span>
                    </p>
                  </Col>
                  {/* <Col className={`text-center add-post-btn`}>
                    <CreatePost postType={"buy"} buttonTitle={"Create Buy"} />
                  </Col> */}
                </Row>
              </Col>
            </Row>
          )}
          <Row className={width.current > 470 && "desktop-home-view"}>
            {
                (!!generalPostWithAdsPic && generalPostWithAdsPic)?.map((data, index) => {
                  desc = data?.description?.substring(0, 160).split("\n");
                  return (
                    <Fragment key={index}>
                      {data?.user && <div 
                        onClick={() => {
                          ((data?.description.length > 160 ||
                            data?.type == "sell" ||
                            data?.type == "buy")) && dispatch(popUpCloseFoot({status:2}))
                            appPopupTab?.status === 2 && navigate(
                              !localStorage.getItem("x-auth-token")
                                ? "/login"
                                : `/blog-details?postid=${data?.id}`
                            )
                        }}
                        className={`align-item ${
                          width.current > 470
                            ? `mt-3 mb-3 col-md-12`
                            : `mt-0 col-md-4`
                        }`}
                      >
                        <div className="homecard rounded">
                          <Row className="align-profile-item px-2">
                            <Col
                              sm={2}
                              xs={2}
                              md={1}
                              className={`post-profile`}
                            >
                              {data?.user?.image ? (
                                <img
                                  className="post-user-img"
                                  src={data?.user?.image}
                                />
                              ) : (
                                <img
                                  className="post-user-img"
                                  src={userprofile}
                                />
                              )}
                            </Col>
                            <Col sm={10} xs={10} md={11}>
                              <p className="mb-0 text-black">
                                <b>
                                  {data?.user?.firmName
                                    ? data.user.firmName
                                    : data?.user?.firstName}
                                </b>
                              </p>
                              <p className="mb-0 date-font-size">
                                {moment(data?.createdAt).format("LLL")}
                              </p>
                            </Col>
                          </Row>
                          {((data?.name !== "undefined") && (data?.type !== "general")) && <p className="mb-0">{selectedbtn === "sell" ? "Seller" : "Buyer"}: <b>{data?.name}</b></p>} 
                          <p className="app-post-title mb-0">
                            <b>
                           {data?.type === "general" ? data?.title : !!data?.product?.name && data.product.name}
                              
                            </b>
                          </p>
                          {(data?.type === "general" && desc?.length > 0) ? <p className="card-text-wrap black-color mb-0">
                              {desc.map((line, index) => (
                                <Fragment key={index}>
                                  {line}
                                  <br />
                                </Fragment>
                              ))}
                            </p> : <>
                            <p className="mb-0">Qty: <b>{data?.qty} MT</b></p>
                          <p className="mb-0">Price: <b>{data?.price} PMT</b></p>
                          <p className="mb-0">Location: <b>{`${data?.city !== "undefined" ? data?.city + `,` : ''} ${data?.state !== "undefined" ? data?.state : ''}`}</b></p></>}
                          <div>
                            <p className="mb-0 text-secondary"><b>
                              {
                                (data?.description?.length > 0 ||
                                  data?.type == "sell" ||
                                  data?.type == "buy") &&
                                  "Read More"
                              }</b>
                            </p>
                          </div>
                        </div>
                      </div>}
                      {/* (!data?.user && postTypeName === "general") */}
                      {(!data?.user) && (
                              <Row className="m-auto w-100">
                                <img className="p-0 mb-1" src={`/${data?.image}`} alt={data?.title} title={data?.title} />
                              </Row>
                            )}
                      {/* {data?.type === "general" && (
                        <>
                            {data?.image?.length > 0 && (
                              <Row>
                                <img src={`/${data?.image}`} />
                              </Row>
                            )}
                          <Row>
                            <Col md={6} sm={6} xs={6}>
                              <div className="d-flex">
                                <p
                                  className="pr-like-dislike mb-0 mt-1"
                                  onClick={async () => {
                                    dispatch(restPosts());
                                    dispatch(
                                      createPostComment({
                                        like: true,
                                        post: data?.id,
                                      })
                                    );
                                    dispatch(
                                      menuBottomTab({ bottomtab: data?.type })
                                    );
                                    setTimeout(async () => {
                                      await dispatch(
                                        getFrontEndPosts({
                                          type: data?.type,
                                        })
                                      );
                                    }, 800);
                                  }}
                                >
                                  {data?.like.indexOf(
                                    localStorage.getItem("userid")
                                  ) === -1 ? (
                                    <i className="bi bi-hand-thumbs-up"></i>
                                  ) : (
                                    <i className="bi bi-hand-thumbs-up-fill"></i>
                                  )}
                                  {!!data?.like && data?.like.length}
                                </p>
                                <p
                                  className="mb-0 pt-1"
                                  onClick={async () => {
                                    dispatch(restPosts());
                                    dispatch(
                                      createPostComment({
                                        unlike: true,
                                        post: data?.id,
                                      })
                                    );
                                    dispatch(
                                      menuBottomTab({ bottomtab: data?.type })
                                    );
                                    setTimeout(() => {
                                      dispatch(
                                        getFrontEndPosts({
                                          type: data?.type,
                                        })
                                      );
                                    }, 800);
                                  }}
                                >
                                  {data?.unlike.indexOf(
                                    localStorage.getItem("userid")
                                  ) === -1 ? (
                                    <i className="bi bi-hand-thumbs-down"></i>
                                  ) : (
                                    <i className="bi bi-hand-thumbs-down-fill"></i>
                                  )}
                                  {!!data?.unlike && data?.unlike.length}
                                </p>
                              </div>
                            </Col>
                            <Col md={6} sm={6} xs={6}>
                              <CommentPopup postid={data?.id} />
                            </Col>
                          </Row>
                          {(!data?.user && (index % 2 === 1)) && (
                              <Row className="m-auto">
                                <img src={`/${data?.image}`} alt={data?.title} title={data?.title} />
                              </Row>
                            )}
                        </>
                      )} */}
                        {data?.user && <Row>
                            <Col md={6} sm={6} xs={6}>
                              <div className="d-flex">
                                <p
                                  className="pr-like-dislike mb-0 mt-1"
                                  onClick={async () => {
                                    dispatch(restPosts());
                                    dispatch(
                                      createPostComment({
                                        like: true,
                                        post: data?.id,
                                      })
                                    );
                                    dispatch(
                                      menuBottomTab({ bottomtab: data?.type })
                                    );
                                    setTimeout(async () => {
                                      await dispatch(
                                        getFrontEndPosts({
                                          type: data?.type,
                                        })
                                      );
                                    }, 800);
                                  }}
                                >
                                  {data?.like?.indexOf(
                                    localStorage.getItem("userid")
                                  ) === -1 ? (
                                    <i className="bi bi-hand-thumbs-up"></i>
                                  ) : (
                                    <i className="bi bi-hand-thumbs-up-fill"></i>
                                  )}
                                  {!!data?.like && data?.like.length}
                                </p>
                                <p
                                  className="mb-0 pt-1"
                                  onClick={async () => {
                                    dispatch(restPosts());
                                    dispatch(
                                      createPostComment({
                                        unlike: true,
                                        post: data?.id,
                                      })
                                    );
                                    dispatch(
                                      menuBottomTab({ bottomtab: data?.type })
                                    );
                                    setTimeout(() => {
                                      dispatch(
                                        getFrontEndPosts({
                                          type: data?.type,
                                        })
                                      );
                                    }, 800);
                                  }}
                                >
                                  {data?.unlike?.indexOf(
                                    localStorage.getItem("userid")
                                  ) === -1 ? (
                                    <i className="bi bi-hand-thumbs-down"></i>
                                  ) : (
                                    <i className="bi bi-hand-thumbs-down-fill"></i>
                                  )}
                                  {!!data?.unlike && data?.unlike.length}
                                </p>
                              </div>
                            </Col>
                            <Col md={6} sm={6} xs={6}>
                              <CommentPopup postid={data?.id} />
                            </Col>
                          </Row>}

                      {(width.current < 500 && data?.type) && <hr className="my-2" />}
                    </Fragment>
                  );
                })
            }
          </Row>
        </Col>
        {width.current > 470 && (
          <Col md={2} className="mt-2">
            <img className="w-100" src={ads} />
          </Col>
        )}
      </Row>
    </>
  );
}

export default PostTabs;
