import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import {getUsers, restRecord, subscriptionStatus} from "../../reducers/commonReducer";

function SubscStatus(props) {
  const {subStatus,id} = props;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant={subStatus === 1 && "danger" || subStatus === 3 && "success" || subStatus === 2 && "info"} onClick={handleShow}>
      {subStatus === 2 && "Free" || subStatus === 3 && "Pro" || subStatus === 1 && "Pending"}
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Subscription Update</Modal.Title>
        </Modal.Header>
        <Formik
          onSubmit={async (values) => {
            await dispatch(subscriptionStatus(values));
            setTimeout(async() => {
                await dispatch(restRecord())
                await dispatch(getUsers())
              }, 1000)
            setShow(false);
          }}
          initialValues={{
            userid: id,
            status : +subStatus
          }}
        >
          {({ handleSubmit, values, setFieldValue, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Label>Subscription Status</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("status", +e.target.value);
                    }}
                    name="status"
                    className="form-control"
                    value={values.status}
                    isInvalid={!!errors.status}
                  >
                    <option value="">Select Subscription Status</option>
                    <option value={1} selected>
                      Pending
                    </option>
                    <option value={2} selected>
                      Free
                    </option>
                    <option value={3} selected>
                      Pro
                    </option>
                  </Form.Control>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default SubscStatus;
