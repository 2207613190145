import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import { Form, Row, Col, Button } from "react-bootstrap";
import {
  addSettings,
  getSettings,
  updateSetting,
} from "../../reducers/commonReducer";
import { Formik } from "formik";
import * as yup from "yup";

// Validation by Yup
const schema = yup.object().shape({
  name: yup.string().required(),
  value: yup.string().required(),
  halptext: yup.string().required(),
  filter: yup.string().required(),
  key: yup.string().required(),
});

const Settings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [helpText, setHelpText] = useState("");
  const [settingValue, setSettingValue] = useState("");
  const { getSettingsList } = useSelector((state) => state.commonReducer);
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    let timeOut = setTimeout(() => {
      dispatch(
        getSettings({
          search: search,
        })
      );
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search]);

  return (
    <>
      <Header />
      <div className="container">
        <div className="mt-4 row">
          <div className="col-md-6 col-6 p-0">
            <h3>Add Settings</h3>
          </div>
          <Formik
            validationSchema={schema}
            onSubmit={async (values, { resetForm }) => {
              await dispatch(addSettings(values));
              await dispatch(getSettings());
              resetForm({ values: "" });
            }}
            initialValues={{
              name: "",
              value: "",
              key: "",
              halptext: "",
              filter: "",
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form noValidate onSubmit={handleSubmit} className="p-0">
                <Row className="mb-4">
                  <Form.Group as={Col} md="3">
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="bi bi-person-fill"></i>
                      </div>
                      <Form.Control
                        type="text"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={!!errors.name}
                        className="form-control"
                        placeholder="Setting Name"
                        required
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="3">
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="bi bi-lock-fill"></i>
                      </div>
                      <Form.Control
                        type="text"
                        name="value"
                        value={values.value}
                        onChange={handleChange}
                        isInvalid={!!errors.value}
                        className="form-control"
                        placeholder="Setting Value"
                        required
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.value}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="3">
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="bi bi-lock-fill"></i>
                      </div>
                      <Form.Control
                        type="text"
                        name="key"
                        value={values.key}
                        onChange={handleChange}
                        isInvalid={!!errors.key}
                        className="form-control"
                        placeholder="Setting key"
                        required
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.key}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group as={Col} md="3">
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="bi bi-search"></i>
                      </div>
                      <Form.Control
                        type="text"
                        name="filter"
                        value={values.filter}
                        onChange={handleChange}
                        isInvalid={!!errors.filter}
                        className="form-control"
                        placeholder="Setting Filter"
                        required
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.filter}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="col-12 text-center mt-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-pen-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          as="textarea"
                          row={3}
                          name="halptext"
                          value={values.halptext}
                          onChange={handleChange}
                          isInvalid={!!errors.halptext}
                          className="form-control"
                          placeholder="Write Helptext....."
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.halptext}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12 text-center">
                    <Button
                      type="submit"
                      className=" button-color login-btn px-4 mt-2 w-100"
                    >
                      Add New Setting
                    </Button>
                  </div>
                </Row>
              </Form>
            )}
          </Formik>
          <div className="col-md-6 col-6 p-0">
            <h3>Settings List</h3>
          </div>
          <Row>
            <div className="col-12  mb-2 p-0">
              <Form.Group as={Col} md="12">
                <div className="input-group">
                  <div className="input-group-text">
                    <i className="bi bi-search"></i>
                  </div>
                  <Form.Control
                    type="text"
                    name="search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Write Anything..."
                    required
                  />
                </div>
              </Form.Group>
            </div>
          </Row>

          <div className="overflow-auto border mt-3">
            <Table striped hover size="sm" className="overflow-scroll">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Name</th>
                  <th>Help Text</th>
                  {/* <th>Key</th> */}
                  <th>Value</th>
                  {/* <th>Filter</th> */}
                </tr>
              </thead>
              <tbody>
                {!!getSettingsList &&
                  getSettingsList.map((data, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}.</td>
                        <td>{data?.name}</td>
                        <td>{data?.halptext}</td>
                        {/* <td>{data?.key}</td> */}
                        <td>
                          <TableRow itemId={data?.id} value={data?.value} />
                        </td>
                        {/* <td>{data?.filter}</td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export const TableRow = (props) => {
  const { value, itemId } = props;
  const dispatch = useDispatch();
  const [updateData, setUpdateData] = useState(value);
  return (
    <Form.Group className="mb-3">
      <Form.Control
        type="text"
        value={updateData}
        onBlur={() => {
          dispatch(updateSetting({ id: itemId, value: updateData }));
        }}
        onChange={(e) => setUpdateData(e.target.value)}
      />
    </Form.Group>
  );
};
export default Settings;
