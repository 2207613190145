import React from "react";
import AppHeader from "../Components/AppHeader";
import { useEffect } from "react";
import { Row } from "react-bootstrap";

const ShippingDeliveryPolicy = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AppHeader />
      <div className="content mb-5">
        <div className="container">
            <Row>
            <h2 className="about-content pt-3 pb-3"> Shipping & Delivery Policy </h2>
            </Row>
          <div className="row">
            <p><b>SHIPPING</b></p>
            <p>We aim to make your online shopping a hassle-free experience so our shipping methods and policies are designed to get your order to you quickly and efficiently.</p>
            <p><b>Delivery Times</b></p>
            <p>Your order will be on its way soon and handle with utmost care by our delivery professional check our shipping schedule below to see just how long your order will take:</p>
            <p><b>DISCLAIMER</b></p>
            <p>Kindly note that below-given shipping schedule is invalid during the spread of COVID-19. Due to transportation barriers the normal shipping time is 8-10 working days PAN India. Temporarily No Delivery Service In The Lockdown Areas / Containment Zones. To avoid inconvenience kindly contact us before ordering.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShippingDeliveryPolicy;
