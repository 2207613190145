import React, { useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import {
  addPost,
  getFrontEndPosts,
  restPosts,
} from "../../reducers/commonReducer";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  title: yup.string().max(1150).required("Title is Required"),
  image: yup.mixed()
});
const GeneralPost = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const width = useRef(window.innerWidth);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleshow = () => setShow(true);
  const { updategen, utitle, udescription, api, uid } = props;
  return (
    <>
      {localStorage.getItem("x-auth-token") ? (
        <span onClick={handleshow}>
          {width.current < 470 ? (
            <Row>
              {updategen === "1" ? (
                <i onClick={handleshow} className="bi bi-pen-fill"></i>
              ) : (
                <Form.Group
                  className={`${width.current < 470 && "mb-1"}`}
                  controlId="formBasicEmail"
                >
                  <Form.Control
                    className="gen-write-herepost"
                    type="text"
                    placeholder="Write General Post Here...."
                    // disabled
                  />
                </Form.Group>
              )}
            </Row>
          ) : updategen === "1" ? (
            <Button variant="success" onClick={handleshow}>
              <i onClick={handleshow} className="bi bi-pen-fill"></i>
            </Button>
          ) : (
            <i className="bi bi-patch-plus-fill"></i>
          )}
        </span>
      ) : (
        <div>
          <span>
            {width.current < 470 ? (
              <Row>
                <Form.Group
                  className={`${width.current < 470 && "mb-1"}`}
                  controlId="formBasicEmail"
                >
                  <Form.Control
                    className="gen-write-herepost"
                    type="text"
                    placeholder="Write General Post Here...."
                    onClick={() => navigate("/login")}
                  />
                </Form.Group>
              </Row>
            ) : (
              <i className="bi bi-patch-plus-fill"></i>
            )}
          </span>
        </div>
      )}

      {/* // modal  */}
      <Modal size="lg" className="popup-form" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Experss Your Views</Modal.Title>
        </Modal.Header>
        <hr />
        <Modal.Body>
          <Formik
            validationSchema={schema}
            onSubmit={async (values, { resetForm }) => {
              api
                ? await dispatch(api(values))
                : await dispatch(addPost(values));
              resetForm({ values: "" });
              setShow(false);
              await dispatch(restPosts());
              await dispatch(
                getFrontEndPosts({
                  type: "general",
                })
              );
            }}
            initialValues={{
              id: uid,
              title: utitle,
              description: udescription,
              image: "",
              type: "general",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              setFieldValue,
              errors,
            }) => (
              <Form className="container" onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Title</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Write your title here....."
                        name="title"
                        value={values.title}
                        onChange={handleChange}
                        isInvalid={!!errors.title}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.title}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12} sm={12}>
                    <Form.Group>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        isInvalid={!!errors.description}
                        as="textarea"
                        rows={3}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12} sm={12}>
                    <Form.Group as={Col} md="12">
                      <Form.Label>Add Image</Form.Label>
                      <Form.Control
                        id="myfile"
                        type="file"
                        name="image"
                        onChange={(event) => {
                          const resumeFile = event.target.files[0];
                          setFieldValue("image", resumeFile);
                        }}
                        isInvalid={!!errors.image}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.image}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Modal.Footer>
                  <Button variant="success" type="submit">
                    Post
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default GeneralPost;
