import React, { useEffect, useRef } from "react";
import AppHeader from "../../Components/AppHeader";
import { Col, Container, Row } from "react-bootstrap";
import "../../market.css";
import { useDispatch, useSelector } from "react-redux";
import { getMarketDetails } from "../../reducers/commonReducer";
import moment from "moment";
import MarketAppUi from "./MarketAppUi";
import { getMonthName } from "../../const";
import { Link, useNavigate } from "react-router-dom";

const Market = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => { 
    dispatch(getMarketDetails());
    if(!localStorage.getItem("x-auth-token")){
      navigate(`/login-singup`)
    }
  }, []);
  const { getMarketlist, userProfileData, loading } = useSelector((state) => state.commonReducer);
  const width = useRef(window.innerWidth);
  let monthsData =  [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
   
const matchedData = monthsData.filter((month) =>
  getMarketlist?.MDEXCPOLive?.hasOwnProperty(month)
);
const ecbotBeanOil = monthsData.filter((month) =>
  getMarketlist?.ECBOTBeanOilLive?.hasOwnProperty(month)
);
const ecbotSoyaOil = monthsData.filter((month) =>
  getMarketlist?.ECBOTSoyBeansLive?.hasOwnProperty(month)
);
const ecbotSoyaMealOil = monthsData.filter((month) =>
  getMarketlist?.ECBOTSoyMealLive?.hasOwnProperty(month)
);
const cottonCandy = monthsData.filter((month) =>
  getMarketlist?.COTTONCANDY?.hasOwnProperty(month)
);
const cottonCake = monthsData.filter((month) =>
  getMarketlist?.CottonCake?.hasOwnProperty(month)
);
const castorSeeds = monthsData.filter((month) =>
  getMarketlist?.CastorSeed?.hasOwnProperty(month)
);
const mcxCrudeOil = monthsData.filter((month) =>
  getMarketlist?.MCXCrudeOilLive?.hasOwnProperty(month)
);
// const ecbotBeanOil = Object.keys(!!getMarketlist?.ECBOTBeanOilLive && getMarketlist?.ECBOTBeanOilLive).filter((month) =>
// monthsData.includes(month)
// );
let brentCrudeSpot = getMarketlist?.BrentCrudeSpot?.Other;
let witCrudeOil = getMarketlist?.WTICrudeOilSpot?.Other;
let usdInr = getMarketlist?.USDINR?.Other;
let usdMyr = getMarketlist?.USDMYR?.Other;
  return (
    <>
      <AppHeader />
      {(userProfileData?.subscription?.status === 3) ? (width.current > 668 ? (
         <div className="container-fluid">
        {(loading === undefined || loading) ?  <h3 className="text-info text-center mt-5">
        Loading.....
      </h3> : 
          <Row className="mt-4">
            <table className="table responsive">
              <thead>
                <tr>
                  <td>Exchange</td>
                  <td>Contract</td>
                  <td>Last</td>
                  <td>Buy</td>
                  <td>Sell</td>
                  <td>Chg</td>
                  <td>Prev Sett</td>
                  <td>Open</td>
                  <td>High</td>
                  <td>Low</td>
                  <td>Volume</td>
                  <td>Time</td>
                </tr>
              </thead>
              <tbody>
                {
                  !!matchedData && matchedData.map((data, index) => {
                    let monthWise = getMarketlist?.MDEXCPOLive[data]
                    return(
                      <tr key={index}>
                        <td data-label="Exchange">{monthWise?.nm}</td>
                        <td data-label="Contract">
                          {" "}
                          {monthWise?.mnth > 0
                            ? getMonthName(Number(monthWise?.mnth))
                            : ""}{" "}
                          {monthWise?.yr}
                        </td>
                        <td
                          data-label="Last"
                          className={`${
                            (Number(monthWise?.LAST) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.LAST) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.LAST) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.LAST)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Buy"
                          className={`${
                            (Number(monthWise?.buy) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.buy) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.buy) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.buy)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Sell"
                          className={`${
                            (Number(monthWise?.sell) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.sell) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.sell) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.sell)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Chg"
                          className={`${
                            Number(monthWise?.chgn) > 0 ? "bg-success" : "bg-danger"
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.chgn)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Prev Sett">{Number(monthWise?.settlementprice)?.toFixed(1)}</td>
                        <td
                          data-label="Open"
                          className={`${
                            (Number(monthWise?.OPEN) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.OPEN) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.OPEN) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.OPEN)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="High"
                          className={`${
                            (Number(monthWise?.high) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.high) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.high) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.high)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Low"
                          className={`${
                            (Number(monthWise?.low) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.low) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.low) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.low)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Volume">{Number(monthWise?.volume)?.toFixed(0)}</td>
                        <td data-label="Time">
                          {moment(monthWise?.updatedAt).format("DD-MM-YYYY HH:mm")}
                        </td>
                     </tr>
                    )
                  })
                } 
                {/*------ ECBOT Bean Oil Live --------*/}
                {
                  !!ecbotBeanOil && ecbotBeanOil.map((data, index) => {
                    let monthWise = getMarketlist?.ECBOTBeanOilLive[data]
                    return(
                      <tr key={index}>
                        <td data-label="Exchange">{monthWise?.nm}</td>
                        <td data-label="Contract">
                          {" "}
                          {monthWise?.mnth > 0
                            ? getMonthName(Number(monthWise?.mnth))
                            : ""}{" "}
                          {monthWise?.yr}
                        </td>
                        <td
                          data-label="Last"
                          className={`${
                            (Number(monthWise?.LAST) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.LAST) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.LAST) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.LAST)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Buy"
                          className={`${
                            (Number(monthWise?.buy) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.buy) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.buy) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.buy)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Sell"
                          className={`${
                            (Number(monthWise?.sell) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.sell) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.sell) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.sell)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Chg"
                          className={`${
                            Number(monthWise?.chgn) > 0 ? "bg-success" : "bg-danger"
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.chgn)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Prev Sett">{Number(monthWise?.settlementprice)?.toFixed(1)}</td>
                        <td
                          data-label="Open"
                          className={`${
                            (Number(monthWise?.OPEN) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.OPEN) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.OPEN) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.OPEN)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="High"
                          className={`${
                            (Number(monthWise?.high) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.high) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.high) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.high)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Low"
                          className={`${
                            (Number(monthWise?.low) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.low) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.low) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.low)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Volume">{Number(monthWise?.volume)?.toFixed(0)}</td>
                        <td data-label="Time">
                          {moment(monthWise?.updatedAt).format("DD-MM-YYYY HH:mm")}
                        </td>
                     </tr>
                    )
                  })
                }  
                {/*------ ECBOT Soya Oil Live --------*/}
                {
                  !!ecbotSoyaOil && ecbotSoyaOil.map((data, index) => {
                    let monthWise = getMarketlist?.ECBOTSoyBeansLive[data]
                    return(
                      <tr key={index}>
                        <td data-label="Exchange">{monthWise?.nm}</td>
                        <td data-label="Contract">
                          {" "}
                          {monthWise?.mnth > 0
                            ? getMonthName(Number(monthWise?.mnth))
                            : ""}{" "}
                          {monthWise?.yr}
                        </td>
                        <td
                          data-label="Last"
                          className={`${
                            (Number(monthWise?.LAST) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.LAST) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.LAST) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.LAST)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Buy"
                          className={`${
                            (Number(monthWise?.buy) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.buy) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.buy) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.buy)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Sell"
                          className={`${
                            (Number(monthWise?.sell) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.sell) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.sell) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.sell)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Chg"
                          className={`${
                            Number(monthWise?.chgn) > 0 ? "bg-success" : "bg-danger"
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.chgn)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Prev Sett">{Number(monthWise?.settlementprice)?.toFixed(1)}</td>
                        <td
                          data-label="Open"
                          className={`${
                            (Number(monthWise?.OPEN) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.OPEN) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.OPEN) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.OPEN)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="High"
                          className={`${
                            (Number(monthWise?.high) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.high) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.high) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.high)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Low"
                          className={`${
                            (Number(monthWise?.low) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.low) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.low) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.low)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Volume">{Number(monthWise?.volume)?.toFixed(0)}</td>
                        <td data-label="Time">
                          {moment(monthWise?.updatedAt).format("DD-MM-YYYY HH:mm")}
                        </td>
                     </tr>
                    )
                  })
                }  
                 {/*------ ECBOT Soya meal Live --------*/}
                 {
                  !!ecbotSoyaMealOil && ecbotSoyaMealOil.map((data, index) => {
                    let monthWise = getMarketlist?.ECBOTSoyMealLive[data]
                    return(
                      <tr key={index}>
                        <td data-label="Exchange">{monthWise?.nm}</td>
                        <td data-label="Contract">
                          {" "}
                          {monthWise?.mnth > 0
                            ? getMonthName(Number(monthWise?.mnth))
                            : ""}{" "}
                          {monthWise?.yr}
                        </td>
                        <td
                          data-label="Last"
                          className={`${
                            (Number(monthWise?.LAST) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.LAST) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.LAST) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.LAST)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Buy"
                          className={`${
                            (Number(monthWise?.buy) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.buy) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.buy) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.buy)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Sell"
                          className={`${
                            (Number(monthWise?.sell) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.sell) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.sell) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.sell)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Chg"
                          className={`${
                            Number(monthWise?.chgn) > 0 ? "bg-success" : "bg-danger"
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.chgn)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Prev Sett">{Number(monthWise?.settlementprice)?.toFixed(1)}</td>
                        <td
                          data-label="Open"
                          className={`${
                            (Number(monthWise?.OPEN) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.OPEN) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.OPEN) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.OPEN)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="High"
                          className={`${
                            (Number(monthWise?.high) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.high) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.high) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.high)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Low"
                          className={`${
                            (Number(monthWise?.low) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.low) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.low) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.low)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Volume">{Number(monthWise?.volume)?.toFixed(0)}</td>
                        <td data-label="Time">
                          {moment(monthWise?.updatedAt).format("DD-MM-YYYY HH:mm")}
                        </td>
                     </tr>
                    )
                  })
                }  
                 {/*------ COTTONCANDY --------*/}
                 {
                  !!cottonCandy && cottonCandy.map((data, index) => {
                    let monthWise = getMarketlist?.COTTONCANDY[data]
                    return(
                      <tr key={index}>
                        <td data-label="Exchange">{monthWise?.nm}</td>
                        <td data-label="Contract">
                          {" "}
                          {monthWise?.mnth > 0
                            ? getMonthName(Number(monthWise?.mnth))
                            : ""}{" "}
                          {monthWise?.yr}
                        </td>
                        <td
                          data-label="Last"
                          className={`${
                            (Number(monthWise?.LAST) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.LAST) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.LAST) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.LAST)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Buy"
                          className={`${
                            (Number(monthWise?.buy) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.buy) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.buy) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.buy)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Sell"
                          className={`${
                            (Number(monthWise?.sell) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.sell) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.sell) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.sell)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Chg"
                          className={`${
                            Number(monthWise?.chgn) > 0 ? "bg-success" : "bg-danger"
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.chgn)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Prev Sett">{Number(monthWise?.settlementprice)?.toFixed(1)}</td>
                        <td
                          data-label="Open"
                          className={`${
                            (Number(monthWise?.OPEN) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.OPEN) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.OPEN) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.OPEN)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="High"
                          className={`${
                            (Number(monthWise?.high) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.high) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.high) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.high)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Low"
                          className={`${
                            (Number(monthWise?.low) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.low) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.low) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.low)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Volume">{Number(monthWise?.volume)?.toFixed(0)}</td>
                        <td data-label="Time">
                          {moment(monthWise?.updatedAt).format("DD-MM-YYYY HH:mm")}
                        </td>
                     </tr>
                    )
                  })
                }  
                {/*------ CottonCake --------*/}
                {
                  !!cottonCake && cottonCake.map((data, index) => {
                    let monthWise = getMarketlist?.CottonCake[data]
                    return(
                      <tr key={index}>
                        <td data-label="Exchange">{monthWise?.nm}</td>
                        <td data-label="Contract">
                          {" "}
                          {monthWise?.mnth > 0
                            ? getMonthName(Number(monthWise?.mnth))
                            : ""}{" "}
                          {monthWise?.yr}
                        </td>
                        <td
                          data-label="Last"
                          className={`${
                            (Number(monthWise?.LAST) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.LAST) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.LAST) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.LAST)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Buy"
                          className={`${
                            (Number(monthWise?.buy) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.buy) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.buy) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.buy)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Sell"
                          className={`${
                            (Number(monthWise?.sell) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.sell) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.sell) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.sell)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Chg"
                          className={`${
                            Number(monthWise?.chgn) > 0 ? "bg-success" : "bg-danger"
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.chgn)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Prev Sett">{Number(monthWise?.settlementprice)?.toFixed(1)}</td>
                        <td
                          data-label="Open"
                          className={`${
                            (Number(monthWise?.OPEN) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.OPEN) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.OPEN) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.OPEN)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="High"
                          className={`${
                            (Number(monthWise?.high) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.high) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.high) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.high)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Low"
                          className={`${
                            (Number(monthWise?.low) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.low) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.low) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.low)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Volume">{Number(monthWise?.volume)?.toFixed(0)}</td>
                        <td data-label="Time">
                          {moment(monthWise?.updatedAt).format("DD-MM-YYYY HH:mm")}
                        </td>
                     </tr>
                    )
                  })
                }  
                {/*------ CastorSeed --------*/}
                {
                  !!castorSeeds && castorSeeds.map((data, index) => {
                    let monthWise = getMarketlist?.CastorSeed[data]
                    return(
                      <tr key={index}>
                        <td data-label="Exchange">{monthWise?.nm}</td>
                        <td data-label="Contract">
                          {" "}
                          {monthWise?.mnth > 0
                            ? getMonthName(Number(monthWise?.mnth))
                            : ""}{" "}
                          {monthWise?.yr}
                        </td>
                        <td
                          data-label="Last"
                          className={`${
                            (Number(monthWise?.LAST) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.LAST) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.LAST) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.LAST)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Buy"
                          className={`${
                            (Number(monthWise?.buy) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.buy) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.buy) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.buy)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Sell"
                          className={`${
                            (Number(monthWise?.sell) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.sell) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.sell) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.sell)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Chg"
                          className={`${
                            Number(monthWise?.chgn) > 0 ? "bg-success" : "bg-danger"
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.chgn)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Prev Sett">{Number(monthWise?.settlementprice)?.toFixed(1)}</td>
                        <td
                          data-label="Open"
                          className={`${
                            (Number(monthWise?.OPEN) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.OPEN) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.OPEN) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.OPEN)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="High"
                          className={`${
                            (Number(monthWise?.high) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.high) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.high) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.high)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Low"
                          className={`${
                            (Number(monthWise?.low) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.low) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.low) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.low)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Volume">{Number(monthWise?.volume)?.toFixed(0)}</td>
                        <td data-label="Time">
                          {moment(monthWise?.updatedAt).format("DD-MM-YYYY HH:mm")}
                        </td>
                     </tr>
                    )
                  })
                }  
                {/*------ MCX Crude Oil Live --------*/}
                {
                  !!mcxCrudeOil && mcxCrudeOil.map((data, index) => {
                    let monthWise = getMarketlist?.MCXCrudeOilLive[data]
                    return(
                      <tr key={index}>
                        <td data-label="Exchange">{monthWise?.nm}</td>
                        <td data-label="Contract">
                          {" "}
                          {monthWise?.mnth > 0
                            ? getMonthName(Number(monthWise?.mnth))
                            : ""}{" "}
                          {monthWise?.yr}
                        </td>
                        <td
                          data-label="Last"
                          className={`${
                            (Number(monthWise?.LAST) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.LAST) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.LAST) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.LAST)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Buy"
                          className={`${
                            (Number(monthWise?.buy) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.buy) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.buy) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.buy)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Sell"
                          className={`${
                            (Number(monthWise?.sell) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.sell) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.sell) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.sell)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Chg"
                          className={`${
                            Number(monthWise?.chgn) > 0 ? "bg-success" : "bg-danger"
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.chgn)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Prev Sett">{Number(monthWise?.settlementprice)?.toFixed(1)}</td>
                        <td
                          data-label="Open"
                          className={`${
                            (Number(monthWise?.OPEN) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.OPEN) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.OPEN) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.OPEN)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="High"
                          className={`${
                            (Number(monthWise?.high) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.high) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.high) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.high)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Low"
                          className={`${
                            (Number(monthWise?.low) ===
                              Number(monthWise?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(monthWise?.settlementprice) <
                              Number(monthWise?.low) &&
                              "bg-success") ||
                            (Number(monthWise?.settlementprice) >
                              Number(monthWise?.low) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(monthWise?.low)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Volume">{Number(monthWise?.volume)?.toFixed(0)}</td>
                        <td data-label="Time">
                          {moment(monthWise?.updatedAt).format("DD-MM-YYYY HH:mm")}
                        </td>
                     </tr>
                    )
                  })
                }  
                  {/*------ Brent Crude Spot --------*/}
                      {brentCrudeSpot && <tr>
                        <td data-label="Exchange">{brentCrudeSpot?.nm}</td>
                        <td data-label="Contract">
                          {" "}
                          {brentCrudeSpot?.mnth > 0
                            ? getMonthName(Number(brentCrudeSpot?.mnth))
                            : ""}{" "}
                          {brentCrudeSpot?.yr}
                        </td>
                        <td
                          data-label="Last"
                          className={`${
                            (Number(brentCrudeSpot?.LAST) ===
                              Number(brentCrudeSpot?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(brentCrudeSpot?.settlementprice) <
                              Number(brentCrudeSpot?.LAST) &&
                              "bg-success") ||
                            (Number(brentCrudeSpot?.settlementprice) >
                              Number(brentCrudeSpot?.LAST) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(brentCrudeSpot?.LAST)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Buy"
                          className={`${
                            (Number(brentCrudeSpot?.buy) ===
                              Number(brentCrudeSpot?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(brentCrudeSpot?.settlementprice) <
                              Number(brentCrudeSpot?.buy) &&
                              "bg-success") ||
                            (Number(brentCrudeSpot?.settlementprice) >
                              Number(brentCrudeSpot?.buy) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(brentCrudeSpot?.buy)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Sell"
                          className={`${
                            (Number(brentCrudeSpot?.sell) ===
                              Number(brentCrudeSpot?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(brentCrudeSpot?.settlementprice) <
                              Number(brentCrudeSpot?.sell) &&
                              "bg-success") ||
                            (Number(brentCrudeSpot?.settlementprice) >
                              Number(brentCrudeSpot?.sell) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(brentCrudeSpot?.sell)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Chg"
                          className={`${
                            Number(brentCrudeSpot?.chgn) > 0 ? "bg-success" : "bg-danger"
                          }`}
                        >
                          <span className="text-light">{Number(brentCrudeSpot?.chgn)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Prev Sett">{Number(brentCrudeSpot?.settlementprice)?.toFixed(1)}</td>
                        <td
                          data-label="Open"
                          className={`${
                            (Number(brentCrudeSpot?.OPEN) ===
                              Number(brentCrudeSpot?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(brentCrudeSpot?.settlementprice) <
                              Number(brentCrudeSpot?.OPEN) &&
                              "bg-success") ||
                            (Number(brentCrudeSpot?.settlementprice) >
                              Number(brentCrudeSpot?.OPEN) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(brentCrudeSpot?.OPEN)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="High"
                          className={`${
                            (Number(brentCrudeSpot?.high) ===
                              Number(brentCrudeSpot?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(brentCrudeSpot?.settlementprice) <
                              Number(brentCrudeSpot?.high) &&
                              "bg-success") ||
                            (Number(brentCrudeSpot?.settlementprice) >
                              Number(brentCrudeSpot?.high) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(brentCrudeSpot?.high)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Low"
                          className={`${
                            (Number(brentCrudeSpot?.low) ===
                              Number(brentCrudeSpot?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(brentCrudeSpot?.settlementprice) <
                              Number(brentCrudeSpot?.low) &&
                              "bg-success") ||
                            (Number(brentCrudeSpot?.settlementprice) >
                              Number(brentCrudeSpot?.low) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(brentCrudeSpot?.low)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Volume">{Number(brentCrudeSpot?.volume)?.toFixed(0)}</td>
                        <td data-label="Time">
                          {moment(brentCrudeSpot?.updatedAt).format("DD-MM-YYYY HH:mm")}
                        </td>
                     </tr>}
                    {/*------ witCrudeOil --------*/}
                    {witCrudeOil && <tr>
                        <td data-label="Exchange">{witCrudeOil?.nm}</td>
                        <td data-label="Contract">
                          {" "}
                          {witCrudeOil?.mnth > 0
                            ? getMonthName(Number(witCrudeOil?.mnth))
                            : ""}{" "}
                          {witCrudeOil?.yr}
                        </td>
                        <td
                          data-label="Last"
                          className={`${
                            (Number(witCrudeOil?.LAST) ===
                              Number(witCrudeOil?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(witCrudeOil?.settlementprice) <
                              Number(witCrudeOil?.LAST) &&
                              "bg-success") ||
                            (Number(witCrudeOil?.settlementprice) >
                              Number(witCrudeOil?.LAST) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(witCrudeOil?.LAST)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Buy"
                          className={`${
                            (Number(witCrudeOil?.buy) ===
                              Number(witCrudeOil?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(witCrudeOil?.settlementprice) <
                              Number(witCrudeOil?.buy) &&
                              "bg-success") ||
                            (Number(witCrudeOil?.settlementprice) >
                              Number(witCrudeOil?.buy) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(witCrudeOil?.buy)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Sell"
                          className={`${
                            (Number(witCrudeOil?.sell) ===
                              Number(witCrudeOil?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(witCrudeOil?.settlementprice) <
                              Number(witCrudeOil?.sell) &&
                              "bg-success") ||
                            (Number(witCrudeOil?.settlementprice) >
                              Number(witCrudeOil?.sell) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(witCrudeOil?.sell)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Chg"
                          className={`${
                            Number(witCrudeOil?.chgn) > 0 ? "bg-success" : "bg-danger"
                          }`}
                        >
                          <span className="text-light">{Number(witCrudeOil?.chgn)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Prev Sett">{Number(witCrudeOil?.settlementprice)?.toFixed(1)}</td>
                        <td
                          data-label="Open"
                          className={`${
                            (Number(witCrudeOil?.OPEN) ===
                              Number(witCrudeOil?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(witCrudeOil?.settlementprice) <
                              Number(witCrudeOil?.OPEN) &&
                              "bg-success") ||
                            (Number(witCrudeOil?.settlementprice) >
                              Number(witCrudeOil?.OPEN) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(witCrudeOil?.OPEN)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="High"
                          className={`${
                            (Number(witCrudeOil?.high) ===
                              Number(witCrudeOil?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(witCrudeOil?.settlementprice) <
                              Number(witCrudeOil?.high) &&
                              "bg-success") ||
                            (Number(witCrudeOil?.settlementprice) >
                              Number(witCrudeOil?.high) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(witCrudeOil?.high)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Low"
                          className={`${
                            (Number(witCrudeOil?.low) ===
                              Number(witCrudeOil?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(witCrudeOil?.settlementprice) <
                              Number(witCrudeOil?.low) &&
                              "bg-success") ||
                            (Number(witCrudeOil?.settlementprice) >
                              Number(witCrudeOil?.low) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(witCrudeOil?.low)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Volume">{Number(witCrudeOil?.volume)?.toFixed(0)}</td>
                        <td data-label="Time">
                          {moment(witCrudeOil?.updatedAt).format("DD-MM-YYYY HH:mm")}
                        </td>
                     </tr>}
                     {/*------ USDINR --------*/}
                     {usdInr && <tr>
                        <td data-label="Exchange">{usdInr?.nm}</td>
                        <td data-label="Contract">
                          {" "}
                          {usdInr?.mnth > 0
                            ? getMonthName(Number(usdInr?.mnth))
                            : ""}{" "}
                          {usdInr?.yr}
                        </td>
                        <td
                          data-label="Last"
                          className={`${
                            (Number(usdInr?.LAST) ===
                              Number(usdInr?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(usdInr?.settlementprice) <
                              Number(usdInr?.LAST) &&
                              "bg-success") ||
                            (Number(usdInr?.settlementprice) >
                              Number(usdInr?.LAST) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(usdInr?.LAST)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Buy"
                          className={`${
                            (Number(usdInr?.buy) ===
                              Number(usdInr?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(usdInr?.settlementprice) <
                              Number(usdInr?.buy) &&
                              "bg-success") ||
                            (Number(usdInr?.settlementprice) >
                              Number(usdInr?.buy) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(usdInr?.buy)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Sell"
                          className={`${
                            (Number(usdInr?.sell) ===
                              Number(usdInr?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(usdInr?.settlementprice) <
                              Number(usdInr?.sell) &&
                              "bg-success") ||
                            (Number(usdInr?.settlementprice) >
                              Number(usdInr?.sell) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(usdInr?.sell)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Chg"
                          className={`${
                            Number(usdInr?.chgn) > 0 ? "bg-success" : "bg-danger"
                          }`}
                        >
                          <span className="text-light">{Number(usdInr?.chgn)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Prev Sett">{Number(usdInr?.settlementprice)?.toFixed(1)}</td>
                        <td
                          data-label="Open"
                          className={`${
                            (Number(usdInr?.OPEN) ===
                              Number(usdInr?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(usdInr?.settlementprice) <
                              Number(usdInr?.OPEN) &&
                              "bg-success") ||
                            (Number(usdInr?.settlementprice) >
                              Number(usdInr?.OPEN) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(usdInr?.OPEN)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="High"
                          className={`${
                            (Number(usdInr?.high) ===
                              Number(usdInr?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(usdInr?.settlementprice) <
                              Number(usdInr?.high) &&
                              "bg-success") ||
                            (Number(usdInr?.settlementprice) >
                              Number(usdInr?.high) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(usdInr?.high)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Low"
                          className={`${
                            (Number(usdInr?.low) ===
                              Number(usdInr?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(usdInr?.settlementprice) <
                              Number(usdInr?.low) &&
                              "bg-success") ||
                            (Number(usdInr?.settlementprice) >
                              Number(usdInr?.low) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(usdInr?.low)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Volume">{Number(usdInr?.volume)?.toFixed(0)}</td>
                        <td data-label="Time">
                          {moment(usdInr?.updatedAt).format("DD-MM-YYYY HH:mm")}
                        </td>
                     </tr>}
                     {/*------ USDMYR --------*/}
                    {usdMyr && <tr>
                        <td data-label="Exchange">{usdMyr?.nm}</td>
                        <td data-label="Contract">
                          {" "}
                          {usdMyr?.mnth > 0
                            ? getMonthName(Number(usdMyr?.mnth))
                            : ""}{" "}
                          {usdMyr?.yr}
                        </td>
                        <td
                          data-label="Last"
                          className={`${
                            (Number(usdMyr?.LAST) ===
                              Number(usdMyr?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(usdMyr?.settlementprice) <
                              Number(usdMyr?.LAST) &&
                              "bg-success") ||
                            (Number(usdMyr?.settlementprice) >
                              Number(usdMyr?.LAST) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(usdMyr?.LAST)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Buy"
                          className={`${
                            (Number(usdMyr?.buy) ===
                              Number(usdMyr?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(usdMyr?.settlementprice) <
                              Number(usdMyr?.buy) &&
                              "bg-success") ||
                            (Number(usdMyr?.settlementprice) >
                              Number(usdMyr?.buy) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(usdMyr?.buy)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Sell"
                          className={`${
                            (Number(usdMyr?.sell) ===
                              Number(usdMyr?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(usdMyr?.settlementprice) <
                              Number(usdMyr?.sell) &&
                              "bg-success") ||
                            (Number(usdMyr?.settlementprice) >
                              Number(usdMyr?.sell) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(usdMyr?.sell)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Chg"
                          className={`${
                            Number(usdMyr?.chgn) > 0 ? "bg-success" : "bg-danger"
                          }`}
                        >
                          <span className="text-light">{Number(usdMyr?.chgn)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Prev Sett">{Number(usdMyr?.settlementprice)?.toFixed(1)}</td>
                        <td
                          data-label="Open"
                          className={`${
                            (Number(usdMyr?.OPEN) ===
                              Number(usdMyr?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(usdMyr?.settlementprice) <
                              Number(usdMyr?.OPEN) &&
                              "bg-success") ||
                            (Number(usdMyr?.settlementprice) >
                              Number(usdMyr?.OPEN) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(usdMyr?.OPEN)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="High"
                          className={`${
                            (Number(usdMyr?.high) ===
                              Number(usdMyr?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(usdMyr?.settlementprice) <
                              Number(usdMyr?.high) &&
                              "bg-success") ||
                            (Number(usdMyr?.settlementprice) >
                              Number(usdMyr?.high) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(usdMyr?.high)?.toFixed(1)}</span>
                        </td>
                        <td
                          data-label="Low"
                          className={`${
                            (Number(usdMyr?.low) ===
                              Number(usdMyr?.settlementprice) &&
                              "bg-secondary") ||
                            (Number(usdMyr?.settlementprice) <
                              Number(usdMyr?.low) &&
                              "bg-success") ||
                            (Number(usdMyr?.settlementprice) >
                              Number(usdMyr?.low) &&
                              "bg-danger")
                          }`}
                        >
                          <span className="text-light">{Number(usdMyr?.low)?.toFixed(1)}</span>
                        </td>
                        <td data-label="Volume">{Number(usdMyr?.volume)?.toFixed(0)}</td>
                        <td data-label="Time">
                          {moment(usdMyr?.updatedAt).format("DD-MM-YYYY HH:mm")}
                        </td>
                     </tr>}
              </tbody>
            </table>
          </Row>}
        </div>
      ) : (
        <MarketAppUi 
        matchedData={matchedData}
        ecbotBeanOil={ecbotBeanOil}
        ecbotSoyaOil={ecbotSoyaOil}
        ecbotSoyaMealOil={ecbotSoyaMealOil}
        cottonCandy={cottonCandy}
        cottonCake={cottonCake}
        castorSeeds={castorSeeds}
        mcxCrudeOil={mcxCrudeOil}
        />
      )): <Container>
          <Row>
            <Col md={12} className="text-center mt-5">
             {(userProfileData?.subscription?.status === 2 && localStorage.getItem("x-auth-token")) && <h3>To access live market prices, So please activate the Premium plan by <Link to="/subscription">Click Here.</Link></h3>}
             {!localStorage.getItem("x-auth-token") && <h3>To access live market prices, So please login <Link to="/login">Click Here.</Link></h3>}
            </Col>
          </Row>
        </Container>}
    </>
  );
};

export default Market;
