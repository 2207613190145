import React, { useEffect, useState } from "react";
import AppHeader from "../../Components/AppHeader";
import { Col, Container, Row, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  addBargin,
  barginList,
  deleteBargin,
  updateBargin,
} from "../../reducers/commonReducer";
import ActionBargin from "./ActionBargin";
import Confirmation from "../Confirmation";
import moment from "moment";

export default function BarginList() {
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const dispatch = useDispatch();

  useEffect(() => {
    let timeOut = setTimeout(() => {
      dispatch(
        barginList({
          search: search,
        })
      );
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search]);
  const { getBarginList, userProfileData, loading } = useSelector(
    (state) => state.commonReducer
  );
  return (
    <>
      <AppHeader />
      {(loading === undefined || loading) ?  <h3 className="text-info text-center mt-5">
       Loading.....
     </h3> : 
      <Container>
        {(userProfileData?.subscription?.date != null &&
            <ActionBargin
              buttonTitle={"Add New Bargain"}
              title={"Create New Bargain"}
              submitTitle={"Submit New Bargain"}
              api={addBargin}
            />
          )}

        <Row>
          <div className="col-12 mt-1 mb-2">
            <Form.Group as={Col} md="12">
              <div className="input-group">
                <div className="input-group-text">
                  <i className="bi bi-search"></i>
                </div>
                <Form.Control
                  type="text"
                  name="search"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  className="form-control"
                  placeholder="Write Anything..."
                  required
                />
              </div>
            </Form.Group>
          </div>
        </Row>
        <Row>
          {!!getBarginList && getBarginList.length > 0 ? (
            getBarginList.map((data, index) => {
              return (
                <Col md={4} sm={12} xs={12} className="mt-1" key={index}>
                  <div
                    className={`border p-2 homecard border rounded ${
                      data?.status && `selected-bg`
                    }`}
                  >
                    <Row>
                      <Col md={8} sm={8} xs={8}>
                        <p className="">
                          <b>{data?.seller}</b>
                        </p>
                      </Col>
                      <Col md={4} sm={4} xs={4}>
                        <span className="d-flex">
                          {data?.status ? <Form.Check checked={true} className="px-2 mt-1" onClick={async() => dispatch(
                            await updateBargin({
                              id:data?.id,
                              status:false
                            }),
                             setTimeout(() => {
                              dispatch(barginList())
                            }, 500)
                          )}/> : <Form.Check className="px-2 mt-1" onClick={async() => dispatch(
                            await updateBargin({
                              id:data?.id,
                              status:true
                            }),
                             setTimeout(() => {
                              dispatch(barginList())
                            }, 500)
                          )}/>}
                          <ActionBargin
                            title={"Update Bargain"}
                            submitTitle={"Update Bargain"}
                            useller={data?.seller}
                            ubroker={data?.broker}
                            uremarks={data?.remarks}
                            udate={data?.date}
                            ubuyer={data?.buyer}
                            uitem={data?.item}
                            uqty={data?.qty}
                            urate={data?.rate}
                            uunit={data?.unit}
                            uid={data?.id}
                            api={updateBargin}
                          />
                          <Confirmation
                            id={data?.id}
                            showhide={show}
                            handleShow={handleShow}
                            handleClose={handleClose}
                            deleteApi={deleteBargin}
                          />
                        </span>
                      </Col>
                    </Row>
                    <p className="mb-0">
                      Date: <b>{moment(data?.date).format("DD-MM-YYYY")}</b>
                    </p>
                    <p className="mb-0">
                      Broker: <b>{data?.broker}</b>
                    </p>
                    <p className="mb-0">
                      Buyer: <b>{data?.buyer}</b>
                    </p>
                    <p className="mb-0">
                      Item: <b>{data?.item}</b>
                    </p>
                    <Row>
                      <Col md={6} sm={6} xs={6}>
                        QTY: <b>{data?.qty}</b>
                      </Col>
                      <Col md={6} sm={6} xs={6}>
                        Rate: <b>{data?.rate}</b>
                      </Col>
                    </Row>
                    {
                    !!data?.remarks &&  <p className="mb-0">
                    Remarks: <b>{data?.remarks}</b>
                  </p>
                    }
                   
                  </div>
                </Col>
              );
            })
          ) : (
            <h3 className="text-center text-warning">No Bargain Yet...</h3>
          )}
        </Row>
      </Container>}
    </>
  );
}
