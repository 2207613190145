import React from "react";
import AppHeader from "../../Components/AppHeader";
import { Container, Row } from "react-bootstrap";

const MarketReport = () => {
  const queryParameters = new URLSearchParams(window.location.search);
  const marketUrl = queryParameters.get("marketUrl");
  return (
    <>
      <AppHeader />
      <Container className="mt-4">
        <Row>
          <iframe
            src={marketUrl}
            width={1000}
            height={500}
            allow="reload, accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture full"
            sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
          ></iframe>
        </Row>
      </Container>
    </>
  );
};

export default MarketReport;
