import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Update from "./Update";
import Form from "react-bootstrap/Form";
import {
  getStates,
  getUsers,
  agentUserVerified,
  restRecord,
  getSubscriptionSetting,
  deletePermanentUser,
} from "../../reducers/commonReducer";
import { Formik } from "formik";
import red from "../../images/red.png";
import green from "../../images/green.png";
import SubscStatus from "./SubscStatus";
import moment from "moment";
import ExportData from "../../ExportData";

const List = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(restRecord())
    // Get users
    dispatch(getUsers());
    // Get state list
    dispatch(getStates());
    dispatch(getSubscriptionSetting());
  }, []);
  const { getUsersData, usersBlank, getSubscriptionList } = useSelector((state) => state.commonReducer);
  const [page, setPage] = useState(1);

  const header = [
    "Sr",
    "Name",
    "UserName",
    "Phone",
    "Email",
    "Plan",
    "SubscriptionDate",
  ];
  let body = [];
  !!getUsersData &&
  getUsersData.map((data, index) => {
      body.push({
        Sr: index + 1,
        Name: `${data?.firstName} ${data?.lastName}`,
        UserName: data?.username,
        Phone: data?.phone,
        Email: data?.email,
        Plan: data?.subscription?.plan,
        SubscriptionDate: moment(data?.subscription?.date).format("LL"),
      });
    });
  return (
    <>
      <Header />
      <div className="container">
        <div className="mt-4 row">
          <div className="col-md-6 col-6">
            <h3>Users List</h3>
          </div>
          {/* <div className="col-md-6 text-end col-6">
            <Button
              className="mb-3 mobile-width submit-buttom"
              onClick={handleShow}
            >
              Add User
            </Button>
          </div> */}
          <div className="col-md-12 p-0">
            <Formik
              onSubmit={async (values, { resetForm }) => {
                dispatch(restRecord());
                dispatch(getUsers(values));
              }}
              initialValues={{
                search: "",
                role: "",
                status: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values
              }) => (
                <Form className="container" onSubmit={handleSubmit}>
                  <Row className=" mb-3 border pt-3 pb-3 justify-content-between">
                    <Col md={3} sm={12}>
                      <Form.Label>Name, Username and FirmName</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Searching..."
                        name="search"
                        value={values.search}
                        onChange={handleChange}
                      />
                    </Col>
                    <Col md={2} sm={12}>
                      <Form.Label>Role</Form.Label>
                      <Form.Control
                        as="select"
                        name="role"
                        value={values.role}
                        onChange={handleChange}
                        className="form-control"
                      >
                        <option value="">Select Role</option>
                        <option value="trader">Trader</option>
                        <option value="agent">Agent</option>
                      </Form.Control>
                    </Col>
                    <Col md={2} sm={12}>
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        name="status"
                        value={values.status}
                        onChange={handleChange}
                        className="form-control"
                      >
                        <option value={""}>Select status</option>
                        <option value="1">Enabled</option>
                        <option value="0">Disabled</option>
                      </Form.Control>
                    </Col>
                    <Col md={2} sm={12}>
                      <Form.Label>Click to filter</Form.Label>
                      <Form.Control
                        type="submit"
                        placeholder="Filter"
                        name="filter"
                        className="btn submit-buttom"
                      />
                    </Col>
                    <Col md={1} sm={12}>
                      <Form.Label className="d-none">Clear</Form.Label><br/>
                      <Button onClick={() => window.location.reload()} variant="danger" className="mt-2 px-1"><i className="bi bi-arrow-clockwise"></i> Clear</Button>
                    </Col>
                    <Col md={2} sm={12}>
                    <Form.Label>Export</Form.Label>
                    <ExportData title="Export Users" header={header} body={body}/>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
          <div className="overflow-auto border mt-3">
            <Table striped hover size="sm" className="overflow-scroll">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Name</th>
                  <th>SubscriptionDate</th>
                  <th>Refferal</th>
                  <th>Phone</th>
                  <th>Role</th>
                  <th>Address</th>
                  <th>Sell/Buy/Orders</th>
                  <th>Products</th>
                  {/* <th>Brokers</th> */}
                  <th>Status</th>
                  <th>As Agent</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {!!getUsersData &&
                  getUsersData?.map((data, index) => {
                    return (
                      <tr key={data?.id}>
                        <td>{index + 1}.</td>
                        <td className={`${(data?.subscription?.status === 3 && `text-success` || data?.subscription?.status === 2 && `text-info`)}`}>
                          {data?.firstName} {data?.lastName} ({data?.username})<br/>
                          <SubscStatus subStatus={data?.subscription?.status} id={data?.id}/>
                          <p className="text-dark mb-0"><b>Signup:</b> {moment(data?.createdAt).format("L")}</p>
                        </td>
                        <td className="text-success">{moment(data?.subscription?.date).format("LL")}</td>
                        {/* <td>{data?.subscription?.status === 1 && "Pending" || data?.subscription?.status === 2 && "Free" || data?.subscription?.status === 3 && "Pro"}</td> */}
                        <td> 
                          {data?.referralStatus === 1 ? (
                            <img src={green} />
                          ) : (
                            <img src={red} />
                          )}<br/>
                          {
                            data?.referralStatus === 1 && <>
                              <b>Name</b>: {`${data?.referralurl?.firstName} (${data?.referralurl?.username})`}<br/>
                              <b>Phone</b>: {data?.referralurl?.phone}
                            </>
                          }<br/>
                          {(data?.referralStatus === 1 && data?.subscription?.status === 3)&& 
                          <span className={data?.referralPayment ? `referral-success` : `referral-danger`}>
                            {33 / 100 * +getSubscriptionList?.subscriptionprice}/-
                          </span>
                          }
                        </td>
                        <td>{data?.phone}</td>
                        <td>{data?.role?.name}</td>
                        <td>{`${data?.address}, ${data?.city}, ${data?.state ? data?.state : ""}`}</td>
                        <td><Button variant="success" className="mt-1 px-1 py-0" onClick={() => navigate(`/posts?userid=${data?.id}&type=sell`)}>Sell</Button>
                        <Button variant="warning" className="mt-1 px-1 py-0" onClick={() => navigate(`/posts?userid=${data?.id}&type=buy`)}>Buy</Button>
                        <Button variant="info" className="mt-1 px-1 py-0" onClick={() => navigate(`/orders?userid=${data?.id}`)}>Order</Button>
                        </td>
                        <td>
                          {data?.subscription?.status === 3 ? "All Products" : data?.products &&
                            data?.products?.map((data, index) => {
                              return <span key={index}>{data?.name}, </span>;
                            })}
                        </td>
                        {/* <td></td> */}
                        <td className="table-img">
                          {data?.status === true ? (
                            <img src={green} />
                          ) : (
                            <img src={red} />
                          )}
                        </td>
                        <td>
                          {data?.role?.slug === "agent" ? <Button variant="success" disabled>As Agent</Button> : (
                            <Button
                              className="px-1 py-0"
                              variant="warning"
                              onClick={() => {
                                dispatch(
                                  agentUserVerified({
                                    id: data?.id,
                                  })
                                );
                                setTimeout(() => {
                                  dispatch(getUsers());
                                }, 500);
                              }}
                            >
                              Select Agent
                            </Button>
                          )}
                        </td>
                        <td>
                          <Update
                            fName={data?.firstName}
                            lName={data?.lastName}
                            userAdrs={data?.address}
                            userCity={data?.city}
                            userState={data?.state}
                            userPhone={data?.phone}
                            userEmail={data?.email}
                            userFirmName={data?.firmName}
                            userProducts={data?.products}
                            // userBrokers={data?.brokers}
                            status={data?.status}
                            referralPayment={data?.referralPayment}
                            id={data?.id}
                          />
                          <Button variant="info" className="px-1 py-0"
                          onClick={async() => {
                            const confirmed = window.confirm("Are you sure for delete this user.");
                            if (confirmed) {
                              await dispatch(deletePermanentUser({
                                id : data?.id
                              }))
                              await dispatch(restRecord())
                              // Get users
                              dispatch(getUsers());
                            }
                          }}>
                          <i class="bi bi-trash3-fill"></i>
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {!!usersBlank && usersBlank.length > 0 ? (
                <Row>
                  <Button
                  className="w-100"
                    onClick={() => {
                      setPage(page + 1);
                      dispatch(
                        getUsers({
                          page: page + 1,
                        })
                      );
                    }}
                  >
                    Load More
                  </Button>
                </Row>
              ) : (
                <p className="text-center">
                  <b>No record found 😔.</b>
                </p>
              )}
          </div>
        </div>
      </div>

      {/* <Add showhide={show} handleClose={handleClose} /> */}
    </>
  );
};

export default List;
