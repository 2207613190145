import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { paymentsList, updatePayment } from "../../reducers/commonReducer";
import PaymentAction from "./PaymentAction";
import moment from "moment";
import AppHeader from "../../Components/AppHeader";
import ExportData from "../../ExportData";

const Payments = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    dispatch(paymentsList())
  }, []);
  const { paymentsData } = useSelector((state) => state.commonReducer);

  const header = [
    "Sr",
    "TransactionId",
    "Name",
    "FirmName",
    "Amount",
    "Date",
    "Status",
  ];
  let body = [];
  !!paymentsData &&
  paymentsData.map((data, index) => {
      body.push({
        Sr: index + 1,
        TransactionId: data?.paymentTransaction,
        Name: `${data?.userid?.firstName} ${data?.userid?.lastName} (${data?.userid?.username})`,
        FirmName: data?.userid?.firmName,
        Amount: data?.amount,
        Date: moment(data?.createdAt).format("LL HH:MM A"),
        Status: data?.status === 0 ? "Pending": 
            data?.status === 1 ? "Failed" :
            data?.status === 2 && "Success",
      });
    });
  return (
    <>
      {localStorage.getItem("userRole") === "admin" ? <Header/> : <AppHeader/>}
      <div className="container">
        <Row className="mt-4 mb-2 user-table"> 
        <Col md={12}>
        <h3>Payments</h3>
          </Col>  
          <hr/>
          <Col md={10}>
            
          </Col>
          {/* <Col className="text-end" md={6}>
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
                onChange={(e)=>{
                  
                }}
              />
          </Col> */}
          <Col md={2} sm={12}>
            <ExportData title="Export PaymentList" header={header} body={body}/>
          </Col>
        </Row>
          
          <div className="overflow-auto">
        <Table striped bordered hover size="sm" className="overflow-scroll">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Trns ID</th>
              <th>User Details</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Status</th>
              {
                (localStorage.getItem("userRole") === "admin") && <th>Action</th>
              }
            </tr>
          </thead>
          <tbody>
            {!!paymentsData &&
              paymentsData?.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{data?.paymentTransaction}</td>
                    <td>{`${data?.userid?.firstName} ${data?.userid?.lastName}`}<br/>
                    ({data?.userid?.username})<br/>
                    FirmName: <b>{data?.userid?.firmName}</b></td>
                    <td><b>{data?.amount}/-</b></td>
                    <td>{moment(data?.createdAt).format("LL HH:MM A")}</td>
                    <td className="font-weight-bold">
                    {data?.status === 0 && <span className="text-info">Pending</span>}
                    {data?.status === 1 && <span className="text-danger">Failed</span>}
                    {data?.status === 2 && <span className="text-success">Success</span>}
                    </td>
                    {
                      (localStorage.getItem("userRole") === "admin") && <td>
                      <PaymentAction
                        title="Update Payment"
                        status={data?.status}
                        id={data?.id}
                        api={updatePayment}
                      />
                    </td>
                    }
                  </tr>
                );
              })}
          </tbody>
        </Table> 

        </div>
      </div>
    </>
  );
};

export default Payments;
