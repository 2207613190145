import React, {useState} from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { getAdvertisement, restRecord } from "../../reducers/commonReducer";

const schema = yup.object().shape({
  title : yup.string().required(),
  image: yup.mixed(),
});

function AdvertisementAction(props) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const{popupTitle, id, api, title} = props;
  return (
    <>
      <Button className="mobile-width" variant="primary" onClick={handleShow}>
        {popupTitle}
      </Button>
      <Modal show={show} size={"lg"} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{popupTitle} Advertisement</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values) => {
            const apiResult = await dispatch(api(values));
            if(apiResult?.payload?.status){
              handleClose();
              dispatch(restRecord());
              dispatch(getAdvertisement());
            }
          }}
          initialValues={{
            id,
            title,
            image:''
          }}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            values,
            errors,
          }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6} >
                  <Form.Group >
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Title"
                      name="title"
                      value={values.title}
                      onChange={handleChange}
                      isInvalid={!!errors.title}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.title}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group >
                    <Form.Label>Image</Form.Label>
                  <Form.Control
                            id="myfile"
                            type="file"
                            name="image"
                            onChange={(event) => {
                              const gstfile = event.target.files[0];
                              setFieldValue("image", gstfile);
                            }}
                            isInvalid={!!errors.image}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.image}
                          </Form.Control.Feedback>
                          </Form.Group >
                  </Col>
                </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
                <Button onClick={handleClose} variant="danger">
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default AdvertisementAction;
