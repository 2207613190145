import React, { useRef } from "react";
import AppHeader from "../Components/AppHeader";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addOrder,
  createPostComment,
  getFrontEndPost,
} from "../reducers/commonReducer";
import moment from "moment";
import { Button, Col, Row, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
  comment: yup.string().required(),
});

const BlogDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const width = useRef(window.innerWidth);
  const queryParameters = new URLSearchParams(window.location.search);
  const postID = queryParameters.get("postid");
  const { getFrontEndPostData } = useSelector((state) => state.commonReducer);
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getFrontEndPost({
        id: postID,
      })
    );
  }, []);
  const createOrder = async (productId) => {
    const order = await dispatch(
      addOrder({
        postid: productId,
      })
    );
    if (order.payload?.status === 1) {
      setTimeout(() => {
        navigate("/my-orders");
      }, 1000);
    }
    return null;
  };
  let desc = getFrontEndPostData?.description.split("\n");
  return (
    <>
      <AppHeader />
      <div className="container border">
        <div className="blog-content bg-white mt-3">
          <div className="d-flex ">
            <div className="col-md-12">
              <ul className="list-inline mb-0">
                <li className="list-inline-item">
                  {getFrontEndPostData?.user?.image ? (
                    <img
                      className="single-post-user"
                      src={getFrontEndPostData?.user?.image}
                    />
                  ) : (
                    <i className="bi fs-2 bi-person-circle"></i>
                  )}
                </li>
                <li className="list-inline-item">
                  {getFrontEndPostData?.user?.firstName}
                </li>
                <li className="list-inline-item">
                  {moment(getFrontEndPostData?.createdAt).format("LLL")}
                </li>
              </ul>
            </div>
          </div>
          <div className="heading">
            {!!getFrontEndPostData?.image && (
              <img className="mw-100" src={getFrontEndPostData?.image} />
            )}
            <h2>
            {getFrontEndPostData?.type !== "general" &&(getFrontEndPostData?.type === "sell" && "Sell" || getFrontEndPostData?.type === "buy" && "Buy")} {getFrontEndPostData?.type == "general"
                ? getFrontEndPostData?.title
                : `${
                    !!getFrontEndPostData?.product?.name &&
                    getFrontEndPostData?.product?.name}`}
            </h2>
            {getFrontEndPostData?.qty && (
                <p className="mb-1">
                  Quantity (in MT): <b>{getFrontEndPostData.qty}</b>
                </p>
              )}
            <Row>
            {getFrontEndPostData?.price && (
                <p className="mb-1">
                  Price (in MT): <b>{getFrontEndPostData.price}/-</b>
                </p>
              )}
              {getFrontEndPostData?.price && (
                <p className="mb-1">
                  Delivery Period: <b>{`${moment(getFrontEndPostData?.start_time).format(
                                    "MMM Do"
                                  )} to ${moment(getFrontEndPostData?.end_time).format("LL")}`}</b>
                </p>
              )}
              {(getFrontEndPostData?.preferred_brokers?.firmName) && (
                <p className="mb-1">
                  Broker:{" "}
                  <b>
                    {getFrontEndPostData.preferred_brokers.firmName
                      ? getFrontEndPostData.preferred_brokers.firmName
                      : getFrontEndPostData?.preferred_brokers?.firmName}
                  </b>
                </p>
              )}
                <p className="mb-1">
                 {getFrontEndPostData?.city !== "undefined" && <b>{`${getFrontEndPostData?.city},`}</b>} {getFrontEndPostData?.state !== "undefined" && <b>{`${getFrontEndPostData?.state}`}</b>} 
                </p>
            </Row>
            <div className="mt-2">
              <p className="card-text-wrap mb-0">
                {!!desc &&
                  desc.map((line, index) => (
                    <React.Fragment key={index}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))}
              </p>
            </div>
            {!!getFrontEndPostData &&
              getFrontEndPostData?.type !== "general" && (
                <Button
                  className="button-color mt-2"
                  onClick={() => createOrder(getFrontEndPostData?.id)}
                >
                  Contact
                </Button>
              )}
          </div>
          {getFrontEndPostData?.type == "general" && (
            <>
              <div
                className={`d-flex ${width.current > 500 ? `mt-5` : `mt-1`}`}
              >
                <p
                  className="pr-like-dislike mb-0"
                  onClick={async () => {
                    await dispatch(
                      createPostComment({
                        like: true,
                        post: postID,
                      })
                    );
                    await dispatch(
                      getFrontEndPost({
                        id: postID,
                      })
                    );
                  }}
                >
                  {getFrontEndPostData?.like.indexOf(
                    localStorage.getItem("userid")
                  ) === -1 ? (
                    <i className="bi bi-hand-thumbs-up"></i>
                  ) : (
                    <i className="bi bi-hand-thumbs-up-fill"></i>
                  )}
                  {!!getFrontEndPostData?.like &&
                    getFrontEndPostData?.like.length}
                </p>
                <p
                  className="mb-0"
                  onClick={async () => {
                    await dispatch(
                      createPostComment({
                        unlike: true,
                        post: postID,
                      })
                    );
                    await dispatch(
                      getFrontEndPost({
                        id: postID,
                      })
                    );
                  }}
                >
                  {getFrontEndPostData?.unlike.indexOf(
                    localStorage.getItem("userid")
                  ) === -1 ? (
                    <i className="bi bi-hand-thumbs-down"></i>
                  ) : (
                    <i className="bi bi-hand-thumbs-down-fill"></i>
                  )}
                  {!!getFrontEndPostData?.unlike &&
                    getFrontEndPostData?.unlike.length}
                </p>
              </div>
              {/* add comment */}
              <Formik
                validationSchema={schema}
                onSubmit={async (values, { resetForm }) => {
                  await dispatch(createPostComment(values));
                  resetForm({ values: "" });
                  await dispatch(
                    getFrontEndPost({
                      id: postID,
                    })
                  );
                }}
                initialValues={{
                  comment: "",
                  post: postID,
                }}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <Form onSubmit={handleSubmit}>
                    <Row className="mb-3 mt-2">
                      <Col md={12} sm={12}>
                        <Form.Group>
                          <Form.Label>Enter Comment</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Write your comment...."
                            name="comment"
                            value={values.comment}
                            onChange={handleChange}
                            isInvalid={!!errors.comment}
                            as="textarea"
                            rows={3}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.comment}
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button variant="success" type="submit">
                      Submit Your Comment <i className="bi bi-pen"></i>
                    </Button>
                  </Form>
                )}
              </Formik>
            </>
          )}

          <Row className={`${width.current > 500 ? `mt-5` : `mt-1`}`}>
            {!!getFrontEndPostData?.comments &&
              getFrontEndPostData?.comments.map((data, item) => {
                return (
                  <ul className="list-inline" key={item}>
                    <li className="list-inline-item">
                      {data?._id?.image ? (
                        <img
                          className="single-post-user"
                          src={data?._id?.image}
                        />
                      ) : (
                        <i className="bi fs-2 bi-person-circle"></i>
                      )}
                    </li>
                    <li className="list-inline-item">
                      <b>
                        {data?._id?.firmName
                          ? data?._id?.firmName
                          : data?._id?.firstName}
                      </b>
                    </li>
                    <li className="list-inline-item">
                      {moment(data?.commentDate).format("LLL")}
                    </li>
                    <p className="mb-0">{data?.comment}</p>
                  </ul>
                );
              })}
          </Row>
        </div>
      </div>
    </>
  );
};

export default BlogDetails;
