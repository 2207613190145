import React, { useState } from "react";
import AppHeader from "../../Components/AppHeader";
import { useDispatch, useSelector } from "react-redux";
import { getNews } from "../../reducers/commonReducer";
import { Button, Container, Row } from "react-bootstrap";
import News from "./News";

const MainNews = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const { getNewsBlank } = useSelector((state) => state.commonReducer);
  return (
    <>
      <AppHeader />
      <Container>
        <News />
        {!!getNewsBlank && getNewsBlank.length > 0 ? (
                          <Row>
                            <Button
                              className="w-100"
                              onClick={() => {
                                setPage(page + 1);
                                dispatch(getNews({
                                  page: page + 1,
                                }));
                              }}
                            >
                              Load More
                            </Button>
                          </Row>
                        ) : (
                          <p className="text-center">
                            <b>No record found 😔.</b>
                          </p>
                        )}
      </Container>
    </>
  );
};

export default MainNews;
