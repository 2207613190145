import React, { useEffect, useState } from "react";
import { Button, Container, Col, Row } from "react-bootstrap";
import moment from "moment";
import AppHeader from "../../Components/AppHeader";
import { useDispatch, useSelector } from "react-redux";
import { getMyOrders, menuBottomTab, restRecord } from "../../reducers/commonReducer";
import { useNavigate } from "react-router-dom";

const MyOrders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  useEffect(() => {
    localStorage.removeItem("addOrder");
    dispatch(restRecord())
    dispatch(
      menuBottomTab({
        orderKey: "all",
      })
    );
    dispatch(
      getMyOrders({
        type: "all",
      })
    );
  }, []);
  const { getMyOrdersData, getMyOrdersBlank, appMenuTab, loading } = useSelector(
    (state) => state.commonReducer
  );
  return (
    <>
      <AppHeader />
      {(loading === undefined || loading) ?  <h3 className="text-info text-center mt-5">
       Loading.....
     </h3> : 
      <div className="content mt-3">
        <Container>
          <Row>
            <b onClick={() => navigate(-1)}>
              <h3>
                <i className="bi bi-caret-left"></i> My Enquiries
              </h3>
            </b>
          </Row>
          <Row className="d-flex mt-3 mb-3">
          <Col
              sm={4}
              xs={4}
              onClick={() => {
                dispatch(restRecord())
                dispatch(
                  getMyOrders({
                    type: "all",
                  })
                );
                dispatch(
                  menuBottomTab({
                    orderKey: "all",
                  })
                );
              }}
            >
              <span
                className={`bg-white btn border rounded w-100 ${
                  appMenuTab?.orderKey == "all" && "selectedColor"
                }`}
              >
                All
              </span>
            </Col>
            <Col
              sm={4}
              xs={4}
              onClick={() => {
                dispatch(restRecord())
                dispatch(
                  getMyOrders({
                    type: "sell",
                  })
                );
                dispatch(
                  menuBottomTab({
                    orderKey: "sell",
                  })
                );
              }}
            >
              <span
                className={`bg-white btn border rounded w-100 ${
                  appMenuTab?.orderKey == "sell" && "selectedColor"
                }`}
              >
                Sell
              </span>
            </Col>
            <Col
              sm={4}
              xs={4}
              onClick={() => {
                dispatch(restRecord())
                dispatch(
                  getMyOrders({
                    type: "buy",
                  })
                );
                dispatch(
                  menuBottomTab({
                    orderKey: "buy",
                  })
                );
              }}
            >
              <span
                className={`bg-white btn border rounded w-100 ${
                  appMenuTab?.orderKey == "buy" && "selectedColor"
                }`}
              >
                Buy
              </span>
            </Col>
          </Row>
          <Row>
            {
              !!getMyOrdersData ? getMyOrdersData?.map((data, index) => {
                return(
                  <Col md={4} xs={12} key={index} className="mb-2 p-2 box-shadow">
                    <p className="mb-0"><b>OrderID:</b> {data?.orderid}</p>
                    <p className="mb-0"><b>Date</b>: {moment(data?.createdAt).format("LLL")}</p>
                    <p className="mb-0"><b>Product</b>: {data?.postTitle}</p>
                    <p className="mb-0"><b>Order Summary</b>: {data?.ordersummary}</p>
                  </Col>
                )
              }) :
              <h3 className="text-danger text-center mt-5">
                Not Order Yet...
              </h3>
            }
          </Row>
          {!!getMyOrdersBlank && getMyOrdersBlank.length > 0 ? (
                <Row>
                  <Button
                  className="w-100"
                    onClick={() => {
                      setPage(page + 1);
                      dispatch(
                        getMyOrders({
                          page: page + 1,
                        })
                      );
                    }}
                  >
                    Load More
                  </Button>
                </Row>
              ) : (
                <p className="text-center">
                  <b>No record found 😔.</b>
                </p>
              )}
        </Container>
      </div>}
    </>
  );
};

export default MyOrders;
