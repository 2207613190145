import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getProducts, restRecord, getAdvertisement, addAdvertisement, updateAdvertisement, deleteAdvertisement } from "../../reducers/commonReducer";
import moment from "moment";
import AdvertisementAction from "./AdvertisementAction";

const Advertisements = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(restRecord())
    dispatch(getAdvertisement());
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
  }, []);
  const { getAdvertisementList, getAdvertisementBlank } = useSelector((state) => state.commonReducer);
  const [page, setPage] = useState(1);
  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table"> 
        <Col md={12}>
        <h3>Advertisements</h3>
          </Col> 
          <hr/>
          <Col md={6}>
            <AdvertisementAction popupTitle={"Add"} api={addAdvertisement}/>
          </Col>
          <Col className="text-end" md={6}>
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
                onChange={(e)=>{
                   dispatch(getProducts({
                     search:e.target.value.charAt(0).toUpperCase() +
                     e.target.value.slice(1)
                   }))
                }}
              />
          </Col>
        </Row>
          
          <div className="overflow-auto">
        <Table striped bordered hover size="sm" className="overflow-scroll">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Date</th>
              <th>Title</th>
              <th>Img</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!!getAdvertisementList ?
              getAdvertisementList?.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td>{index + 1}.</td>
                    <td>{moment(data?.createdAt).format("LL")}</td>
                    <td>{data?.title}</td>
                    <td><img className="m-auto w-100" src={`/${data?.image}`}/></td>
                    <td>
                      <AdvertisementAction
                        api={updateAdvertisement}
                        popupTitle="Update"
                        id={data?.id}
                        title={data?.title}
                      />
                      <Button onClick={async() => {
                        await dispatch(deleteAdvertisement(data?.id))
                        await dispatch(restRecord())
                        await dispatch(getAdvertisement())
                        }} variant="danger"><i className="bi bi-trash3-fill"></i></Button>
                    </td>
                  </tr>
                );
              }) : 'No record found'} 
          </tbody>
        </Table> 
        {!!getAdvertisementBlank && getAdvertisementBlank.length > 0 ? (
                <Row>
                  <Button
                    className="w-100"
                    onClick={() => {
                      setPage(page + 1);
                      dispatch(getAdvertisement({
                        page: page + 1,
                      }));
                    }}
                  >
                    Load More
                  </Button>
                </Row>
              ) : (
                <p className="text-center">
                  <b>No record found 😔.</b>
                </p>
              )}
        </div>
      </div>
    </>
  );
};

export default Advertisements;
