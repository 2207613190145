import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux"; 
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getOrders, getEnabledProducts, restRecord } from "../../reducers/commonReducer";
import Add from "./Add";
import red from "../../images/red.png";
import green from "../../images/green.png";

const List = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const productId = queryParameters.get("product");
  const userid = queryParameters.get("userid");
  
  useEffect(() => {
    dispatch(restRecord())
    dispatch(getOrders({
      "product": productId,
      userid
    }));
    dispatch(getEnabledProducts());
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
  }, []);
  const { getOrdersData, getOrdersBlank } = useSelector((state) => state.commonReducer);
  
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [search, setSearch] = useState();
  const [postType, setPostType] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">  
        <div className="col-md-6 col-6"><h3>My Enquiries</h3></div> 
          <div className="col-md-6 text-end col-6">
          <Button className="mb-3 mobile-width submit-buttom" onClick={handleShow}>
              Add Enquiries
            </Button>
          </div> 
          <div className="col-md-12">
          <Row className=" mb-3 border pt-3 pb-3 justify-content-between">
                    <Col md={4} sm={12}>
                      <Form.Label>Product Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search by Product"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Col>
                    <Col md={2} sm={12}>
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="startDate"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Col>
                    <Col md={2} sm={12}>
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="endDate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </Col>
                    <Col md={2} sm={12}>
                      <Form.Label>Click to filter</Form.Label>
                      <Form.Control
                        type="submit"
                        placeholder="Filter"
                        name="filter"
                        className="btn submit-buttom"
                        onClick={() => {
                          dispatch(restRecord())
                          dispatch(getOrders({
                            startDate, endDate, search
                          }));
                        }}
                      />
                    </Col>
                    <Col md={2} sm={12}>
                      <Form.Label className="d-none">Clear</Form.Label><br/>
                      <Button onClick={()=> window.location.reload()} variant="danger" className="mt-2"><i className="bi bi-arrow-clockwise"></i> Clear</Button>
                    </Col>
                  </Row>
          </div>   
          <div className="overflow-auto mt-3 border">
        <Table striped  hover size="sm" className="overflow-scroll">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Post Title</th>
              <th>Order Id</th>
              <th>Seller</th>
              <th>Buyer</th>
              <th>Summary</th>
              <th>Remarks</th>
              <th>Status</th>
              {/* <th>Action</th> */}
            </tr>
          </thead>
          <tbody>
            {!!getOrdersData &&
              getOrdersData?.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td>{index + 1}.</td>
                    <td>{data?.postTitle}</td>
                    <td>{data?.orderid}</td>
                    <td>{data?.seller?.username}</td>
                    <td>{data?.buyer?.username}</td>
                    <td>{data?.ordersummary}</td>
                    <td>{data?.remarks}</td> 
                    <td className="table-img">{data?.status ? <img src={green} /> : <img src={red} />}</td>
                    {/* <td> */}
                      {/* <Update
                        title="Update Product"
                        name={data?.name}
                        description={data?.description}
                        category={data?.category.id}
                        status={data?.status}
                        id={data?.id}
                      /> */}
                    {/* </td> */}
                  </tr>
                );
              })}
          </tbody>
        </Table> 
        {!!getOrdersBlank && getOrdersBlank.length > 0 ? (
                <Row>
                  <Button
                    className="w-100"
                    onClick={() => {
                      setPage(page + 1);
                      dispatch(getOrders({
                        search, endDate, startDate, page: page + 1,
                      }));
                    }}
                  >
                    Load More
                  </Button>
                </Row>
              ) : (
                <p className="text-center">
                  <b>No record found 😔.</b>
                </p>
              )}
        </div>
        </Row>
      </div> 
      <Add showhide={show} handleClose={handleClose} />
    </>
  );
};

export default List;
