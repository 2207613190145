import React, { useEffect, useRef, useState } from "react";
import Container from "react-bootstrap/Container";
import AppHeader from "../../Components/AppHeader";
import PostTabs from "../../Components/PostTabs";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdvertisementsWithoutPage,
  getEnabledProducts,
  getFrontEndPosts,
  getFrontEndbrokers,
  getStates,
  menuBottomTab,
  restPosts,
  userProfile,
} from "../../reducers/commonReducer";
import { Row, Button, Form, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

const Homepage = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const queryParameters = new URLSearchParams(window.location.search);
  const postTypeid = queryParameters.get("postType");
  const width = useRef(window.innerWidth);
  const { getFrontEndPostsBlank, selectedbtn,loading } = useSelector(
    (state) => state.commonReducer
  );
  useEffect(() => {
    // dispatch(restPosts());
    dispatch(menuBottomTab({ bottomtab: postTypeid ? postTypeid : "sell" }));
    dispatch(getStates());
    dispatch(getAdvertisementsWithoutPage())
    dispatch(getEnabledProducts());
    localStorage.getItem("x-auth-token") && dispatch(userProfile());
    localStorage.getItem("x-auth-token") && dispatch(getFrontEndbrokers());
  }, []);
  useEffect(() => {
    let timeOut = setTimeout(() => {
      dispatch(restPosts());
      dispatch(
        getFrontEndPosts({
          type: postTypeid ? postTypeid : "sell",
          search: search,
          // type: selectedbtn ? selectedbtn : postTypeid,
        })
      );
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search]);
  return (
    <> 
      <AppHeader />
      <Container className="mt-3 mb-3 main-contain-withoutfoot">
        {(selectedbtn == "sell" || selectedbtn == "buy") && (
          <Row>
            <div className="col-12  mb-2">
              <Form.Group as={Col} md="12">
                <div className="input-group">
                  <div className="input-group-text">
                    <i className="bi bi-search"></i>
                  </div>
                  <Form.Control
                    type="text"
                    name="search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Write Anything..."
                    required
                  />
                </div>
              </Form.Group>
            </div>
          </Row>
        )}
        <PostTabs />
        <Row>
          <Col>
            {!!getFrontEndPostsBlank && getFrontEndPostsBlank.length > 0 && (
              <Button
                className="w-100 button-color"
                onClick={() => {
                  setPage(page + 1);
                  dispatch(
                    getFrontEndPosts({
                      type: postTypeid ? postTypeid : "general",
                      page: page + 1,
                    })
                  );
                }}
              >
                Load More
              </Button>
            )}
          </Col>
        </Row>
       
      </Container>
      {
        width?.current > 668 && <Row className="p-0 m-0 sub-foot">
        <Col>
          <Link to="/disclaimer">Disclaimer</Link>
        </Col>
        <Col>
          <Link to="/privacy_policy">Privacy Statement</Link>
        </Col>
        <Col>
          <Link to="/terms_of_use">Terms of Use</Link>
        </Col>
        <Col>
          <Link to="/cancellation-and-refund">Cancellation And Refund Policy</Link>
        </Col>
        {/* <Col>
          <Link to="/shipping-delivery">Shipping & Delivery Policy</Link>
        </Col> */}
        </Row>
      }
      {width?.current > 668 && <Row className="p-0 m-0 sub-foot">
        <hr/>
        <Col md={12} className="p-0"><p className="m-0 text-white">© {new Date().getFullYear()} VT INFOTECH. All Rights Reserved.</p></Col>
        </Row>}
    </>
  );
};

export default Homepage;
