import React from "react";
import AppHeader from "../Components/AppHeader";
import { useEffect } from "react";
import { Row } from "react-bootstrap";

const TermsOfUse = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AppHeader />
      <div className="content mb-5">
        <div className="container">
            <Row>
            <h2 className="about-content pt-3 pb-3">Terms Of Use </h2>
            </Row>
          <div className="row">
           <p>	
By visiting our site you are agreeing to be bound by the following terms and conditions. We may change these terms and conditions at any time. Your continued use of sabkamunafa.com means that you accept any new or modified terms and conditions that we come up with. Please re-visit the 'Terms of Use' link at our site from time to time to stay abreast of any changes that we may introduce.</p>

            <p>The term 'sabkamunafa.com' is used through this entire Terms of Use document to refer to the website, its owners and the employees and associates of the owner
            </p>
            <h4>1)REGISTRATION</h4>
           <p>By registering, you certify that all information you provide, now or in the future, is accurate. sabkamunafa.com reserves the right, in its sole discretion, to deny you access to this website or any portion thereof without notice for the following reasons (a) immediately by sabkamunafa.com for any unauthorized access or use by you (b) immediately by sabkamunafa.com if you assign or transfer (or attempt the same) any rights granted to you under this Agreement; (c) immediately, if you violate any of the other terms and conditions of this User Agreement
I agree to get periodic SMS alerts.
I agree to get periodic newsletters.</p>
<h4>2) LICENSE</h4>
<p>sabkamunafa.com, hereby grants you a limited, non-exclusive, non-assignable and non-transferable license to access sabkamunafa.com provided and expressly conditioned upon your agreement that all such access and use shall be governed by all of the terms and conditions set forth in this USER AGREEMENT post payment of subscription fees.</p>
<h4>3) COPYRIGHT & NO RETRANSMISSION OF INFORMATION:</h4>
<p>sabkamunafa.com as well as the design and information contained in this site is the valuable, exclusive property of sabkamunafa.com, and nothing in this Agreement shall be construed as transferring or assigning any such ownership rights to you or any other person or entity. All commodity call information on sabkamunafa.com is the proprietary, confidential property of sabkamunafa.com and cannot be repeated for any reason outside the sabkamunafa.com. You agree not to repeat or rebroadcast in any way any of the commodity call information made on sabkamunafa.com for any reason whatsoever. You agree that if you do repeat or re-post any of sabkamunafa.com calls by any mean, you will be liable for actual and punitive damages as determined by sabkamunafa.com and additional damages to be determined by an Indian court of Law You may not resell, redistribute, broadcast or transfer the information or use the information in a searchable, machine-readable database unless separately and specifically authorized in writing by sabkamunafa.com prior to such use. You may not rent, lease, sublicense, distribute, transfer, copy, reproduce, publicly display, publish, adapt, store or time-share sabkamunafa.com, any part thereof, or any of the information received or accessed therefrom to or through any other person or entity unless separately and specifically authorized in writing by sabkamunafa.com prior to such use. In addition, you may not remove, alter or obscure any copyright, legal or proprietary notices in or on any portions of sabkamunafa.com without prior written authorization Except as set forth herein, any other use of the information contained in this site requires the prior written consent of mcd and may require a separate fee.</p>
<h4>4)DELAYS IN SERVICES:</h4>
<p>Neither sabkamunafa.com (including its and their directors, employees, affiliates, agents, representatives or subcontractors) shall be liable for any loss or liability resulting, directly or indirectly, from delays or interruptions due to electronic or mechanical equipment failures, telephone interconnect problems, defects, weather, strikes, walkouts, fire, acts of God, riots, armed conflicts, acts of war, or other like causes. sabkamunafa.com shall have no responsibility to provide you access to sabkamunafa.com while interruption of sabkamunafa.com is due to any such cause shall continue.</p>
<h4>5) LIABILITY DISCLAIMER:</h4>
<p>YOU EXPRESSLY AGREE THAT USE OF THE WEBSITE IS AT YOUR SOLE RISK. THE CONTENTS, INFORMATION, SOFTWARE, PRODUCTS, FEATURES AND SERVICES PUBLISHED ON THIS WEB SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE CONTENTS HEREIN. sabkamunafa.com AND/OR ITS RESPECTIVE SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THIS WEB SITE AT ANY TIME. THIS WEB SITE MAY BE TEMPORARILY UNAVAILABLE FROM TIME TO TIME DUE TO REQUIRED MAINTENANCE, TELECOMMUNICATIONS INTERRUPTIONS, OR OTHER DISRUPTIONS. sabkamunafa.com (AND ITS OWNERS, SUPPLIERS, CONSULTANTS, ADVERTISERS, AFFILIATES, PARTNERS, EMPLOYEES OR ANY OTHER ASSOCIATED ENTITIES, ALL COLLECTIVELY REFERRED TO AS ASSOCIATED ENTITIES HEREAFTER) SHALL NOT BE LIABLE TO USER OR MEMBER OR ANY THIRD PARTY SHOULD sabkamunafa.com EXERCISE ITS RIGHT TO MODIFY OR DISCONTINUE ANY OR ALL OF THE CONTENTS, INFORMATION, SOFTWARE, PRODUCTS, FEATURES AND SERVICES PUBLISHED ON THIS WEBSITE.</p>
<p>sabkamunafa.com AND/OR ITS RESPECTIVE ASSOCIATED ENTITIES MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY OF THE CONTENTS, INFORMATION, SOFTWARE, PRODUCTS, FEATURES AND SERVICES CONTAINED ON THIS WEB SITE FOR ANY PURPOSE. ALL SUCH CONTENTS, INFORMATION, SOFTWARE, PRODUCTS, FEATURES AND SERVICES ARE PROVIDED "AS IS" WITHOUT WARRANTY OF ANY KIND. sabkamunafa.com AND/OR ITS ASSOCIATED ENTITIES HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THESE CONTENTS, INFORMATION, SOFTWARE, PRODUCTS, FEATURES AND SERVICES, INCLUDING ALL IMPLIED WARRANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND AVAILABILITY. IN NO EVENT SHALL sabkamunafa.com AND/OR ITS ASSOCIATED ENTITIES BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF THIS WEB SITE OR WITH THE DELAY OR INABILITY TO USE THIS WEBSITE, OR FOR ANY CONTENTS, INFORMATION, SOFTWARE, PRODUCTS, FEATURES AND SERVICES OBTAINED THROUGH THIS WEB SITE, OR OTHERWISE ARISING OUT OF THE USE OF THIS WEB SITE, WHETHER BASED ON CONTRACT, TORT, STRICT LIABILITY OR OTHERWISE, EVEN IF sabkamunafa.com OR ANY OF ITS ASSOCIATED ENTITIES HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.</p>
<h4>6)EQUIPMENT AND OPERATION</h4>
<p>You shall provide and maintain all telephone/internet and other equipment necessary to access sabkamunafa.com, and the costs of any such equipment and/or telephone/internet connections or use, including any applicable taxes, shall be borne solely by you. You are responsible for operating your own equipment used to access sabkamunafa.com</p>
<h4>7) INFORMATION DISCLAIMER:</h4>
<p>You acknowledge that the information provided through sabkamunafa.com is compiled from sources, which are beyond the control of sabkamunafa.com Though such information is recognized by the parties to be generally reliable, the parties acknowledge that inaccuracies may occur and sabkamunafa.com does not warrant the accuracy or suitability of the information. For this reason, as well as the possibility of human and mechanical errors and other factors, YOU ACKNOWLEDGE THAT sabkamunafa.com IS PROVIDED TO YOU ON AN "AS IS, WITH ALL FAULTS" BASIS. sabkamunafa.com EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES, WHETHER EXPRESS, ORAL, IMPLIED, STATUTORY OR OTHERWISE, OF ANY KIND TO THE USERS AND/OR ANY THIRD PARTY, INCLUDING ANY IMPLIED WARRANTIES OF ACCURACY, TIMELINESS, COMPLETENESS, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AS WELL AS ANY WARRANTIES ARISING BY VIRTUE OF CUSTOM OF TRADE OR COURSE OF DEALING AND ANY IMPLIED WARRANTIES OF TITLE OR NON-INFRINGEMENT. IN ADDITION, sabkamunafa.com, IN PROVIDING THE INFORMATION MAKES NO ENDORSEMENT OF ANY PARTICULAR SECURITY, MARTKET PARTICIPANT, OR BROKERAGE. FURTHER, sabkamunafa.com DOES NOT REPRESENT OR WARRANT THAT IT WILL MEET YOUR REQUIREMENTS OR IS SUITABLE FOR YOUR NEEDS.</p>
<p>Under this User Agreement, you assume all risk of errors and/or omissions in sabkamunafa.com, including the transmission or translation of information. You assume full responsibility for implementing sufficient procedures and checks to satisfy your requirements for the accuracy and suitability of sabkamunafa.com, including the information, and for maintaining any means, which you may require for the reconstruction of lost data or subsequent manipulations or analyses of the information under the User Agreement.</p>
<p>YOU AGREE THAT sabkamunafa.com (INCLUDING ITS AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, GROUP COMPANIES AGENTS, REPRESENTATIVES OR SUBCONTRACTORS) SHALL NOT IN ANY EVENT BE LIABLE FOR ANY SPECIAL, INCIDENTAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF THE USE OR INABILITY TO USE sabkamunafa.com FOR ANY PURPOSE WHATSOEVER. sabkamunafa.com AND IT'S AFFILIATES, OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS SHALL HAVE NO LIABILITY IN TORT, CONTRACT, OR OTHERWISE TO USER AND/OR ANY THIRD PARTY.</p>
<h4>8) LINKS TO THIRD PARTY SITES</h4>
<p>The links in this site will allow you to leave sabkamunafa.com. The linked sites are not under the control of sabkamunafa.com. sabkamunafa.com has not reviewed, nor approved these sites and is not responsible for the contents or omissions of any linked site or any links contained in a linked site. The inclusion of any linked site does not imply endorsement by sabkamunafa.com of the site. Third party links to sabkamunafa.com shall be governed by a separate agreement.</p>
<h4>9) INDEMNIFICATION:</h4>
<p>YOU SHALL INDEMNIFY, DEFEND AND HOLD HARMLESS sabkamunafa.com (INCLUDING ITS AND THEIR OFFICERS, DIRECTORS, EMPLOYEES, AFFILIATES, GROUP COMPANIES, AGENTS, REPRESENTATIVES OR SUBCONTRACTORS) FROM ANY AND ALL CLAIMS AND LOSSES IMPOSED ON, INCURRED BY OR ASSERTED AS A RESULT OF OR RELATED TO: (a) your access and use of sabkamunafa.com (b) any non-compliance by user with the terms and conditions hereof; or (c) any third party actions related to users receipt and use of the information, whether authorized or unauthorized. Any clause declared invalid shall be deemed severable and not affect the validity or enforceability of the remainder. These terms may only be amended in a writing signed by sabkamunafa.com</p>
<h4>10) CONFLICTING TERMS:</h4>
<p>If there is any conflict between this User Agreement and other documents, this User Agreement shall govern, whether such order or other documents is prior to or subsequent to this User Agreement, or is signed or acknowledged by any director, officer, employee, representative or agent of sabkamunafa.com.</p>
<h4>11) ATTORNEY'S FEES:</h4>
<p>If sabkamunafa.com takes action (by itself or through its associate companies) to enforce any of the provisions of this User Agreement, including collection of any amounts due hereunder, mcd shall be entitled to recover from you (and you agree to pay), in addition to all sums to which it is entitled or any other relief, at law or in equity, reasonable and necessary attorney's fees and any costs of any litigation.</p>
<h4>12) ENTIRE AGREEMENT:</h4>
<p>This User Agreement constitutes the entire agreement between the parties, and no other agreement, written or oral, exists between you and sabkamunafa.com. By using the Information on sabkamunafa.com, you assume full responsibility for any and all gains and losses, financial, emotional or otherwise, experienced, suffered or incurred by you. sabkamunafa.com does not guarantee the accuracy, completeness or timeliness of, or otherwise endorse in any way, the views, opinions or recommendations expressed in the Information, does not give investment advice, and does not advocate the purchase or sale of any security or investment by you or any other individual. The Information is not intended to provide tax, legal or investment advice, which you should obtain from your professional advisor prior to making any investment of the type discussed in the Information. The Information does not constitute a solicitation by the information providers, sabkamunafa.com or other of the purchase or sale of securities.</p>
<p>THE SERVICE IS PROVIDED "AS IS," WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION, ANY WARRANTY FOR INFORMATION, DATA, SERVICES, UNINTERRUPTED ACCESS, OR PRODUCTS PROVIDED THROUGH OR IN CONNECTION WITH THE SERVICE. SPECIFICALLY, sabkamunafa.com DISCLAIMS ANY AND ALL WARRANTIES, INCLUDING, BUT NOT LIMITED TO: (i) ANY WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY, USEFULNESS, OR CONTENT OF INFORMATION, PRODUCTS OR SERVICES; AND (ii) ANY WARRANTIES OF TITLE, WARRANTY OF NON-INFRINGEMENT, WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE. THIS DISCLAIMER OF LIABILITY APPLIES TO ANY DAMAGES OR INJURY CAUSED BY ANY FAILURE OF PERFORMANCE, ERROR, OMISSION, INTERRUPTION, DELETION, DEFECT, DELAY IN OPERATION OR TRANSMISSION, COMPUTER VIRUS, COMMUNICATION LINE FAILURE, THEFT OR DESTRUCTION OR UNAUTHORIZED ACCESS TO, ALTERATION OF, OR USE OF RECORD, WHETHER FOR BREACH OF CONTRACT, TORT, NEGLIGENCE, OR UNDER ANY OTHER CAUSE OF ACTION.</p>
<p>NEITHER sabkamunafa.com NOR ANY OF ITS EMPLOYEES, AGENTS, SUCCESSORS, ASSIGNS, AFFILIATES, GROUP COMPANIES OR CONTENT OR SERVICE PROVIDERS SHALL BE LIABLE TO YOU OR OTHER THIRD PARTY FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL OR CONSEQUENTIAL DAMAGES ARISING OUT OF USE OF SERVICE OR INABILITY TO GAIN ACCESS TO OR USE THE SERVICE OR OUT OF ANY BREACH OF ANY WARRANTY. BECAUSE SOME COUNTRIES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IN SUCH COUNTRIES, THE RESPECTIVE LIABILITY OF sabkamunafa.com, ITS EMPLOYEES, AGENTS, SUCCESSORS, ASSIGNS, AFFILIATES, GROUP COMPANIES AND CONTENT OR SERVICE PROVIDERS RESPECTIVE LIABILITY IS LIMITED TO THE AMOUNT PROVIDED UNDER SAID LAW. FURTHER, YOU AGREE AND UNDERSTAND THAT ALL SERVICES PROVIDED ARE NON-REFUNDABLE AND THAT YOU SHOULD CAREFULLY CONSIDER WHETHER OUR SERVICES ARE ABLE TO MEET YOUR NEEDS.</p>
<h4>13) TERMINATION:</h4>
<p>This User Agreement and the license rights granted hereunder shall remain in full force and effect unless terminated or canceled for any of the following reasons: (a) immediately by sabkamunafa.com for any unauthorized access or use by you (b) immediately by sabkamunafa.com if you assign or transfer (or attempt the same) any rights granted to you under this Agreement; (c) immediately, if you violate any of the other terms and conditions of this User Agreement. Termination or cancellation of this Agreement shall not effect any right or relief to which sabkamunafa.com may be entitled, at law or in equity. Upon termination of this User Agreement, all rights granted to you will terminate and revert to sabkamunafa.com. Except as set forth herein, regardless of the reason for cancellation or termination of this User Agreement, the fee charged if any for access to sabkamunafa.com is non-refundable for any reason.</p>
<h4>14) JURISDICTION:</h4>
<p>The terms of this agreement are exclusively based on and subject to Indian law. You hereby consent to the exclusive jurisdiction and venue of courts in Mumbai, India in all disputes arising out of or relating to the use of this website. Use of this website is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and conditions, including without limitation this paragraph.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
