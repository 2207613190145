import React, { useRef } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import AppHeader from "../../Components/AppHeader";
import paymentqr from "../../images/paymentqr.png"

const PaymentDetails = () => {
  const width = useRef(window.innerWidth);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  return (
    <>
      <AppHeader />
      <div className="content mt-2">
        <Container>
          <Row>
            <Col md={12}>
              <div className="mb-2">
                <Row>
                  <Col md={6} xs={12} sm={12} className="mt-2">
                    <h4 className="mb-0">Bank Details</h4>
                    <hr className="mt-0"/>
                    <p className="mb-0">
                      <b>VT Infotech</b>
                    </p>
                    <p className="mb-0">
                      <b>A/c 201026439434<br/>
                        IFSC: INDB0000145<br/>
                        Indusind Bank <br/>
                        Sector-7 Branch Rohini, Delhi
                      </b>
                    </p>
                  </Col>
                  <Col md={6} xs={12} sm={12} className="mt-2 ">
                  <h4 className="mb-0">For Paytm, Gpay, PhonePe</h4>
                  <hr className="mt-0"/>
                  <div className="text-center">
                    <img className="cursore-pointer" src={paymentqr}/>
                  </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col md={12} className="mt-3">
            <div className="mb-2 text-center">
            <p className="mt-4 mb-0 f-11"><b>Note</b>:- After payment please share a screenshot on this whatsapp.</p>
              <Button onClick={() => openInNewTab("https://api.whatsapp.com/send?phone=+919355808088&text=I'm Interested in your services...&source=&data=&app_absent=")} className={`b-r-50 ${width?.current > 668 ? `mt-2 w-50` : `w-100`}`} variant="success"><i className="bi bi-whatsapp"></i> +91 9355808088</Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default PaymentDetails;
