import React, { useEffect, useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { useDispatch } from 'react-redux';
import { paymentReturnUrl } from '../../reducers/commonReducer';
import { useNavigate } from 'react-router-dom';

export default function PaymentResp() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [respMessage, setRespMessage] = useState();
    const queryParameters = new URLSearchParams(window.location.search);
    const paymentId = queryParameters.get("id");
    useEffect(async() => {
        const apiresp = await dispatch(paymentReturnUrl({
            id: paymentId
        }))
        setRespMessage(apiresp?.payload?.message)
    },[])

  return (
    <div className='bg-c-green'>
    <Container>
        <Row className='payment-resp'>
            <Col md={12}>
                <h2>{respMessage}</h2>
                <a href="/">Back To Home <i className="bi bi-arrow-right-short"></i></a>
            </Col>
        </Row>
    </Container>
    </div>
  )
}
