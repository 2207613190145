import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getFrontEndPosts,
  menuBottomTab,
  popUpCloseFoot,
  restPosts,
} from "../../reducers/commonReducer";
import CreatePost from "../HomePage/CreatePost";
import { Button, Col, Container, Row } from "react-bootstrap";
import feedd from "../../images/footer/feedd.png";
import feed from "../../images/footer/feed.png";
import marketd from "../../images/footer/marketd.png";
import market from "../../images/footer/market.png";
import newsd from "../../images/footer/newsd.png";
import news from "../../images/footer/news.png";
import priced from "../../images/footer/priced.png";
import price from "../../images/footer/price.png";
import plusd from "../../images/footer/plusd.png";
import plus from "../../images/footer/plus.png";
const Footer = () => {
  const dispatch = useDispatch();
  const [createPost, setCreatePost] = useState(false);
  const [page, setPage] = useState(1);
  const { appMenuTab } = useSelector((state) => state.commonReducer);
  const queryParameters = new URLSearchParams(window.location.search);
  const postTypeid = queryParameters.get("postType");
  useEffect(() => {
    document.addEventListener("click", handleClickOutSide, true);
  }, []);
  const refOne = useRef(null);

  let handleClickOutSide = (e) => {
    if (e.target.classList.contains("btn-close")) {
      setCreatePost(false);
      dispatch(popUpCloseFoot({status:1}))
    } else if (Boolean(e.target.closest(".post-create-form"))) {
      console.log("click on form");
    } else {
      if (!refOne.current.contains(e.target)) {
        setCreatePost(false);
        dispatch(popUpCloseFoot({status:1}))
        console.log("click out side");
      } else {
        console.log("click in side");
      }
    }
  };

  return (
    <>
      <Container className="mt-55">
        {createPost && (
          <Row>
            <div className="app-sab-menu" ref={refOne}>
              <Container>
                <Row>
                    <Col xs={6} sm={6}>
                    <CreatePost
                      postType={"sell"}
                      buttonTitle={"Seller"}
                      setCreatePost={setCreatePost}
                    />
                  </Col>
                  <Col xs={6} sm={6}>
                    <CreatePost
                      postType={"buy"}
                      buttonTitle={"Buyer"}
                      setCreatePost={setCreatePost}
                    />
                  </Col>
                </Row>
                <Row className="mt-1">
                  <Col xs={12} sm={12}>
                    <Button
                      variant="danger"
                      className="w-100"
                      onClick={() => setCreatePost(false)}
                    >
                      Close
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </Row>
        )}
        <Row>
          <div className="d-flex bootom-img-nav bottom-20 header-bg-color text-white footer-bottom fixed-bottom align-items-center pt-5-0  justify-content-around">
            <Col>
              <div className="footer-link">
                <p
                  className="mb-0"
                  onClick={() => {
                    dispatch(menuBottomTab({ bottomtab: "general" }));
                  }}
                >
                  <Link
                    to="/?postType=general"
                    className="botom-border-0 foot-img-icon"
                    onClick={() => {
                      dispatch(restPosts());
                      dispatch(
                        getFrontEndPosts({
                          type: "general",
                          page: page,
                        })
                      );
                    }}
                  >
                    {appMenuTab?.bottomtab === "general" ? (
                      <>
                        <img src={feedd} />
                        <p className="mb-0 app-foot-text">General</p>
                      </>
                    ) : (
                      <>
                        <img src={feed} />
                        <p className="mb-0 app-foot-text text-secondary">
                          General
                        </p>
                      </>
                    )}
                  </Link>
                </p>
              </div>
            </Col>
            {/* <Col>
              <div className="footer-link">
                <p
                  className="mb-0"
                  onClick={() => {
                    dispatch(restPosts());
                    dispatch(menuBottomTab({ bottomtab: "sell", page: page }));
                  }}
                >
                  <Link
                    to="/?postType=sell"
                    className="botom-border-0 foot-img-icon"
                    onClick={() => {
                      dispatch(
                        getFrontEndPosts({
                          type: "sell",
                        })
                      );
                    }}
                  >
                    {appMenuTab?.bottomtab === "sell" ? (
                      <>
                        <img src={priced} />
                        <p className="mb-0 app-foot-text ">Sell/Buy</p>
                      </>
                    ) : (
                      <>
                        <img src={price} />
                        <p className="mb-0 app-foot-text text-secondary">
                          Sell/Buy
                        </p>
                      </>
                    )}
                  </Link>
                </p>
              </div>
            </Col> */}
            <Col className="plus-tab-mbl-menu">
              {/* <div
                className="footer-link"
                onClick={() => {
                  setCreatePost(true);
                  dispatch(menuBottomTab({ bottomtab: "mainplus" }));
                }}
              >
                {appMenuTab?.bottomtab === "mainplus" ? (
                  <img className="foot-img-43 rotate" src={plusd} onClick={() => dispatch(popUpCloseFoot({
                    status: 0
                  }))}/>
                ) : (
                  <img className="foot-img-43 rotate" src={plus} onClick={() => dispatch(popUpCloseFoot({
                    status: 0
                  }))}/>
                )}
              </div> */}
            </Col>
            {/* <Col>
              <div className="footer-link">
                <p
                  className="mb-0"
                  onClick={() => {
                    dispatch(menuBottomTab({ bottomtab: "livemarket" }));
                  }}
                >
                  <Link to="/market" className="botom-border-0 foot-img-icon">
                    {appMenuTab?.bottomtab === "livemarket" ? (
                      <>
                        <img src={marketd} />
                        <p className="mb-0 app-foot-text">Live Market</p>
                      </>
                    ) : (
                      <>
                        <img src={market} />
                        <p className="mb-0 app-foot-text text-secondary">
                          Live Market
                        </p>
                      </>
                    )}
                  </Link>
                </p>
              </div>
            </Col> */}
            <Col>
              <div className="footer-link">
                <p
                  className="mb-0"
                  onClick={() => {
                    dispatch(menuBottomTab({ bottomtab: "news" }));
                  }}
                >
                  {" "}
                  <Link to="/news" className="botom-border-0 foot-img-icon">
                    {appMenuTab?.bottomtab === "news" ? (
                      <>
                        <img src={newsd} />
                        <p className="mb-0 app-foot-text">News</p>
                      </>
                    ) : (
                      <>
                        <img src={news} />
                        <p className="mb-0 app-foot-text text-secondary">
                          News
                        </p>
                      </>
                    )}
                  </Link>
                </p>
              </div>
            </Col>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Footer;
