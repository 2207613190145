import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "../../market.css";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getMonthName } from "../../const";

const MarketAppUi = ({matchedData,ecbotBeanOil,ecbotSoyaOil,ecbotSoyaMealOil,cottonCandy,cottonCake,castorSeeds,mcxCrudeOil}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // useEffect(() => {
  //   dispatch(getMarketDetails());
  // }, []);
  const { getMarketlist, loading } = useSelector((state) => state.commonReducer);
  let brentCrudeSpot = getMarketlist?.BrentCrudeSpot?.Other;
  let witCrudeOil = getMarketlist?.WTICrudeOilSpot?.Other;
  let usdInr = getMarketlist?.USDINR?.Other;
  let usdMyr = getMarketlist?.USDMYR?.Other;
  return (
    <>
     {(loading === undefined || loading) ?  <h3 className="text-info text-center mt-5">
        Loading.....
      </h3> : 
            <Container className="market-mbl">
              {/*------- MDEXCPOLive --------*/}
                {
                  !!matchedData && matchedData.map((data, index) => {
                    let monthWise = getMarketlist?.MDEXCPOLive[data]
                    return(
                      <Row
                      className="market-main pt-1 pb-1"
                      key={index}
                      // onClick={() => navigate(`/market/${monthWise?._id}`)}
                    >
                      <Col md={7} sm={7} xs={7}>
                        <h6 className="mb-2">
                          {monthWise?.nm.replace("Live", "")}{" "}
                          {monthWise?.mnth > 0 ? getMonthName(Number(monthWise?.mnth)) : ""}{" "}
                          {monthWise?.yr}
                        </h6>
                        <p
                          className={`mb-0 ${
                            Number(monthWise?.chgn) > 0 ? "text-success" : "text-danger"
                          } time-zon`}
                        >
                        {moment(monthWise?.updatedAt).format("h:mm A")}
                        </p>
                      </Col>
                      <Col md={5} sm={5} xs={5} className="float-right">
                        <h6 className="mb-2">{Number(monthWise?.LAST).toFixed(2)}</h6>
                        <br />
                        <p
                          className={`mb-0 market-mbl-up-down ${
                            Number(monthWise?.chgn) > 0 ? "text-success" : "text-danger"
                          }`}
                        >
                          {Number(monthWise?.chgn).toFixed(2)} (
                          {Number(monthWise?.perchgn).toFixed(2)}%)
                        </p>
                      </Col>
                      <hr className="mb-0" />
                    </Row>
                    )})}

              {/*------- ECBOT Bean Oil Live --------*/}
                {
                  !!ecbotBeanOil && ecbotBeanOil.map((data, index) => {
                    let monthWise = getMarketlist?.ECBOTBeanOilLive[data]
                    return(
                      <Row
                      className="market-main pt-1 pb-1"
                      key={index}
                      // onClick={() => navigate(`/market/${monthWise?._id}`)}
                    >
                      <Col md={7} sm={7} xs={7}>
                        <h6 className="mb-2">
                          {monthWise?.nm.replace("Live", "")}{" "}
                          {monthWise?.mnth > 0 ? getMonthName(Number(monthWise?.mnth)) : ""}{" "}
                          {monthWise?.yr}
                        </h6>
                        <p
                          className={`mb-0 ${
                            Number(monthWise?.chgn) > 0 ? "text-success" : "text-danger"
                          } time-zon`}
                        >
                        {moment(monthWise?.updatedAt).format("h:mm A")}
                        </p>
                      </Col>
                      <Col md={5} sm={5} xs={5} className="float-right">
                        <h6 className="mb-2">{Number(monthWise?.LAST).toFixed(2)}</h6>
                        <br />
                        <p
                          className={`mb-0 market-mbl-up-down ${
                            Number(monthWise?.chgn) > 0 ? "text-success" : "text-danger"
                          }`}
                        >
                          {Number(monthWise?.chgn).toFixed(2)} (
                          {Number(monthWise?.perchgn).toFixed(2)}%)
                        </p>
                      </Col>
                      <hr className="mb-0" />
                    </Row>
                    )})}
              {/*------- ECBOT Soya Oil Live --------*/}
                {
                  !!ecbotSoyaOil && ecbotSoyaOil.map((data, index) => {
                    let monthWise = getMarketlist?.ECBOTSoyBeansLive[data]
                    return(
                      <Row
                      className="market-main pt-1 pb-1"
                      key={index}
                      // onClick={() => navigate(`/market/${monthWise?._id}`)}
                    >
                      <Col md={7} sm={7} xs={7}>
                        <h6 className="mb-2">
                          {monthWise?.nm.replace("Live", "")}{" "}
                          {monthWise?.mnth > 0 ? getMonthName(Number(monthWise?.mnth)) : ""}{" "}
                          {monthWise?.yr}
                        </h6>
                        <p
                          className={`mb-0 ${
                            Number(monthWise?.chgn) > 0 ? "text-success" : "text-danger"
                          } time-zon`}
                        >
                        {moment(monthWise?.updatedAt).format("h:mm A")}
                        </p>
                      </Col>
                      <Col md={5} sm={5} xs={5} className="float-right">
                        <h6 className="mb-2">{Number(monthWise?.LAST).toFixed(2)}</h6>
                        <br />
                        <p
                          className={`mb-0 market-mbl-up-down ${
                            Number(monthWise?.chgn) > 0 ? "text-success" : "text-danger"
                          }`}
                        >
                          {Number(monthWise?.chgn).toFixed(2)} (
                          {Number(monthWise?.perchgn).toFixed(2)}%)
                        </p>
                      </Col>
                      <hr className="mb-0" />
                    </Row>
                    )})}
              {/*------- ECBOT Soya meal Live --------*/}
                {
                  !!ecbotSoyaMealOil && ecbotSoyaMealOil.map((data, index) => {
                    let monthWise = getMarketlist?.ECBOTSoyMealLive[data]
                    return(
                      <Row
                      className="market-main pt-1 pb-1"
                      key={index}
                      // onClick={() => navigate(`/market/${monthWise?._id}`)}
                    >
                      <Col md={7} sm={7} xs={7}>
                        <h6 className="mb-2">
                          {monthWise?.nm.replace("Live", "")}{" "}
                          {monthWise?.mnth > 0 ? getMonthName(Number(monthWise?.mnth)) : ""}{" "}
                          {monthWise?.yr}
                        </h6>
                        <p
                          className={`mb-0 ${
                            Number(monthWise?.chgn) > 0 ? "text-success" : "text-danger"
                          } time-zon`}
                        >
                        {moment(monthWise?.updatedAt).format("h:mm A")}
                        </p>
                      </Col>
                      <Col md={5} sm={5} xs={5} className="float-right">
                        <h6 className="mb-2">{Number(monthWise?.LAST).toFixed(2)}</h6>
                        <br />
                        <p
                          className={`mb-0 market-mbl-up-down ${
                            Number(monthWise?.chgn) > 0 ? "text-success" : "text-danger"
                          }`}
                        >
                          {Number(monthWise?.chgn).toFixed(2)} (
                          {Number(monthWise?.perchgn).toFixed(2)}%)
                        </p>
                      </Col>
                      <hr className="mb-0" />
                    </Row>
                    )})}
              {/*------- COTTONCANDY --------*/}
                {
                  !!cottonCandy && cottonCandy.map((data, index) => {
                    let monthWise = getMarketlist?.COTTONCANDY[data]
                    return(
                      <Row
                      className="market-main pt-1 pb-1"
                      key={index}
                      // onClick={() => navigate(`/market/${monthWise?._id}`)}
                    >
                      <Col md={7} sm={7} xs={7}>
                        <h6 className="mb-2">
                          {monthWise?.nm.replace("Live", "")}{" "}
                          {monthWise?.mnth > 0 ? getMonthName(Number(monthWise?.mnth)) : ""}{" "}
                          {monthWise?.yr}
                        </h6>
                        <p
                          className={`mb-0 ${
                            Number(monthWise?.chgn) > 0 ? "text-success" : "text-danger"
                          } time-zon`}
                        >
                        {moment(monthWise?.updatedAt).format("h:mm A")}
                        </p>
                      </Col>
                      <Col md={5} sm={5} xs={5} className="float-right">
                        <h6 className="mb-2">{Number(monthWise?.LAST).toFixed(2)}</h6>
                        <br />
                        <p
                          className={`mb-0 market-mbl-up-down ${
                            Number(monthWise?.chgn) > 0 ? "text-success" : "text-danger"
                          }`}
                        >
                          {Number(monthWise?.chgn).toFixed(2)} (
                          {Number(monthWise?.perchgn).toFixed(2)}%)
                        </p>
                      </Col>
                      <hr className="mb-0" />
                    </Row>
                    )})}
              {/*------- CottonCake --------*/}
                {
                  !!cottonCake && cottonCake.map((data, index) => {
                    let monthWise = getMarketlist?.CottonCake[data]
                    return(
                      <Row
                      className="market-main pt-1 pb-1"
                      key={index}
                      // onClick={() => navigate(`/market/${monthWise?._id}`)}
                    >
                      <Col md={7} sm={7} xs={7}>
                        <h6 className="mb-2">
                          {monthWise?.nm.replace("Live", "")}{" "}
                          {monthWise?.mnth > 0 ? getMonthName(Number(monthWise?.mnth)) : ""}{" "}
                          {monthWise?.yr}
                        </h6>
                        <p
                          className={`mb-0 ${
                            Number(monthWise?.chgn) > 0 ? "text-success" : "text-danger"
                          } time-zon`}
                        >
                        {moment(monthWise?.updatedAt).format("h:mm A")}
                        </p>
                      </Col>
                      <Col md={5} sm={5} xs={5} className="float-right">
                        <h6 className="mb-2">{Number(monthWise?.LAST).toFixed(2)}</h6>
                        <br />
                        <p
                          className={`mb-0 market-mbl-up-down ${
                            Number(monthWise?.chgn) > 0 ? "text-success" : "text-danger"
                          }`}
                        >
                          {Number(monthWise?.chgn).toFixed(2)} (
                          {Number(monthWise?.perchgn).toFixed(2)}%)
                        </p>
                      </Col>
                      <hr className="mb-0" />
                    </Row>
                    )})}
                {/*------- CastorSeed --------*/}
                {
                  !!castorSeeds && castorSeeds.map((data, index) => {
                    let monthWise = getMarketlist?.CastorSeed[data]
                    return(
                      <Row
                      className="market-main pt-1 pb-1"
                      key={index}
                      // onClick={() => navigate(`/market/${monthWise?._id}`)}
                    >
                      <Col md={7} sm={7} xs={7}>
                        <h6 className="mb-2">
                          {monthWise?.nm.replace("Live", "")}{" "}
                          {monthWise?.mnth > 0 ? getMonthName(Number(monthWise?.mnth)) : ""}{" "}
                          {monthWise?.yr}
                        </h6>
                        <p
                          className={`mb-0 ${
                            Number(monthWise?.chgn) > 0 ? "text-success" : "text-danger"
                          } time-zon`}
                        >
                        {moment(monthWise?.updatedAt).format("h:mm A")}
                        </p>
                      </Col>
                      <Col md={5} sm={5} xs={5} className="float-right">
                        <h6 className="mb-2">{Number(monthWise?.LAST).toFixed(2)}</h6>
                        <br />
                        <p
                          className={`mb-0 market-mbl-up-down ${
                            Number(monthWise?.chgn) > 0 ? "text-success" : "text-danger"
                          }`}
                        >
                          {Number(monthWise?.chgn).toFixed(2)} (
                          {Number(monthWise?.perchgn).toFixed(2)}%)
                        </p>
                      </Col>
                      <hr className="mb-0" />
                    </Row>
                    )})}
                 {/*------- MCX Crude Oil Live  --------*/}
                {
                  !!mcxCrudeOil && mcxCrudeOil.map((data, index) => {
                    let monthWise = getMarketlist?.MCXCrudeOilLive[data]
                    return(
                      <Row
                      className="market-main pt-1 pb-1"
                      key={index}
                      // onClick={() => navigate(`/market/${monthWise?._id}`)}
                    >
                      <Col md={7} sm={7} xs={7}>
                        <h6 className="mb-2">
                          {monthWise?.nm.replace("Live", "")}{" "}
                          {monthWise?.mnth > 0 ? getMonthName(Number(monthWise?.mnth)) : ""}{" "}
                          {monthWise?.yr}
                        </h6>
                        <p
                          className={`mb-0 ${
                            Number(monthWise?.chgn) > 0 ? "text-success" : "text-danger"
                          } time-zon`}
                        >
                        {moment(monthWise?.updatedAt).format("h:mm A")}
                        </p>
                      </Col>
                      <Col md={5} sm={5} xs={5} className="float-right">
                        <h6 className="mb-2">{Number(monthWise?.LAST).toFixed(2)}</h6>
                        <br />
                        <p
                          className={`mb-0 market-mbl-up-down ${
                            Number(monthWise?.chgn) > 0 ? "text-success" : "text-danger"
                          }`}
                        >
                          {Number(monthWise?.chgn).toFixed(2)} (
                          {Number(monthWise?.perchgn).toFixed(2)}%)
                        </p>
                      </Col>
                      <hr className="mb-0" />
                    </Row>
                    )})}
                {/*------- brentCrudeSpot  --------*/}
                    {!!brentCrudeSpot && <Row
                      className="market-main pt-1 pb-1"
                    >
                      <Col md={7} sm={7} xs={7}>
                        <h6 className="mb-2">
                          {brentCrudeSpot?.nm.replace("Live", "")}{" "}
                          {brentCrudeSpot?.mnth > 0 ? getMonthName(Number(brentCrudeSpot?.mnth)) : ""}{" "}
                          {brentCrudeSpot?.yr}
                        </h6>
                        <p
                          className={`mb-0 ${
                            Number(brentCrudeSpot?.chgn) > 0 ? "text-success" : "text-danger"
                          } time-zon`}
                        >
                        {moment(brentCrudeSpot?.updatedAt).format("h:mm A")}
                        </p>
                      </Col>
                      <Col md={5} sm={5} xs={5} className="float-right">
                        <h6 className="mb-2">{Number(brentCrudeSpot?.LAST).toFixed(2)}</h6>
                        <br />
                        <p
                          className={`mb-0 market-mbl-up-down ${
                            Number(brentCrudeSpot?.chgn) > 0 ? "text-success" : "text-danger"
                          }`}
                        >
                          {Number(brentCrudeSpot?.chgn).toFixed(2)} (
                          {Number(brentCrudeSpot?.perchgn).toFixed(2)}%)
                        </p>
                      </Col>
                      <hr className="mb-0" />
                    </Row>}
                     {/*------- witCrudeOil  --------*/}
                     {!!witCrudeOil && <Row
                      className="market-main pt-1 pb-1"
                    >
                      <Col md={7} sm={7} xs={7}>
                        <h6 className="mb-2">
                          {witCrudeOil?.nm.replace("Live", "")}{" "}
                          {witCrudeOil?.mnth > 0 ? getMonthName(Number(witCrudeOil?.mnth)) : ""}{" "}
                          {witCrudeOil?.yr}
                        </h6>
                        <p
                          className={`mb-0 ${
                            Number(witCrudeOil?.chgn) > 0 ? "text-success" : "text-danger"
                          } time-zon`}
                        >
                        {moment(witCrudeOil?.updatedAt).format("h:mm A")}
                        </p>
                      </Col>
                      <Col md={5} sm={5} xs={5} className="float-right">
                        <h6 className="mb-2">{Number(witCrudeOil?.LAST).toFixed(2)}</h6>
                        <br />
                        <p
                          className={`mb-0 market-mbl-up-down ${
                            Number(witCrudeOil?.chgn) > 0 ? "text-success" : "text-danger"
                          }`}
                        >
                          {Number(witCrudeOil?.chgn).toFixed(2)} (
                          {Number(witCrudeOil?.perchgn).toFixed(2)}%)
                        </p>
                      </Col>
                      <hr className="mb-0" />
                    </Row>}
                     {/*------- usdInr  --------*/}
                    {!!usdInr && <Row
                      className="market-main pt-1 pb-1"
                    >
                      <Col md={7} sm={7} xs={7}>
                        <h6 className="mb-2">
                          {usdInr?.nm.replace("Live", "")}{" "}
                          {usdInr?.mnth > 0 ? getMonthName(Number(usdInr?.mnth)) : ""}{" "}
                          {usdInr?.yr}
                        </h6>
                        <p
                          className={`mb-0 ${
                            Number(usdInr?.chgn) > 0 ? "text-success" : "text-danger"
                          } time-zon`}
                        >
                        {moment(usdInr?.updatedAt).format("h:mm A")}
                        </p>
                      </Col>
                      <Col md={5} sm={5} xs={5} className="float-right">
                        <h6 className="mb-2">{Number(usdInr?.LAST).toFixed(2)}</h6>
                        <br />
                        <p
                          className={`mb-0 market-mbl-up-down ${
                            Number(usdInr?.chgn) > 0 ? "text-success" : "text-danger"
                          }`}
                        >
                          {Number(usdInr?.chgn).toFixed(2)} (
                          {Number(usdInr?.perchgn).toFixed(2)}%)
                        </p>
                      </Col>
                      <hr className="mb-0" />
                    </Row>}
                     {/*------- usdMyr  --------*/}
                     {!!usdMyr && <Row
                      className="market-main pt-1 pb-1"
                    >
                      <Col md={7} sm={7} xs={7}>
                        <h6 className="mb-2">
                          {usdMyr?.nm.replace("Live", "")}{" "}
                          {usdMyr?.mnth > 0 ? getMonthName(Number(usdMyr?.mnth)) : ""}{" "}
                          {usdMyr?.yr}
                        </h6>
                        <p
                          className={`mb-0 ${
                            Number(usdMyr?.chgn) > 0 ? "text-success" : "text-danger"
                          } time-zon`}
                        >
                        {moment(usdMyr?.updatedAt).format("h:mm A")}
                        </p>
                      </Col>
                      <Col md={5} sm={5} xs={5} className="float-right">
                        <h6 className="mb-2">{Number(usdMyr?.LAST).toFixed(2)}</h6>
                        <br />
                        <p
                          className={`mb-0 market-mbl-up-down ${
                            Number(usdMyr?.chgn) > 0 ? "text-success" : "text-danger"
                          }`}
                        >
                          {Number(usdMyr?.chgn).toFixed(2)} (
                          {Number(usdMyr?.perchgn).toFixed(2)}%)
                        </p>
                      </Col>
                      <hr className="mb-0" />
                    </Row>}
          </Container>
      }
    </>
  );
};

export default MarketAppUi;
