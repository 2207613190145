import React from "react";
import { ProgressBar } from "react-bootstrap";
import { useSelector } from "react-redux";

export const ProfileProgress = () => {
  const { userProfileData } = useSelector((state) => state.commonReducer);
  let processBar = "40";
  if (
    userProfileData?.brokers?.length > 0 &&
    userProfileData?.products?.length > 0
  ) {
    processBar = "100";
  } else if (userProfileData?.products?.length > 0) {
    processBar = "70";
  } else if (userProfileData?.brokers?.length > 0) {
    processBar = "70";
  } else {
    processBar = "40";
  }
  return (
    processBar !== "100" && (
      <ProgressBar
        striped
        variant="success"
        now={processBar}
        label={`${processBar}%`}
      />
    )
  );
};
