import React, { useRef } from "react";
import AppHeader from "../../Components/AppHeader";
import { useEffect } from "react";

const AboutUs = () => {
  const width = useRef(window.innerWidth);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <AppHeader />
      <div className="about-img">
        <div className={`container text-white ${width?.current > 668 && `pt-5 pb-5`}`}>
          <h2 className="about-content pt-5 pb-5"> About Us </h2>
        </div>
      </div>
      <div className="content mb-5">
        <div className="container">
          <div className="row main-about-content mt-3">
            <h4><b>Sabka Munafa - BUYER -BROKER -SELLER Network & Oil Price Tracker</b></h4>
            <p>
            Welcome to Sabka Munafa, your go-to app for tracking real-time oil prices and connecting brokers, buyers, and sellers within the oil market ecosystem. Whether you're a broker looking to facilitate transactions or a buyer/seller seeking profitable deals, Sabka Munafa empowers you to navigate the oil market with precision and build valuable partnerships.
            </p>
            <h5>1. Real-Time Oil Price Updates</h5>
            <p>
            Stay informed with instant access to real-time oil prices from global markets. Keep a finger on the pulse of price changes and trends that drive trading decisions.
            </p>
            <h5>2. Interactive Price Charts</h5>
            <p>
            Visualize historical price data through interactive charts. Analyze price movements across different timeframes to make informed buying and selling choices.
            </p>
            <h5>3. Customized Alerts</h5>
            <p>
            Set up personalized alerts to receive notifications when specific oil prices match your target range. Never miss out on potential profit opportunities.
            </p>
            <h5>4. Broker Matchmaking</h5>
            <p>Brokers can connect with buyers and sellers seeking their services. Facilitate seamless transactions and build fruitful relationships within the community.</p>
            <h5>5. Buyer and Seller Profiles</h5>
            <p>Create detailed profiles to showcase your offerings as a buyer or seller. Highlight your products, quantities, and preferences, enhancing your visibility to potential partners.</p>
            <h5>6. Transaction Facilitation</h5>
            <p>Brokers can assist buyers and sellers in negotiating deals, ensuring a smooth transaction process for all parties involved.</p>
            <h5>7. Market Insights</h5>
            <p>Access up-to-date market news, analysis, and insights to make informed decisions. Understand the factors influencing oil prices and market trends.</p>
            <h5>8. Secure Communication</h5>
            <p>Engage in secure and private conversations with brokers, buyers, and sellers within the app. Foster productive discussions while maintaining data privacy.</p>
            <h5>9. Trade Opportunities</h5>
            <p>Discover potential trade opportunities through networking and discussions. Connect with partners who complement your business objectives.</p>
            <h5>10. Verified Profiles</h5>
            <p>Ensure authenticity with verified broker, buyer, and seller profiles. Build trust and confidence when engaging in transactions.</p>
            <h5>11. Bargain Feature</h5>
            <p>A user can Maintain its bargain on the app on the even while the user is travelling anywhere in the world. He can write down his Bargains on the app.</p>
            <p>Whether you're a broker connecting parties or a buyer/seller seeking lucrative opportunities, Sabka Munafa offers a comprehensive platform for successful transactions and profitable partnerships. Download the app now to be part of a thriving network that focuses on mutual growth, success, and profitability.</p>
            <p><b>Note:</b> This is a fictional app description created based on the information provided. There may not be an actual app named "Sabka Munafa" with these features.</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
