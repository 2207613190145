import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { getUsers, restRecord, updateUser } from "../../reducers/commonReducer";

// const schema = yup.object().shape({
//   name: yup.string().required(),
//   status: yup.string().required(),
//   category : yup.string().required()
// });
function Update(props) {
  const {fName,lName,userAdrs,userCity,userState,userPhone,userEmail,userFirmName,userProducts,status,id,referralPayment} = props;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Button variant="primary" className="px-1 py-0" onClick={handleShow}>
        <i className="bi bi-pencil-square"></i>
      </Button>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update User</Modal.Title>
        </Modal.Header>
        <Formik
          onSubmit={async (values, { resetForm }) => {
            await dispatch(updateUser(values));
            resetForm({ values: "" });
            dispatch(restRecord())
            dispatch(getUsers());
            setShow(false);
          }}
          initialValues={{
            firstName: fName,
            lastName: lName,
            address: userAdrs,
            city: userCity,
            state: userState,
            phone: userPhone,
            email: userEmail,
            firmName: userFirmName,
            // products: userProducts,
            // brokers: userBrokers,
            id,
            status: status,
            referralPayment:referralPayment
          }}
        >
          {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>FirstName</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="FirstName"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      isInvalid={!!errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>LastName</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="LastName"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      isInvalid={!!errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Address"
                      name="address"
                      value={values.address}
                      onChange={handleChange}
                      isInvalid={!!errors.address}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.address}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="city"
                      name="city"
                      value={values.city}
                      onChange={handleChange}
                      isInvalid={!!errors.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="State"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                      isInvalid={!!errors.state}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.state}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Phone"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group>
                    <Form.Label>FirmName</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="FirmName"
                      name="firmName"
                      value={values.firmName}
                      onChange={handleChange}
                      isInvalid={!!errors.firmName}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.firmName}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              {/* <Row className="mb-3">
                <Col md={6}>
                  <Form.Control
                    as="select"
                    name="products"
                    className="form-control"
                  >
                    <option value={""}>Select Products</option>
                  </Form.Control>
                </Col>
                <Col md={6}>
                  <Form.Control
                    as="select"
                    name="brokers"
                    className="form-control"
                  >
                    <option value={""}>Select Brokers</option>
                  </Form.Control>
                </Col>
              </Row> */}
              <Row className="mb-3">
                <Col md={6}>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("status", e.target.value);
                    }}
                    name="status"
                    className="form-control"
                    value={values.status}
                    isInvalid={!!errors.status}
                  >
                    <option value="">Select Status</option>
                    <option value={true} selected>
                      True
                    </option>
                    <option value={false}>False</option>
                  </Form.Control>
                </Col>
                <Col md={6}>
                  <Form.Label>Referral Payment</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("referralPayment", e.target.value);
                    }}
                    name="referralPayment"
                    className="form-control"
                    value={values.referralPayment}
                    isInvalid={!!errors.referralPayment}
                  >
                    <option value="">Select Status</option>
                    <option value={false}>Pending</option>
                    <option value={true}>Complete</option>
                  </Form.Control>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default Update;
