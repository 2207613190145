import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import AppHeader from "../../Components/AppHeader";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import { contactForm } from "../../reducers/commonReducer";

// Validation by Yup
const schema = yup.object().shape({
  name: yup.string().required(),
  phone: yup.string().min(10).max(10).required("Number is required."),
  comment: yup.string().required(),
});

const Contactus = () => {
  const dispatch = useDispatch();
  const handleEmailClick = (email) => {
    window.location.href = `mailto:${email}`;
  };
  return (
    <>
      <AppHeader />
      <div className="content mt-2">
        <Container>
          <Row>
            <Col md={6}>
              <div className="mb-2">
                <p className="mb-0">
                  <b>Call Now</b>
                </p>
                <p className="mb-0">
                <i className="bi bi-telephone-inbound-fill"></i> +91 9355808088
                </p>
              </div>
              <div className="mb-2">
                <p className="mb-0">
                  <b>Address</b>
                </p>
                <p className="mb-0">
                    VT INFOTECH<br/>
                    G-139, Rohini Heights<br/>
                    Sector- 29 Rohini Delhi-110042<br/>
                    Jyotsna- 9355808088
                </p>
              </div>
              <div className="mb-2">
                <p className="mb-0">
                  <b>Email</b>
                </p>
                <p className="mb-0">
                  {/* <Link
                    to="#"
                    onClick={() => handleEmailClick("contact.sabkamunafa@gmail.com")}
                  > */}
                    <i className="bi bi-envelope-at-fill"></i>{" "}
                    contact.sabkamunafa@gmail.com
                  {/* </Link> */}
                </p>
              </div>
            </Col>
            <Col md={6} className="mt-2">
              <h4 className="mb-0 mb-2 text-center">
                <b>Contact Us</b>
              </h4>
              <Formik
                validationSchema={schema}
                onSubmit={(values, { resetForm }) => {
                  dispatch(contactForm(values));
                  resetForm({ values: "" });
                }}
                initialValues={{
                  name: "",
                  phone: "",
                  comment: "",
                  email:''
                }}
              >
                {({ handleSubmit, handleChange, values, errors }) => (
                  <Form noValidate onSubmit={handleSubmit}>
                    <div className="col-12 ">
                      <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="bi bi-person-fill"></i>
                          </div>
                          <Form.Control
                            type="text"
                            name="name"
                            value={values.name}
                            onChange={handleChange}
                            isInvalid={!!errors.name}
                            className="form-control"
                            placeholder="Name"
                            required
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-12 mt-2">
                      <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="bi bi-telephone-fill"></i>
                          </div>
                          <Form.Control
                            type="number"
                            name="phone"
                            value={values.phone}
                            onChange={handleChange}
                            isInvalid={!!errors.phone}
                            className="form-control"
                            placeholder="Phone"
                            required
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.phone}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-12 mt-2">
                      <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                          <i className="bi bi-envelope-check-fill"></i>
                          </div>
                          <Form.Control
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                            className="form-control"
                            placeholder="email"
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-12 mt-2">
                      <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="bi bi-pen-fill"></i>
                          </div>
                          <Form.Control
                            type="text"
                            as="textarea"
                            row={4}
                            name="comment"
                            value={values.comment}
                            onChange={handleChange}
                            isInvalid={!!errors.comment}
                            className="form-control"
                            placeholder="Write Your Comment....."
                            required
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.comment}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div className="col-12 text-center">
                      <Button
                        type="submit"
                        className=" button-color login-btn px-4 mt-4 w-100"
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Contactus;
