import React, { useEffect } from "react";
import { Button, Col, Table ,Container, Row } from "react-bootstrap";
import AppHeader from "../../Components/AppHeader";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isIOS } from "../../common";
import {
  activatePlan,
  buySellSubscription,
  getSettings,
  getSubscriptionSetting,
  userProfile,
} from "../../reducers/commonReducer";

const SubscriptionNew = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(userProfile());
    dispatch(getSubscriptionSetting());
    dispatch(
      getSettings({
        filter: "subscription",
      })
    );
  }, []);
  const subscriptionApi = async () => {
    const apiResponse = await dispatch(buySellSubscription());
    console.log("apiResponse", apiResponse)
    if (apiResponse?.payload?.status === 1) {
      return setTimeout(() => {
        window.location.replace(apiResponse?.payload?.paymentUrl);
      }, 1000);
    }
    return null;
  };
  const { getSubscriptionList, userProfileData } = useSelector(
    (state) => state.commonReducer
  );

  return (
    <>
      <AppHeader />
      <div className="subscription-img pb-5">
        <Container>
          <div className="row mt-2">
            <h3 className="text-center blink-text">
              <b>{`Upgrade to Premium`}</b>
            </h3>
          </div>
          <Table striped bordered className="text-center mt-2">
      <thead>
        <tr>
          <th>Name</th>
          <th className={`${userProfileData?.subscription?.status === 2 && 'bg-success text-white'}`}>Free</th>
          <th className={`${userProfileData?.subscription?.status === 3 && 'bg-success text-white'}`}>Premium</th>
        </tr>
      </thead>
      <tbody>
      <tr>
          <td className="px-0"><b>Price</b></td>
          <td className="px-0"><b>Free <br/>(Life Time)</b></td>
          <td className="px-0"><b>{!!getSubscriptionList?.subscriptionprice &&
                    getSubscriptionList?.subscriptionprice}/- <br/>({!!getSubscriptionList?.subscriptionmonth &&
                      getSubscriptionList?.subscriptionmonth} Months)</b></td>
        </tr>
        <tr>
          <td><b>Products</b></td>
          <td><b>5</b></td>
          <td><b>Unlimited</b></td>
        </tr>
        <tr>
          <td><b>Bargain</b></td>
          <td><i className="bi bi-dash-circle wrong-icon"></i></td>
          <td><i className="bi bi-check-circle right-icon"></i></td>
        </tr>
        <tr>
          <td><b>Market</b></td>
          <td><i className="bi bi-check-circle right-icon"></i></td>
          <td><i className="bi bi-check-circle right-icon"></i></td>
        </tr>
        <tr>
          <td><b>News</b></td>
          <td><i className="bi bi-check-circle right-icon"></i></td>
          <td><i className="bi bi-check-circle right-icon"></i></td>
        </tr>
        <tr>
          <td><b>Update Products</b></td>
          <td><i className="bi bi-dash-circle wrong-icon"></i></td>
          <td><i className="bi bi-check-circle right-icon"></i></td>
        </tr>
        <tr>
          <td><b>Profile Update</b></td>
          <td><i className="bi bi-dash-circle wrong-icon"></i></td>
          <td><i className="bi bi-check-circle right-icon"></i></td>
        </tr>
        <tr>
          <td></td>
          <td>
            <Button variant="warning" 
                  onClick={async() => {
                    const result = await dispatch(
                      activatePlan({
                        status:2
                        // subscription: true,
                        // plan: "Free",
                      })
                    );
                    if(result?.payload?.status){
                      setTimeout(() => {
                        navigate(`/my-profile`)
                      }, 1000);
                    }
                    setTimeout(() => {
                      dispatch(userProfile());
                    }, 500);
                  }} className="w-100"
                  disabled={(userProfileData?.subscription?.status === 2) ? true : false}
                  >Plan Activate</Button></td>
          <td>
            <Button variant="warning" 
                  onClick={()=> subscriptionApi()} className="w-100"
                  disabled={userProfileData?.subscription?.status === 3 ? true : false}>Plan Activate</Button>
          </td>
        </tr>
      </tbody>
    </Table>
    <Row>
      <Col md={12}>
        <p>Please Select Your Products <Link className="blink-text" to={`/my-profile`}><Button>Click Here</Button></Link></p>
      </Col>
    </Row>
        </Container>
      </div>
    </>
  );
};

export default SubscriptionNew;
