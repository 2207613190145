import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import {Nav, Col, Row} from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import oilimg from "../images/sbka-munafa.png";
import logo from "../images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import NavDropdown from 'react-bootstrap/NavDropdown';
import {
  userLogOut,
  menuBottomTab,
  userProfile,
  areYouLogin,
} from "../reducers/commonReducer";
import { Navbar } from "react-bootstrap";
import homeIcon from "../images/menu/home.png";
import livemarket from "../images/menu/live_market.png";
import aboutus from "../images/menu/about_us.png";
import contactus from "../images/menu/contact_us.png";
import loginIcon from "../images/menu/login.png";
import logoutIcon from "../images/menu/logout.png";
import mybargines from "../images/menu/my_bargines.png";
import myenquiery from "../images/menu/my_enquiery.png";
import postIcon from "../images/menu/posticon.png";
import subscriptionsIcon from "../images/menu/subscriptions.png";
import { isIOS } from "../common";

const AppHeader = () => {
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const queryParameters = new URLSearchParams(window.location.search);
  const postID = queryParameters.get("postType");
  const loginChecker = async () => {
    const apiResponse = await dispatch(areYouLogin());
    if (apiResponse?.payload?.status === 0) {
      localStorage.clear();
    }
    return null;
  };
  useEffect(() => {
    loginChecker();
    localStorage.getItem("x-auth-token") && dispatch(userProfile());
    dispatch(
      menuBottomTab({
        homepost: postID ? postID : "general",
        bottomtab:
          (postID === "general" && "general") ||
          (postID === "sell" && "sell") ||
          (location?.pathname === "/" && "general") ||
          (location?.pathname === "/market" && "livemarket") ||
          (location?.pathname === "/news" && "news"),
      })
    );
  }, []);
  const { userProfileData } = useSelector((state) => state.commonReducer);
  const logout = async () => {
    const apiResponse = await dispatch(userLogOut());
    localStorage.clear();
    if (!localStorage.getItem("x-auth-token") || apiResponse?.payload?.status === 1) {
      localStorage.clear();
      return navigate("/");
    }
    return null;
  };
  let pageName;
  if (location.pathname === "/") {
    pageName = "Posts";
  } else if (location.pathname === "/subscription") {
    pageName = "Subscription";
  } else if (location.pathname === "/market") {
    pageName = "Live Market";
  } else if (location.pathname === "/bargins") {
    pageName = "My Bargain";
  } else if (location.pathname === "/contact-us") {
    pageName = "Contact Us";
  } else if (location.pathname === "/about-us") {
    pageName = "About Us";
  } else if (location.pathname === "/my-orders") {
    pageName = "My Enquiries";
  } else if (location.pathname === "/my-profile") {
    pageName = "Profile / Products";
  } else if (location.pathname === "/news-details") {
    pageName = "News";
  } else if (location.pathname === "/blog-details") {
    pageName = "View Post";
  } else if (location.pathname === "/news") {
    pageName = "News";
  } else if (location.pathname === "/transpoters") {
    pageName = "Transpoters";
  } else {
    pageName = "Sabka Munafa";
  }

  return (
    <>
      <div className="fixed-top position-sticky header-bg-color">
        <div className="container-fluid py-1">
          {width?.current < 468 ? (
            <Nav
              className="justify-content-between footer-icon align-items-center"
              activeKey="/"
            >
              <Nav.Item>
                <Nav.Link eventKey="link-2" className="p-0 m-0">
                  <Button
                    className="p-0 m-0 mbl-menu-btn desktop-logotitle"
                    onClick={handleShow}
                  >
                    <i className="bi bi-text-left"></i>
                  </Button>
                  <Offcanvas end show={show} onHide={handleClose}>
                    <Offcanvas.Header
                      className="app-spacing-0 px-2 close-btn-app"
                    >
                      <Offcanvas.Title>
                        <img className="p-2 fs-1 app-logo-big" src={logo} />
                      </Offcanvas.Title>
                      <Button
                        className="p-0 m-0 mbl-menu-btn"
                        onClick={handleClose}
                      >
                        <i className="bi bi-x-circle"></i>
                      </Button>
                    </Offcanvas.Header>
                    <Offcanvas.Body className="p-0">
                      <Nav
                        defaultActiveKey="/"
                        className="flex-column app-sidebaar mt-3"
                      >
                        {
                          localStorage.getItem("x-auth-token") && <>
                          <div className="container client-wellcome">
                          <h4 className="mb-0">Welcome</h4>
                          <p className="mb-0">{userProfileData?.firmName ? userProfileData?.firmName : `${userProfileData?.firstName} ${userProfileData?.lastName}`}</p>
                          </div>
                          </>
                        }
                        <Link
                          onClick={() =>
                            setTimeout(() => {
                              handleClose();
                              navigate("/");
                            }, 500)
                          }
                        >
                          <img src={homeIcon} />
                          Home{" "}
                        </Link>
                        {localStorage.getItem("x-auth-token") && (
                          <>
                            <Link
                              onClick={() =>
                                setTimeout(() => {
                                  handleClose();
                                  navigate(userProfileData?.subscription?.plan ===
                                      "Pro" ? "/bargins"
                                      : "/subscription"
                                  );
                                }, 500)
                              }
                            >
                              <img src={mybargines} />
                              My Bargain 
                            </Link>
                            <Link
                              onClick={() =>
                                setTimeout(() => {
                                  handleClose();
                                  navigate("/my-posts");
                                }, 500)
                              }
                            >
                              <img src={postIcon} />
                              Posts
                            </Link>
                            <Link
                              onClick={() =>
                                setTimeout(() => {
                                  handleClose();
                                  navigate("/my-orders");
                                }, 500)
                              }
                            >
                              <img src={postIcon} />
                              Enquiries
                            </Link>
                            <Link
                              onClick={() =>
                                setTimeout(() => {
                                  handleClose();
                                  navigate("/payments");
                                }, 500)
                              }
                            >
                              <img src={livemarket} />
                              Payments
                            </Link>
                            <Link
                              onClick={() =>
                                setTimeout(() => {
                                  handleClose();
                                  navigate("/my-profile");
                                }, 500)
                              }
                            >
                              <img src={loginIcon} />
                              Profile / Products
                            </Link>
                            {
                            <Link
                              onClick={() =>
                                setTimeout(() => {
                                  handleClose();
                                  navigate("/subscription");
                                }, 500)
                              }
                            >
                              {" "}
                              <img src={subscriptionsIcon} />
                              Subscription{" "}
                            </Link>
                            }
                          </>
                        )}
                        {localStorage.getItem("x-auth-token") && (
                            <Link to="/request-new-product">
                              <img src={myenquiery} /> Request Product
                            </Link>
                        )}
                        <Link
                          onClick={() =>
                            setTimeout(() => {
                              handleClose();
                              navigate("/about-us");
                            }, 500)
                          }
                        >
                          <img src={aboutus} />
                          About Us
                        </Link>
                        {<Link
                          onClick={() =>
                            setTimeout(() => {
                              handleClose();
                              navigate("/contact-us");
                            }, 500) 
                          }
                        >
                          <img src={contactus} />
                          Contact Us
                        </Link>}
                        {localStorage.getItem("x-auth-token") ? (
                          <>
                          <Nav.Link
                            eventKey="link-2"
                            onClick={() =>
                              setTimeout(() => {
                                navigate("/sent-otp");
                              }, 500)
                            }
                          >
                            <img src={loginIcon} />
                            Change Password
                          </Nav.Link>
                          <Nav.Link
                            eventKey="link-2"
                            onClick={() =>
                              setTimeout(() => {
                                logout();
                                navigate("/login");
                              }, 500)
                            }
                          >
                            <img src={logoutIcon} />
                            Log Out
                          </Nav.Link>
                          </>
                        ) : (
                          <Link
                            onClick={() =>
                              setTimeout(() => {
                                handleClose();
                                navigate("/login");
                              }, 500)
                            }
                          >
                            <img src={loginIcon} />
                            Login/Signup
                          </Link>
                        )}
                      </Nav>
                    </Offcanvas.Body>
                    <div className="app-header-bottom w-100 m-0">
                      <Row className="side-bar-foot">
                        <Col xs={4} sm={4} className="p-0 text-center">
                        <Link
                          onClick={() =>
                            setTimeout(() => {
                              handleClose();
                              navigate("/disclaimer");
                            }, 500)
                          }
                        >
                          Disclaimer
                        </Link>
                        </Col>
                        <Col xs={4} sm={4} className="p-0 text-center">
                        <Link
                          onClick={() =>
                            setTimeout(() => {
                              handleClose();
                              navigate("/privacy_policy");
                            }, 500)
                          }
                        >
                          Privacy Statement
                        </Link>
                        </Col>
                        <Col xs={4} sm={4} className="p-0 text-center">
                        <Link
                          onClick={() =>
                            setTimeout(() => {
                              handleClose();
                              navigate("/terms_of_use");
                            }, 500)
                          }
                        >
                          Terms of Use
                        </Link>
                        </Col>
                      </Row>
                      <hr className="mt-1 mb-1"/>
                        <Row className="side-bar-foot">
                        <Col xs={12} sm={12} className="p-0 text-center">
                        <Link
                          onClick={() =>
                            setTimeout(() => {
                              handleClose();
                              navigate("/cancellation-and-refund");
                            }, 500)
                          }
                        >
                          Cancellation And Refund Policy
                        </Link>
                        </Col>
                        {/* <Col xs={6} sm={6} className="p-0 text-center">
                        <Link
                          onClick={() =>
                            setTimeout(() => {
                              handleClose();
                              navigate("/shipping-delivery");
                            }, 500)
                          }
                        >
                          Shipping & Delivery Policy
                        </Link>
                        </Col> */}
                        </Row>
                       
                    </div>
                  </Offcanvas>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Link eventKey="link-1" to={"/"} className="desktop-logotitle">
                  <img className="app-logo" src={oilimg} />
                  {pageName}
                </Link>
              </Nav.Item>
              <Nav.Item>
                <Link eventKey="link-1" to={"/"} className="desktop-logotitle">
                  <i className="bi bi-bell-fill"></i>
                </Link>
              </Nav.Item>
            </Nav>
          ) : (
            <Navbar variant="dark">
              <div className="container-fluid">
                <Link eventKey="link-1" to={"/"} className="desktop-logotitle">
                  <img className="w-70" src={logo} />
                </Link>
                <Nav
                  defaultActiveKey="/"
                  className="desktop-menu-main desktop-icons-img"
                >
                  <Link to="/">
                    <img src={homeIcon} /> Home
                  </Link>
                  {
                    localStorage.getItem("x-auth-token") && <Link to="/subscription">
                    <img src={subscriptionsIcon} /> Subscription
                  </Link>
                  }
                  <Link to="/about-us">
                    <img src={aboutus} /> About Us
                  </Link>
                  <Link to="/contact-us">
                    <img src={contactus} /> Contact Us
                  </Link>
                  {/* <Link to="/market">
                    <img src={livemarket} /> Live Market
                  </Link> */}
                  <Link to="/news">
                    <img src={aboutus} /> News
                  </Link>
                  {localStorage.getItem("x-auth-token") && (
                      <Link to="/request-new-product">
                        <img src={myenquiery} /> Request Product
                      </Link>
                  )}
                  {localStorage.getItem("x-auth-token") && (
                  <NavDropdown title="My Account" id="basic-nav-dropdown">
                    <NavDropdown.Item className="sub-menu-desk"><Link to="/my-orders" className="p-0"><img src={myenquiery} /> My Enquiries</Link></NavDropdown.Item>
                    <NavDropdown.Item className="sub-menu-desk"><Link to={userProfileData?.subscription?.plan === "Pro"
                            ? "/bargins"
                            : "/subscription"
                        } className="p-0"> <img src={mybargines} /> My Bargain</Link></NavDropdown.Item>
                    <NavDropdown.Item className="sub-menu-desk"><Link to="/my-posts" className="p-0"> <img src={postIcon} /> My Posts</Link></NavDropdown.Item>
                    <NavDropdown.Item className="sub-menu-desk"><Link to="/sent-otp"className="p-0"> <img src={postIcon} /> Change Password</Link></NavDropdown.Item>
                    <NavDropdown.Item className="sub-menu-desk"><Link to="/payments"className="p-0"> <img src={livemarket} /> Payments</Link></NavDropdown.Item>
                    <NavDropdown.Item className="sub-menu-desk"><Link to="/my-profile"className="p-0"> <img src={loginIcon} /> My Profile</Link></NavDropdown.Item>
                  </NavDropdown>)}
                  {localStorage.getItem("x-auth-token") ? (
                    <Nav.Link
                      eventKey="link-2"
                      onClick={logout}
                      className="p-0"
                    >
                      <Button className="button-color btn">
                        <img src={logoutIcon} />
                        Log Out
                      </Button>
                    </Nav.Link>
                  ) : (
                    <Link to="/login">
                      <Button className="button-color btn">Login/Signup</Button>
                    </Link>
                  )}
                </Nav>
              </div>
            </Navbar>
          )}
        </div>
      </div>
    </>
  );
};

export default AppHeader;
