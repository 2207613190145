import React from 'react'

export default function Refferal() {
    const queryParameters = new URLSearchParams(window.location.search);
    const userid = queryParameters.get("userid");

    let redirectUrl = 'https://apps.apple.com/us/app/sabka-munafa-sell-buy/id6473688844/?referid=' + (userid ? userid : '' ); //apple store url
    // let redirectUrl = `http://sabkamunafa.com/`

    if(navigator.userAgent.toLowerCase().indexOf("android") > -1)
    {
    //  redirectUrl = "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.Tambola.Tambola&referrer=utm_source%3D"+ (userid ? userid : '' );

      redirectUrl = "https://play.google.com/store/apps/details?id=com.SabkaMunafa.SabkaMunafa&referrer=utm_source%3D"+ (userid ? userid : '' );

    }
    window.location.href = redirectUrl; 
  return (
    <></>
  )
}
