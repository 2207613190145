import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getProducts, getEnabledCategories } from "../../reducers/commonReducer";
import Add from "./Add";
import Update from "./Update";
import red from "../../images/red.png";
import green from "../../images/green.png";

const List = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProducts());
    dispatch(getEnabledCategories());
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
  }, []);
  const { getProductsData } = useSelector((state) => state.commonReducer);
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table"> 
        <Col md={12}>
        <h3>Product List</h3>
          </Col> 
          <hr/>
          <Col md={6}>
            <Button className="mb-3 mobile-width" variant="primary" onClick={handleShow}>
              Add Product
            </Button>
          </Col>
          <Col className="text-end" md={6}>
              <Form.Control
                type="search"
                placeholder="Search"
                className="me-2"
                aria-label="Search"
                onChange={(e)=>{
                   dispatch(getProducts({
                     search:e.target.value.charAt(0).toUpperCase() +
                     e.target.value.slice(1)
                   }))
                }}
              />
          </Col>
        </Row>
          
          <div className="overflow-auto">
        <Table striped bordered hover size="sm" className="overflow-scroll">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Name</th>
              <th>Description</th>
              <th>Category</th>
              <th>Sell Ads</th>
              <th>Buy Ads</th>
              <th>Orders</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!!getProductsData?.list ?
              getProductsData?.list.map((data, index) => {
                return (
                  <tr key={data.id}>
                    <td>{index + 1}.</td>
                    <td>
                      {data?.name}
                    </td>
                    <td>{data?.description}</td>
                    <td>{data?.category?.name}</td>
                    <td>
                      <NavLink to={`/posts?product=${data.id}&type=sell`}>
                        <Button className="m-1" variant="success">
                          Sell <i className="bi bi-arrow-right"></i>
                        </Button>
                      </NavLink>
                   </td>
                   <td>
                      <NavLink to={`/posts?product=${data.id}&type=buy`}>
                        <Button className="m-1" variant="info">
                        Buy <i className="bi bi-arrow-right"></i>
                        </Button>
                      </NavLink>
                    </td>
                    <td>
                      <NavLink to={`/orders?product=${data.id}`}>
                        <Button className="m-1" variant="info">
                        Orders <i className="bi bi-arrow-right"></i>
                        </Button>
                      </NavLink>
                    </td>
                    <td className="table-img">
                      {data?.status === true ? <img src={green} /> : <img src={red} />}
                    </td>
                    <td>
                      <Update
                        title="Update Product"
                        name={data?.name}
                        description={data?.description}
                        category={data?.category.id}
                        status={data?.status}
                        id={data?.id}
                      />
                    </td>
                  </tr>
                );
              }) : 'No record found'} 
          </tbody>
        </Table> 

        </div>
      
      </div>

      <Add showhide={show} handleClose={handleClose} />
    </>
  );
};

export default List;
