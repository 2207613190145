import React from "react";
import AppHeader from "../Components/AppHeader";
import productone from "../images/products-img/sunflower.webp";
import newsone from "../images/news-img/post-3.jpg.webp";

const NewsDetails = () => {
  return (
    <>
      <AppHeader />
      <div className="container">
        <div className="blog-content border mt-5">
          <div className="heading">
            <h2>Lorem Ipsum: when, and when not to use it</h2>

            <div className="mt-2">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
                soluta quasi impedit dolore voluptatibus tempora, tempore
                delectus illum placeat nostrum aliquam consequatur blanditiis?
                Fugiat alias dolorum mollitia quasi corrupti at Lorem, ipsum
                dolor sit amet consectetur adipisicing elit. Animi nihil itaque
                suscipit! Fuga aut officia, provident iure modi dicta ut
                asperiores dolores nostrum beatae quibusdam, amet voluptatem,
                debitis atque fugiat.
              </p>
            </div>

            <div className="mt-2">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
                soluta quasi impedit dolore voluptatibus tempora, tempore
                delectus illum placeat nostrum aliquam consequatur blanditiis?
                Fugiat alias dolorum mollitia quasi corrupti at Lorem, ipsum
                dolor sit amet consectetur adipisicing elit. Animi nihil itaque
                suscipit! Fuga aut officia, provident iure modi dicta ut
                asperiores dolores nostrum beatae quibusdam, amet voluptatem,
                debitis atque fugiat.
              </p>
            </div>

            <img className="blog-details-img" src={newsone} alt="" />

            <div className="mt-2">
              <h2>Lorem Ipsum: when, and when not to use it</h2>
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Sit
                soluta quasi impedit dolore voluptatibus tempora, tempore
                delectus illum placeat nostrum aliquam consequatur blanditiis?
                Fugiat alias dolorum mollitia quasi corrupti at Lorem, ipsum
                dolor sit amet consectetur adipisicing elit. Animi nihil itaque
                suscipit! Fuga aut officia, provident iure modi dicta ut
                asperiores dolores nostrum beatae quibusdam, amet voluptatem,
                debitis atque fugiat.
              </p>
            </div>

            <div className="comment border-top">
              <ul className="list-inline mt-3">
                <li className="list-inline-item">0 views</li>
                <li className="list-inline-item">0 comments</li>
                <li className="list-inline-item">
                  2 <i className="bi bi-suit-heart"></i>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NewsDetails;
