import React, { useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch, useSelector } from "react-redux";
import {
  addPost,
  getOwnPosts,
  restPosts,
} from "../../reducers/commonReducer";
import { Formik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import moment from "moment";

const schema = yup.object().shape({
  type: yup.string().required(),
  product: yup.string().required(),
  qty: yup.string().required(),
  start_time: yup.string().required(),
  end_time: yup.string().required(),
  description: yup.string().max(100, 'Description must be at most 100 characters long'),
  status: yup.string().required(),
  price: yup.number().required(),
  // preferred_brokers: yup.string().required(),
});
const CreatePost = (props) => {
  const {
    postType,
    buttonTitle,
    ucity,
    udescription,
    uend_time,
    uprice,
    upreferred_brokers,
    uproduct,
    uqty,
    ustart_time,
    ustate,
    ustatus,
    btnSubmitTxt,
    api,
    uid,
    name,
    setCreatePost,
  } = props;
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleshow = () => setShow(true);
  const width = useRef(window.innerWidth);

  const { getStatesData, userProfileData, getEnabledProductsData } = useSelector(
    (state) => state.commonReducer
  );

  return (
    <>
      {localStorage.getItem("x-auth-token") ? (
        width.current < 470 ? (
          buttonTitle ? (
            <Button className="w-100 post-btn-read-more" onClick={handleshow}>
              <i className="bi bi-patch-plus-fill"></i> {buttonTitle}
            </Button>
          ) : (
            <i className="bi bi-pen-fill" onClick={handleshow}></i>
          )
        ) : (
          <span className="w-100" onClick={handleshow}>
            {buttonTitle ? (
              <i className="bi bi-patch-plus-fill"></i>
            ) : (
              <Button variant="success" onClick={handleshow}>
                <i className="bi bi-pen-fill"></i>
              </Button>
            )}
          </span>
        )
      ) : (
        <Link to="/login">
          {width.current < 470 ? (
            <Button className="w-100 post-btn-read-more">
              <i className="bi bi-patch-plus-fill"></i> {buttonTitle}
            </Button>
          ) : (
            <i className="bi bi-patch-plus-fill"></i>
          )}
        </Link>
      )}
      {/* // modal  */}
      <Modal
        size="lg"
        className="popup-form post-create-form"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {buttonTitle ? buttonTitle : "Update"} Post Here
          </Modal.Title>
        </Modal.Header>
        {width.current > 470 && <hr />}
        <Modal.Body>
          <Formik
            validationSchema={schema}
            onSubmit={async (values, { resetForm }) => {
              api ? await dispatch(api(values)) : await dispatch(addPost(values));
              resetForm({ values: "" });
              // setShow(false);
              // setCreatePost(false);
              await dispatch(restPosts());
              setTimeout(() => {
                dispatch(
                  getOwnPosts({
                    type: postType,
                  })
                );
              }, 1000)
            }}
            initialValues={{
              id: uid,
              description: udescription,
              type: postType,
              product: uproduct,
              qty: uqty,
              start_time: moment(ustart_time).format("YYYY-MM-DD"),
              end_time: moment(uend_time).format("YYYY-MM-DD"),
              city: ucity,
              state: ustate,
              // orders: "",
              status: buttonTitle ? true : ustatus,
              price: uprice,
              name
              // preferred_brokers: upreferred_brokers ? upreferred_brokers : [],
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              setFieldValue,
              errors,
            }) => (
              <Form className="container" onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        isInvalid={!!errors.name}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <Form.Label>Product</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("product", e.target.value);
                      }}
                      name="product"
                      className="form-control"
                      value={values.product}
                      isInvalid={!!errors.product}
                    >
                      <option value="">Select Product</option>
                      {!!userProfileData?.products &&
                        userProfileData?.products?.map((data, index) => {
                          return <option value={data?.id} key={index}>{data?.name}</option>;
                        })}
                    </Form.Control>
                  </Col>
                  {/* <Col md={4}>
                    <Form.Label>Brokers</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("preferred_brokers", e.target.value);
                      }}
                      name="preferred_brokers"
                      className="form-control"
                      value={values.preferred_brokers}
                      isInvalid={!!errors.preferred_brokers}
                    >
                      <option value="">Select Brokers</option>
                      {!!getFrontEndbrokersData &&
                        getFrontEndbrokersData.map((data, index) => {
                          return (
                            <option
                              key={index}
                              value={data?.id}
                            >{`${data?.firstName} ${data?.lastName}`}</option>
                          );
                        })}
                    </Form.Control>
                  </Col> */}
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Quantity (in MT)</Form.Label>
                      <Form.Control
                        type="number"
                        name="qty"
                        value={values.qty}
                        onChange={handleChange}
                        isInvalid={!!errors.qty}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.qty}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Price (in MT)</Form.Label>
                      <Form.Control
                        type="number"
                        name="price"
                        value={values.price}
                        onChange={handleChange}
                        isInvalid={!!errors.price}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.price}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="start_time"
                        value={values.start_time}
                        onChange={handleChange}
                        isInvalid={!!errors.start_time}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.start_time}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="end_time"
                        value={values.end_time}
                        onChange={handleChange}
                        isInvalid={!!errors.end_time}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.end_time}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={4}>
                    <Form.Label>State</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("state", e.target.value);
                        // dispatch(
                        //   getCities({
                        //     state: e.target.value,
                        //   })
                        // );
                      }}
                      name="state"
                      className="form-control"
                      value={values.state}
                      isInvalid={!!errors.state}
                    >
                      <option value="">Select State</option>
                      {!!getStatesData?.allStates &&
                        getStatesData.allStates.map((data, index) => {
                          return (
                            <option key={index} value={data}>
                              {data}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>City</Form.Label>
                      <Form.Control
                        type="text"
                        name="city"
                        value={values.city}
                        onChange={handleChange}
                        isInvalid={!!errors.city}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.city}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  {/* <Col md={4}>
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      as="select"
                      onChange={async (e) => {
                        setFieldValue("city", e.target.value);
                      }}
                      name="city"
                      className="form-control"
                      value={values.city}
                      isInvalid={!!errors.city}
                    >
                      <option value="">Select City</option>
                      {!!getCitiesData?.allCities &&
                        getCitiesData?.allCities.map((data, index) => {
                          return (
                            <option key={index} value={data?.city}>
                              {data?.city}
                            </option>
                          );
                        })}
                    </Form.Control>
                  </Col> */}
                  {!buttonTitle && (
                    <Col md={4}>
                      <Form.Label>Status</Form.Label>
                      <Form.Control
                        as="select"
                        onChange={async (e) => {
                          setFieldValue("status", e.target.value);
                        }}
                        name="status"
                        className="form-control"
                        value={values.status}
                        isInvalid={!!errors.status}
                      >
                        <option value="">Select Status</option>
                        <option value={true}>Active</option>
                        <option value={false}>InActive</option>
                      </Form.Control>
                    </Col>
                  )}
                </Row>
                {/* <Col md={4}>
                    <Form.Group>
                      <Form.Label>Orders</Form.Label>
                      <Form.Control
                        type="number"
                        name="orders"
                        value={values.orders}
                        onChange={handleChange}
                        isInvalid={!!errors.orders}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.orders}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col> */}
                <Row className="mb-3">
                  <Col md={12} sm={12}>
                    <Form.Group>
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Description"
                        name="description"
                        value={values.description}
                        onChange={handleChange}
                        isInvalid={!!errors.description}
                        as="textarea"
                        rows={3}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.description}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Modal.Footer>
                  <Button variant="success" type="submit">
                    {btnSubmitTxt ? btnSubmitTxt : "Post"}
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CreatePost;
