import React, { useEffect, useState } from "react";
import { Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import SBK from "../../images/sbka-munafa.png";
import {
  getCatWiseProducts,
  getFrontEndbrokers,
  getStates,
  sendOtp,
  userSignup,
  verifyOtp,
} from "../../reducers/commonReducer";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string(),
  phone: yup.string().min(10).max(10).required(),
  email: yup.string().email("Invalid email address").required(),
  role: yup.string().required(),
  // gst: yup
  //   .string()
  //   .matches(
  //     /^[a-zA-Z0-9]{15}$/,
  //     "GST must be exactly 15 alphanumeric characters"
  //   )
  //   .required("GST is required"),
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], 'Must match "password" field value')
    .required(),
});

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [otpVerifyStatus, setOtpVerifyStatus] = useState(false);
  useEffect(() => {
    dispatch(getStates());
    dispatch(getFrontEndbrokers());
    dispatch(getCatWiseProducts());
  }, []);

  const api = async (values) => {
    const apiResponse = await dispatch(userSignup(values));
    if (apiResponse?.payload?.status === 1) {
      return setTimeout(() => {
        navigate("/login");
      }, 1000);
    }
    return null;
  };

  const otpVerify = async (phone, otp) => {
    const apiResponse = await dispatch(verifyOtp({
      phone, otp
    }));
    if (apiResponse?.payload?.status === 1) {
       setOtpVerifyStatus(true)
    }
    return null;
  };

  return (
    <>
      <div className="content sign-up">
        <div className="row sign-up">
          <div className="col-md-5  login-form p-4">
            <div className="text-center mt-2">
              <img src={SBK} alt="logo" />
            </div>
            <div className="text-center">
              <p>
                <b> Sign Up </b>
              </p>
            </div>

            <Formik
              validationSchema={schema}
              onSubmit={(values) => {
                dispatch(api(values));
              }}
              initialValues={{
                firstName: "",
                lastName: "",
                password: "",
                phone: "",
                email: "",
                role: "trader",
                gst: "",
                confirmPassword: "",
              }}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                touched,
                setFieldValue,
                errors,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="col-12 mt-4">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-person-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="firstName"
                          value={values.firstName}
                          onChange={handleChange}
                          isInvalid={!!errors.firstName}
                          className="form-control"
                          placeholder="FirstName"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-12 mt-2 mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-person-check-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="lastName"
                          value={values.lastName}
                          onChange={handleChange}
                          isInvalid={!!errors.lastName}
                          className="form-control"
                          placeholder="LastName"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.lastName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="col-12 mt-2 mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-envelope-fill"></i>
                        </div>
                        <Form.Control
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                          className="form-control"
                          placeholder="Email"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  {/* <div className="col-12  mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-piggy-bank-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="gst"
                          value={values.gst}
                          onChange={handleChange}
                          isInvalid={!!errors.gst}
                          className="form-control"
                          placeholder="GST (Optional)"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.gst}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div> */}
                  <div className="col-12 mt-2 mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-phone-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="phone"
                          value={values.phone}
                          onChange={async (e) => {
                            setFieldValue("phone", e.target.value);
                            e.target.value?.length === 10 && dispatch(sendOtp({
                              "phone": e.target.value
                            }))
                          }}
                          isInvalid={!!errors.phone}
                          className="form-control"
                          placeholder="Phone"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.phone}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12 mt-2 mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-phone-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          onChange={(e)=> {
                            e.target.value?.length === 4 && otpVerify(values.phone, e.target.value)
                          }}
                          className="form-control"
                          placeholder="Enter OTP"
                        />
                      </div>
                    </Form.Group>
                  </div>
                  {/* <div className="col-12 mt-2 mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-person-circle"></i>
                        </div>
                        <Form.Control
                          as="select"
                          onChange={async (e) => {
                            setFieldValue("role", e.target.value);
                          }}
                          name="role"
                          className="form-control"
                          value={values.role}
                          isInvalid={!!errors.role}
                        >
                          <option value="">Select Role</option>
                          <option value={"trader"}>Trader</option>
                          <option value={"broker"}>Broker</option>
                        </Form.Control>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.role}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div> */}
                  {otpVerifyStatus && <><div className="col-12  mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-lock-fill"></i>
                        </div>
                        <Form.Control
                          type="password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={!!errors.password}
                          className="form-control"
                          placeholder="Password"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12  mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-lock-fill"></i>
                        </div>
                        <Form.Control
                          type="password"
                          name="confirmPassword"
                          value={values.confirmPassword}
                          onChange={handleChange}
                          isInvalid={!!errors.confirmPassword}
                          className={
                            errors.confirmPassword &&
                            touched.confirmPassword &&
                            "errors"
                          }
                          placeholder="Confirm Password"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmPassword}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12 text-center">
                    <Button className="btn-color w-100" type="submit">
                      Sign Up
                    </Button>
                  </div>
                  </>}
                </Form>
              )}
            </Formik>
          </div>
          {/* <div className="col-12 text-center">
            <p>
              <Link to={"/"}>
                <b>
                  <i className="bi bi-arrow-left"></i> Back to Home
                </b>
              </Link>
            </p>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default SignUp;
