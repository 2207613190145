import React, { useRef, useState } from "react";
import { Row, Col } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import moment from "moment";
import { barginList } from "../../reducers/commonReducer";
import { Formik } from "formik";
import * as yup from "yup";

const schema = yup.object().shape({
  seller: yup.string().required(),
  broker: yup.string().required(),
  // remarks: yup.string(),
  buyer: yup.string().required(),
  item: yup.string().required(),
  qty: yup.string().required(),
  rate: yup.string().required(),
  date: yup.string().required(),
});
const ActionBargin = (props) => {
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleshow = () => setShow(true);
  const {
    buttonTitle,
    title,
    submitTitle,
    useller,
    ubroker,
    uremarks,
    ubuyer,
    uitem,
    uqty,
    urate,
    uunit,
    uid,
    udate,
    api,
  } = props;
  return (
    <>
      {buttonTitle ? (
        <Button className="button-color w-100 my-2" onClick={handleshow}>
          {buttonTitle}
        </Button>
      ) : (
        <i onClick={handleshow} className="bi bi-pencil p-1 cursor-pointer"></i>
      )}
      {/* // modal  */}
      <Modal size="lg" className="popup-form" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        {width.current > 470 && <hr />}
        <Modal.Body>
          <Formik
            validationSchema={schema}
            onSubmit={async (values, { resetForm }) => {
              await dispatch(api(values));
              resetForm({ values: "" });
              setShow(false);
              await dispatch(barginList());
            }}
            initialValues={{
              id: uid,
              seller: useller,
              broker: ubroker,
              remarks: uremarks,
              buyer: ubuyer,
              item: uitem,
              qty: uqty,
              rate: urate,
              unit: uunit,
              date: udate ? moment(udate).format("YYYY-MM-DD") : "",
            }}
          >
            {({ handleSubmit, handleChange, values, errors }) => (
              <Form className="container" onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Date</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Enter Date"
                        name="date"
                        value={values.date}
                        onChange={handleChange}
                        isInvalid={!!errors.date}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.date}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12}>
                    <Form.Group>
                      <Form.Label>Seller</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Seller"
                        name="seller"
                        value={values.seller}
                        onChange={handleChange}
                        isInvalid={!!errors.seller}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.seller}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12} sm={12}>
                    <Form.Group>
                      <Form.Label>Buyer</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Buyer"
                        name="buyer"
                        value={values.buyer}
                        onChange={handleChange}
                        isInvalid={!!errors.buyer}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.buyer}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12} sm={12}>
                    <Form.Group>
                      <Form.Label>Broker</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Broker"
                        name="broker"
                        value={values.broker}
                        onChange={handleChange}
                        isInvalid={!!errors.broker}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.broker}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12} sm={12}>
                    <Form.Group>
                      <Form.Label>Item</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Item"
                        name="item"
                        value={values.item}
                        onChange={handleChange}
                        isInvalid={!!errors.item}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.item}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12} sm={12}>
                    <Form.Group>
                      <Form.Label>Qty(In MT)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Qty(In MT)"
                        name="qty"
                        value={values.qty}
                        onChange={handleChange}
                        isInvalid={!!errors.qty}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.qty}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12} sm={12}>
                    <Form.Group>
                      <Form.Label>Rate (In MT)</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Rate (In MT)"
                        name="rate"
                        value={values.rate}
                        onChange={handleChange}
                        isInvalid={!!errors.rate}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.rate}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col md={12} sm={12}>
                    <Form.Group>
                      <Form.Label>Remarks</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Remarks"
                        name="remarks"
                        value={values.remarks}
                        onChange={handleChange}
                        isInvalid={!!errors.remarks}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.remarks}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                {/* <Row className="mb-3">
                  <Col md={12} sm={12}>
                    <Form.Group>
                      <Form.Label>Unit</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Unit"
                        name="unit"
                        value={values.unit}
                        onChange={handleChange}
                        isInvalid={!!errors.unit}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.unit}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row> */}
                <Modal.Footer>
                  <Button variant="success" type="submit">
                    {submitTitle}
                  </Button>
                </Modal.Footer>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ActionBargin;
