import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import {getContacts, restRecord } from "../../reducers/commonReducer";
import moment from "moment";

const ContactList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
        navigate("/");
      }
      dispatch(restRecord())
    dispatch(getContacts());
  }, []);
  const { getContactsData, getContactsBlank } = useSelector((state) => state.commonReducer);
  const [page, setPage] = useState(1);

  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table"> 
        <Col md={12}>
        <h3>Contact List</h3>
          </Col> 
          <hr/>
        </Row>
          
          <div className="overflow-auto">
        <Table striped bordered hover size="sm" className="overflow-scroll">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Name</th>
              <th>Date</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {!!getContactsData ?
              getContactsData.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>
                      {data?.name}
                    </td>
                    <td>{moment(data?.createdAt).format("DD-MM-YYYY")}</td>
                    <td>{data?.phone}</td>
                    <td>{data?.email}</td>
                    <td>{data?.comment}</td>
                  </tr>
                );
              }) : 'No record found'} 
          </tbody>
        </Table> 
        {!!getContactsBlank && getContactsBlank.length > 0 ? (
                <Row>
                  <Button
                  className="w-100"
                    onClick={() => {
                      setPage(page + 1);
                      dispatch(getContacts({page : page + 1}));
                    }}>
                    Load More
                  </Button>
                </Row>
              ) : (
                <p className="text-center">
                  <b>No record found 😔.</b>
                </p>
              )}
        </div>
      </div>
    </>
  );
};

export default ContactList;
