import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import {
  alltranspoters,
  transporterVerification,
} from "../../reducers/commonReducer";
import red from "../../images/red.png";
import green from "../../images/green.png";
import { Button } from "react-bootstrap";

const AllTransporters = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const { alltranspotersList } = useSelector((state) => state.commonReducer);
  useEffect(() => {
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
    let timeOut = setTimeout(() => {
      dispatch(
        alltranspoters({
          search: search,
        })
      );
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search]);
  return (
    <>
      <Header />
      <div className="container">
        <div className="mt-4 row">
          <div className="col-md-6 col-6 p-0">
            <h3>Transporters</h3>
          </div>
          <Row>
            <div className="col-12  mb-2 p-0">
              <Form.Group as={Col} md="12">
                <div className="input-group">
                  <div className="input-group-text">
                    <i className="bi bi-search"></i>
                  </div>
                  <Form.Control
                    type="text"
                    name="search"
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    className="form-control"
                    placeholder="Write Anything..."
                    required
                  />
                </div>
              </Form.Group>
            </div>
          </Row>
          <div className="overflow-auto border mt-3">
            <Table striped hover size="sm" className="overflow-scroll">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Name</th>
                  <th>FirmName</th>
                  <th>Phone</th>
                  <th>Email</th>
                  <th>Address</th>
                  <th>City</th>
                  <th>State</th>
                  <th>GST</th>
                  <th>Status</th>
                  <th>Verify</th>
                </tr>
              </thead>
              <tbody>
                {!!alltranspotersList &&
                  alltranspotersList.map((data, index) => {
                    return (
                      <tr key={data.id}>
                        <td>{index + 1}.</td>
                        <td>
                          {data?.firstName} {data?.lastName}
                        </td>
                        <td>{data?.firmName}</td>
                        <td>{data?.phone}</td>
                        <td>{data?.email}</td>
                        <td>{data?.address}</td>
                        <td>
                          {data?.city} ({data?.zipcode})
                        </td>
                        <td>{data?.state}</td>
                        <td>{data?.gst}</td>
                        <td className="table-img">
                          {data?.transporter === true ? (
                            <img src={green} />
                          ) : (
                            <img src={red} />
                          )}
                        </td>
                        <td>
                          {data?.transporter === false && (
                            <Button
                              variant="success"
                              onClick={async () => {
                                dispatch(
                                  transporterVerification({
                                    id: data?.id,
                                  })
                                );
                                setTimeout(() => {
                                  dispatch(alltranspoters());
                                }, 800);
                              }}
                            >
                              Verify
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      {/* <Add showhide={show} handleClose={handleClose} /> */}
    </>
  );
};

export default AllTransporters;
