import React, { Fragment, useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import AppHeader from "../../Components/AppHeader";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Button } from "react-bootstrap";
import { verifiedtranspoters } from "../../reducers/commonReducer";
import { Link } from "react-router-dom";

const TransporterList = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const { verifiedtranspotersList } = useSelector(
    (state) => state.commonReducer
  );
  useEffect(() => {
    let timeOut = setTimeout(() => {
      dispatch(
        verifiedtranspoters({
          search: search,
        })
      );
    }, 800);
    return () => clearTimeout(timeOut);
  }, [search]);
  return (
    <>
      <AppHeader />
      <Container className="mt-3 main-contain-withoutfoot">
        <Row>
          <Col md={9} xs={9} className="mb-2">
            <Form.Group as={Col} md="12">
              <div className="input-group">
                <div className="input-group-text">
                  <i className="bi bi-search"></i>
                </div>
                <Form.Control
                  type="text"
                  name="search"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                  className="form-control"
                  placeholder="Write Anything..."
                  required
                />
              </div>
            </Form.Group>
          </Col>
          <Col md={3} xs={3}>
            <Link to="/transpoter-signup"><Button variant="success">SignUp</Button></Link>
          </Col>
        </Row>
        {!!verifiedtranspotersList && verifiedtranspotersList.length > 0 ? (
          <Row>
            {verifiedtranspotersList?.map((data, index) => {
              return (
                <Fragment key={index}>
                  <Col md={3}>
                    <div>
                      <p className="mb-0">
                        Name: <b>{`${data?.firstName} ${data?.lastName}`}</b>
                      </p>
                      <p className="mb-0">
                        FirmName: <b>{data?.firmName}</b>
                      </p>
                      <p className="mb-0">
                        Phone: <b>{data?.phone}</b>
                      </p>
                      <p className="mb-0">
                        Email: <b>{data?.email}</b>
                      </p>
                      <p className="mb-0">
                        GST Number: <b>{data?.gst}</b>
                      </p>
                      <p className="mb-0">
                        Address:{" "}
                        <b>{`${data?.address}, ${data?.city} - ${data?.zipcode}, ${data?.state}`}</b>
                      </p>
                    </div>
                  </Col>
                  <hr />
                </Fragment>
              );
            })}
          </Row>
        ) : (
          <h5 className="text-center text-danger">Record Not Found.</h5>
        )}
      </Container>
    </>
  );
};

export default TransporterList;
