import React, { useEffect, useRef } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import moment from "moment";
import AppHeader from "../../Components/AppHeader";
import { useDispatch, useSelector } from "react-redux";
import {
  deletePost,
  getEnabledProducts,
  getFrontEndbrokers,
  getOwnPosts,
  getStates,
  updatePost,
} from "../../reducers/commonReducer";
import { useNavigate } from "react-router-dom";
import GeneralPost from "../HomePage/GeneralPost";
import CreatePost from "../HomePage/CreatePost";

const MyPosts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);
  useEffect(() => {
    localStorage.removeItem("addOrder");
    dispatch(getOwnPosts({ type: "all" }));
    dispatch(getStates()); 
    dispatch(getEnabledProducts());
    dispatch(getFrontEndbrokers());
  }, []);
  const { getOwnPostsList, selectedbtn,loading } = useSelector(
    (state) => state.commonReducer
  );
  return (
    <>
      <AppHeader />
      {(loading === undefined || loading) ?  <h3 className="text-info text-center mt-5">
       Loading.....
     </h3> : 
      <div className="content mt-3">
        <Container>
          <Row>
            <b onClick={() => navigate(-1)}>
              <h3>
                <i className="bi bi-caret-left"></i> My Posts
              </h3>
            </b>
          </Row>
          <Row className="d-flex mt-3 mb-3">
            <Col
              sm={3}
              xs={3}
              onClick={() => {
                dispatch(getOwnPosts({ type: "all" }));
              }}
            >
              <span
                className={`mbl-btn-sml btn button-color rounded w-100 ${
                  selectedbtn == "all" && "selectedColor"
                } ${width?.current > 668 && `order-button`}`}
              >
                All
              </span>
            </Col>
            <Col
              sm={3}
              xs={3}
              onClick={() => {
                dispatch(
                  getOwnPosts({
                    type: "general",
                  })
                );
              }}
            >
              <span
                className={`mbl-btn-sml btn button-color rounded w-100 ${
                  selectedbtn == "general" && "selectedColor"
                } ${width?.current > 668 && `order-button`}`}
              >
                General
              </span>
            </Col>
            <Col
              sm={3}
              xs={3}
              onClick={() => {
                dispatch(
                  getOwnPosts({
                    type: "sell",
                  })
                );
              }}
            >
              <span
                className={`mbl-btn-sml btn button-color rounded w-100 ${
                  selectedbtn == "sell" && "selectedColor"
                } ${width?.current > 668 && `order-button`}`}
              >
                Sell
              </span>
            </Col>
            <Col
              sm={3}
              xs={3}
              onClick={() => {
                dispatch(
                  getOwnPosts({
                    type: "buy",
                  })
                );
              }}
            >
              <span
                className={`mbl-btn-sml btn button-color rounded w-100 ${
                  selectedbtn == "buy" && "selectedColor"
                } ${width?.current > 668 && `order-button`}`}
              >
                Buy
              </span>
            </Col>
          </Row>
          <Row>
            {!!getOwnPostsList && getOwnPostsList.length > 0 ? (
              getOwnPostsList.map((data, index) => {
                return (
                  <Col md={4} sm={12} xs={12} className="mt-1" key={index}>
                    <div className={`border p-2 homecard border rounded`}>
                      <Row>
                        <Col md={8} sm={8} xs={8}>
                        <p className="mb-0"><b>{moment(data?.createdAt).format("LL")}</b></p>
                          <p className="mb-0">
                            <b>
                              {data?.title.length > 0
                                ? data?.title
                                : `${
                                    !!data?.product?.name && data.product.name
                                  } ${moment(data?.start_time).format(
                                    "MMM Do"
                                  )} to ${moment(data?.end_time).format("LL")}`}
                            </b>
                          </p>
                        </Col>
                        <Col md={4} sm={4} xs={4}>
                          <span className="d-flex">
                            {data?.type === "general" ? (
                              <GeneralPost
                                updategen={"1"}
                                utitle={data?.title}
                                udescription={data?.description}
                                api={updatePost}
                                uid={data?.id}
                              />
                            ) : (
                              <CreatePost
                                postType={data?.type}
                                ucity={data?.city}
                                udescription={data?.description}
                                uend_time={data?.end_time}
                                upreferred_brokers={data?.preferred_brokers}
                                uproduct={data?.product?.id}
                                uqty={data?.qty}
                                ustart_time={data?.start_time}
                                ustate={data?.state}
                                ustatus={data?.status}
                                btnSubmitTxt={"Update"}
                                api={updatePost}
                                uid={data?.id}
                                uprice={data?.price}
                                name={data?.name}
                              />
                            )}
                            <Button onClick={async() => {
                              await dispatch(deletePost({
                                id:data?.id
                              }))
                              setTimeout(() => {
                                dispatch(getOwnPosts({ type: "all" }))
                              }, 500)
                            }} className="mx-1" variant="danger"><i className="bi bi-trash3-fill"></i></Button>
                          </span>
                        </Col>
                      </Row>

                      {data?.type !== "general" && (
                      <>  
                      <p className="mb-0">
                        Qty (in MT): <b>{data?.qty}</b>
                      </p>
                      <p className="mb-0">
                        Price (in MT): <b>{data?.price}</b>
                      </p>
                      </>
                      )} 
                      <p className="mb-0">
                        Post Type: <b>{data?.type}</b>
                      </p>
                      
                      <p className="d-flex mb-0">
                        <span className="p-2">
                          <i className="bi bi-hand-thumbs-up-fill"></i>
                          {data?.like.length}
                        </span>
                        <span className="p-2">
                          {" "}
                          <i className="bi bi-hand-thumbs-down-fill"></i>
                          {data?.unlike.length}
                        </span>
                      </p>
                    </div>
                  </Col>
                );
              })
            ) : (
              <h3 className="text-center text-danger">No Post Yet...</h3>
            )}
          </Row>
        </Container>
      </div>}
    </>
  );
};

export default MyPosts;
