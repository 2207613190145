import React, { useEffect, useRef, useState } from "react";
import {Fragment} from 'react';
import { Container, Row, Col, Button } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import Tabs from "react-bootstrap/Tabs";
import profile from "../../images/profile.png";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUser,
  deleteUserProduct,
  getCatWiseProducts,
  getEnabledProducts,
  getFrontEndbrokers,
  getStates,
  updateUserProfile,
  userProfile,
} from "../../reducers/commonReducer";
import Select from "react-select";
import AppHeader from "../../Components/AppHeader";
import { FieldArray, Formik } from "formik";
import * as yup from "yup";
import { ProfileProgress } from "./ProfileProgress";
import { Link, useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string(),
  password: yup.string(),
  email: yup.string().required(),
  firmName: yup.string(),
  address: yup.string(),
  city: yup.string(),
  state: yup.string(),
  gst: yup.string(),
  products: yup.array(),
  gstimage: yup.mixed(),
  image: yup.mixed(),
});

const Profile = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const width = useRef(window.innerWidth);

  useEffect(() => {
    dispatch(getStates());
    dispatch(getEnabledProducts());
    dispatch(getFrontEndbrokers());
    dispatch(getCatWiseProducts());
  }, []);
  const {
    getStatesData,
    getCatWiseProductsData,
    userProfileData,
    userSelectProduct,
  } = useSelector((state) => state.commonReducer);
  const {
    address,
    city,
    email,
    firmName,
    firstName,
    lastName,
    gst,
    state,
    image,
    subscription,
  } = !!userProfileData && userProfileData;
  const [profileImg, setprofileImg] = useState();

  let fltrProducts = {};
  //product filter
  const filteredProducts =
    !!getCatWiseProductsData?.allProductsCat &&
    Object.entries(getCatWiseProductsData?.allProductsCat).map(
      ([prokey, products]) => {
        products.filter((singleProduct) => {
          if (
            !!userSelectProduct &&
            userSelectProduct.includes(singleProduct.id)
          ) {
            return false;
          } else {
            if (!(prokey in fltrProducts)) {
              fltrProducts[prokey] = [];
            }
            fltrProducts[prokey].push(singleProduct);
            return true;
          }
        });
      }
    );

  const updatedata = async (values) => {
    const apiResponse = await dispatch(updateUserProfile(values));
    if (
      apiResponse?.payload?.status === 1 &&
      localStorage.getItem("x-auth-token")
    ) {
      return setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
    return null;
  };

  const copyShareUrl = () => {
    const urlToCopy = `https://${window.location.hostname}/refferal?userid=${userProfileData?.id}`
    // Create a temporary input element to copy the URL
    const tempInput = document.createElement('input');
    document.body.appendChild(tempInput);
    tempInput.value = urlToCopy;
    tempInput.select();
    try {
      // Execute the copy command
      document.execCommand('copy');
      alert('URL copied to clipboard: ' + urlToCopy);
    } catch (err) {
      console.error('Failed to copy URL: ', err);
    } finally {
      // Clean up by removing the temporary input element
      document.body.removeChild(tempInput);
    }
  };
  const deleteMyAccount = async () => {
    const apiResponse = await dispatch(deleteUser());
    if (apiResponse?.payload?.status === 1) {
      return setTimeout(() => {
        navigate("/");
        localStorage.clear();
      }, 1000);
    }
    return null;
  };
  return (
    <>
      <AppHeader />
      <div className={`content ${width.current < 470 ? `mt-0` : `mb-3`}`}>
        <Container>
          <Row className={`${width.current < 470 ? `mb-0` : `mb-5`}`}>
            <Col xs={12} className="text-center profile-main-img">
              <label for="file-input">
                {image ? (
                  <img
                    className={width.current > 470 && `user-profile-img`}
                    src={`/${image}`}
                  />
                ) : (
                  <img
                    className={width.current > 470 && `user-profile-img`}
                    src={profile}
                  />
                )}
              </label>
              <input
                id="file-input"
                type="file"
                hidden
                onChange={(event) => {
                  setprofileImg(URL.createObjectURL(event.target.files[0]));
                  dispatch(
                    updatedata({
                      image: event.target.files[0],
                    })
                  );
                }}
              />
            </Col>
              <Col xs={12} md={12}>
                <h5 className="text-danger text-center">
                  For profile update required Subsctiprion.{" "}
                  <Link to="/subscription">Click Here</Link>
                </h5>
              </Col>
            <Col xs={12} className="mb-1">
              <ProfileProgress />
            </Col>
          </Row>
          {
          <Row>
            <Col className="text-center mb-2">
            <b>{`https://${window.location.hostname}/refferal?userid=${userProfileData?.id}`}</b> <i onClick={copyShareUrl} className="bi bi-clipboard"></i>
            </Col>
          </Row>
          }
          <Formik
            validationSchema={schema}
            onSubmit={(values, { resetForm }) => {
              dispatch(updatedata(values));
              resetForm();
            }}
            enableReinitialize={true}
            initialValues={{
              firstName: firstName,
              lastName: lastName,
              password: "",
              email: email,
              firmName: firmName ? firmName : "",
              address: address,
              city: city,
              state: state ? state : "",
              gst: gst,
              gstimage: "",
              // brokers: [],
              products: [],
              EDIBLE: [],
              NONEDIBLE: [],
              CHEMICAL: [],
              GRAINS:[],
              OILSEEDS:[],
              image: "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              errors,
              setFieldValue,
            }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Tabs
                  defaultActiveKey="profile"
                  id="fill-tab-example"
                  className={`${width.current < 470 ? `mb-0` : `mb-3`}`}
                  fill
                >
                  <Tab eventKey="profile" title="Profile">
                    <div
                      className={`col-12 ${
                        width.current < 470 ? `mt-1` : `mt-4`
                      }`}
                    >
                      <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="bi bi-person-fill"></i>
                          </div>
                          <Form.Control
                            type="text"
                            name="firstName"
                            value={values.firstName}
                            onChange={handleChange}
                            isInvalid={!!errors.firstName}
                            className="form-control"
                            placeholder="FirstName"
                            required
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.firstName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div
                      className={`col-12 ${
                        width.current < 470 ? `mt-1` : `mt-4`
                      }`}
                    >
                      <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="bi bi-person-check-fill"></i>
                          </div>
                          <Form.Control
                            type="text"
                            name="lastName"
                            value={values.lastName}
                            onChange={handleChange}
                            isInvalid={!!errors.lastName}
                            className="form-control"
                            placeholder="LastName"
                            required
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.lastName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div
                      className={`col-12 ${
                        width.current < 470 ? `mt-1` : `mt-4`
                      }`}
                    >
                      <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="bi bi-envelope-fill"></i>
                          </div>
                          <Form.Control
                            type="email"
                            name="email"
                            value={values.email}
                            onChange={handleChange}
                            isInvalid={!!errors.email}
                            className="form-control"
                            placeholder="Email"
                            required
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div
                      className={`col-12 ${
                        width.current < 470 ? `mt-1` : `mt-4`
                      }`}
                    >
                      <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="bi bi-building"></i>
                          </div>
                          <Form.Control
                            type="text"
                            name="firmName"
                            value={values.firmName}
                            onChange={handleChange}
                            isInvalid={!!errors.firmName}
                            className="form-control"
                            placeholder="FirmName"
                            required
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.firmName}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div
                      className={`col-12 ${
                        width.current < 470 ? `mt-1` : `mt-4`
                      }`}
                    >
                      <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="bi bi-cash-coin"></i>
                          </div>
                          <Form.Control
                            type="text"
                            name="gst"
                            value={values.gst}
                            onChange={handleChange}
                            isInvalid={!!errors.gst}
                            className="form-control"
                            placeholder="GST"
                            required
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.gst}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div
                      className={`col-12 ${
                        width.current < 470 ? `mt-1 mb-1` : `mt-4 mb-4`
                      }`}
                    >
                      <Form.Group as={Col} md="12">
                        <div className="input-group custome-input-text">
                          <div className="input-group-text">
                            <i className="bi bi-cash-coin"></i>
                          </div>
                          <label className="input-group-text" for="inputGroupFile">
                            Choose GST
                          </label>
                          <Form.Control
                            id="myfile"
                            type="file"
                            name="gstimage"
                            onChange={(event) => {
                              const gstfile = event.target.files[0];
                              setFieldValue("gstimage", gstfile);
                            }}
                            isInvalid={!!errors.gstimage}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.gstimage}
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </div>

                    <div
                      className={`col-12 ${
                        width.current < 470 ? `mt-1` : `mt-4`
                      }`}
                    >
                      <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="bi bi-pin-map-fill"></i>
                          </div>
                          <Form.Control
                            type="text"
                            name="address"
                            value={values.address}
                            onChange={handleChange}
                            isInvalid={!!errors.address}
                            className="form-control"
                            placeholder="Address"
                            required
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.address}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div
                      className={`col-12 ${
                        width.current < 470 ? `mt-1` : `mt-4`
                      }`}
                    >
                      <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="bi bi-pin-map-fill"></i>
                          </div>
                          <Form.Control
                            as="select"
                            onChange={async (e) => {
                              setFieldValue("state", e.target.value);
                              // dispatch(
                              //   getCities({
                              //     state: e.target.value,
                              //   })
                              // );
                            }}
                            name="state"
                            className="form-control"
                            value={values.state}
                            isInvalid={!!errors.state}
                          >
                            <option value="">Select State</option>
                            {!!getStatesData?.allStates &&
                              getStatesData?.allStates.map((data, index) => {
                                return (
                                  <option key={index} value={data}>
                                    {data}
                                  </option>
                                );
                              })}
                          </Form.Control>
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.state}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                    <div
                      className={`col-12 ${
                        width.current < 470 ? `mt-1 mb-1` : `mt-4 mb-4`
                      }`}
                    >
                      <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="bi bi-pin-map-fill"></i>
                          </div>
                          <Form.Control
                            type="text"
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                            isInvalid={!!errors.city}
                            className="form-control"
                            placeholder="City"
                            required
                          />
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.city}
                        </Form.Control.Feedback>
                      </Form.Group>
                      {/* <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="bi bi-pin-map-fill"></i>
                          </div>
                          <Form.Control
                            as="select"
                            onChange={async (e) => {
                              setFieldValue("city", e.target.value);
                            }}
                            name="city"
                            className="form-control"
                            value={values.city}
                            isInvalid={!!errors.city}
                          >
                            <option value={""}>Select City</option>
                            {!!getCitiesData?.allCities &&
                              getCitiesData?.allCities.map((data, index) => {
                                return (
                                  <option key={index} value={data?.city}>
                                    {data?.city}
                                  </option>
                                );
                              })}
                          </Form.Control>
                        </div>
                        <Form.Control.Feedback type="invalid">
                          {errors.city}
                        </Form.Control.Feedback>
                      </Form.Group> */}
                    </div>
                    <div
                      className={`col-12 ${
                        width.current < 470 ? `mt-1 mb-1` : `mt-4 mb-4`
                      }`}
                    >
                      <Form.Group as={Col} md="12">
                        <div className="input-group custome-input-text">
                          <div className="input-group-text">
                            <i className="bi bi-card-image"></i>
                          </div>
                          <label className="input-group-text" for="inputGroupFile">
                            Profile Pic
                          </label>
                          <Form.Control
                            id="myfile"
                            type="file"
                            name="image"
                            onChange={(event) => {
                              const resumeFile = event.target.files[0];
                              setFieldValue("image", resumeFile);
                            }}
                            isInvalid={!!errors.image}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.image}
                          </Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </div>
                  </Tab>
                  <Tab eventKey="longer-tab" title="Products">
                    {<p><b>Note</b>:You are active Free plan so you can select max 5 products need to more then 5 product please activate Premium plan <Link to="/subscription"><Button variant="success" className="p-0 blink-text">Click Here</Button></Link></p>}
                    <p className="text-warning"><b>Note</b>:Need new product in this product list so please request to create new product <Link to="/request-new-product"><Button variant="success" className="p-0 blink-text">Click Here</Button></Link></p>
                    <p className="mb-0">
                      {!!userProfileData?.products &&
                        userProfileData?.products.map((data, index) => {
                          return (
                            <Button variant="success m-1 p-0" key={index}>
                              {data?.name} {subscription?.plan === "Pro" && <span><i onClick={async() => {
                                await dispatch(deleteUserProduct({
                                  product:data?.id
                                }))
                                await dispatch(userProfile())
                              }} className="bi bi-trash3-fill text-danger"></i></span>}
                            </Button>
                          );
                        })}
                    </p>
                    {(!!getCatWiseProductsData?.allCategories && userProfileData?.subscription?.status !== 3)&&
                      Object.entries(getCatWiseProductsData?.allCategories).map(
                        ([catkey, value], index) => {
                          return (
                            <Fragment key={index}>
                              <div
                                className={`col-12 ${
                                  width.current < 470 ? `mb-1` : `mt-4 mb-5`
                                }`}
                              >
                                <label>
                                  Select <b>{value}</b> Products
                                </label>
                                <Form.Group as={Col} md="12">
                                  <div className="input-group">
                                    <div className="input-group-text">
                                      <i className="bi bi-box2-heart-fill"></i>
                                    </div>
                                    {
                                      width.current < 500 ?
                                    <Form.Control
                                      as="select"
                                      multiple={true}
                                      name={
                                        (value === "EDIBLE" && "EDIBLE") ||
                                        (value === "NON EDIBLE" &&
                                          "NONEDIBLE") ||
                                        (value === "GRAINS" &&
                                          "GRAINS") ||
                                        (value === "OIL SEEDS" &&
                                          "OILSEEDS") ||
                                        (value === "CHEMICAL" && "CHEMICAL")
                                      }
                                      onChange={handleChange}
                                      className="form-control"
                                    >
                                      {!!fltrProducts[catkey] &&
                                        fltrProducts[catkey].map(
                                          (value, index) => (
                                            <option
                                              value={value?.id}
                                              key={index}
                                            >
                                              {value?.name}
                                            </option>
                                          )
                                        )}
                                    </Form.Control> :
                                     <FieldArray  name={
                                      (value === "EDIBLE" && "EDIBLE") ||
                                      (value === "NON EDIBLE" &&
                                        "NONEDIBLE") ||
                                      (value === "GRAINS" &&
                                        "GRAINS") ||
                                      (value === "OIL SEEDS" &&
                                        "OILSEEDS") ||
                                      (value === "CHEMICAL" && "CHEMICAL")
                                    }>
                                      {({ name }) => (<Select
                                        defaultValue={[]}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        onChange={(selectedOptions) => {
                                          const newData = !!selectedOptions && selectedOptions?.map((data) => data?.value);
                                            if (name === "EDIBLE") {
                                              values.EDIBLE = newData
                                            }else if(name === "NONEDIBLE"){
                                                values.NONEDIBLE = newData
                                            }
                                            else if(name === "GRAINS"){
                                              values.GRAINS = newData
                                            }
                                            else if(name === "OILSEEDS"){
                                            values.OILSEEDS = newData
                                            }else if(name === "CHEMICAL"){
                                              values.CHEMICAL = newData
                                            }else{
                                            }
                                        }}
                                        options={
                                          !!fltrProducts[catkey] &&
                                          fltrProducts[catkey].map((data) => {
                                            return {
                                              value: data?.id,
                                              label: data?.name,
                                            };
                                          })
                                        }
                                        // components={{
                                        //   Option: InputOption,
                                        // }}
                                        className="form-control"
                                      />)}
                                    </FieldArray> }
                                  </div>
                                </Form.Group>
                              </div>
                            </Fragment>
                          );
                        }
                      )}
                  </Tab>
                  {/* <Tab eventKey="contact" title="Brokers">
                    <div className="col-12 mt-4 mb-5">
                      <label>
                        Select <b>Brokers</b>
                      </label>
                      <p className="mb-0">
                        {!!userProfileData?.brokers &&
                          userProfileData?.brokers.map((data, index) => {
                            return (
                              <b>{`${data?.firstName} ${data?.lastName}, `}</b>
                            );
                          })}
                      </p>
                      <Form.Group as={Col} md="12">
                        <div className="input-group">
                          <div className="input-group-text">
                            <i className="bi bi-person-circle"></i>
                          </div>
                          <Form.Control
                            as="select"
                            multiple={true}
                            onChange={handleChange}
                            name="brokers"
                            className="form-control"
                            value={values.brokers}
                            isInvalid={!!errors.brokers}
                          >
                            {!!filteredBrokers &&
                              filteredBrokers.map((value, index) => {
                                return (
                                  <option value={value?.id} key={index}>
                                    {value?.firstName}
                                  </option>
                                );
                              })}
                          </Form.Control>
                        </div>
                      </Form.Group>
                    </div>
                  </Tab> */}
                </Tabs>
                <div className="col-12 text-center">
                  <Button
                    className={`btn-color w-100 ${
                      width.current < 470 && `p-1`
                    }`}
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          {<Button className="mt-3 w-100" variant="danger" onClick={deleteMyAccount}>
            Delete My Account
          </Button>
          }
        </Container>
      </div>
    </>
  );
};

export default Profile;
