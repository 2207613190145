import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { newRequestProductDelete, newRequestProducts } from "../../reducers/commonReducer";
import Add from "./Add";
import moment from "moment";

const RequestProducts = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(newRequestProducts());
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
  }, []);
  const { newRequestProductsList } = useSelector((state) => state.commonReducer);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table"> 
        <Col md={12}>
        <h3>New Request Product</h3>
          </Col>  
          <hr/>
        </Row>
          <div className="overflow-auto">
        <Table striped bordered hover size="sm" className="overflow-scroll">
          <thead>
            <tr>
              <th>Sr</th>
              <th>Name</th>
              <th>Date</th>
              <th>Phone</th>
              <th>Email</th>
              <th>Product Name</th>
              <th>Category</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {!!newRequestProductsList &&
              newRequestProductsList?.map((data, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td>{data?.name}</td>
                    <td>{moment(data?.createdAt).format("LLL")}</td>
                    <td>{data?.phone}</td>
                    <td>{data?.email}</td>
                    <td>{data?.product}</td>
                    <td>{data?.category}</td>
                    <td><Button variant="dark" onClick={async() => {
                         const modalStatus = window.confirm("Are you sure for delete Query.");
                         if(modalStatus){
                           await dispatch(newRequestProductDelete({
                            id: data?.id
                            }))
                            await dispatch(newRequestProducts())
                         }
                    }}><i className="bi bi-trash3-fill"></i></Button></td>
                  </tr>
                );
              })}
          </tbody>
        </Table> 

        </div>
      </div>

      <Add showhide={show} handleClose={handleClose} />
    </>
  );
};

export default RequestProducts;
