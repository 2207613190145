export const marketlist = [
  {
    title: "Investing related",
    url: "https://in.investing.com/",
  },
  {
    title: "Investing related 2",
    url: "https://in.investing.com/",
  },
  {
    title: "Investing related 3",
    url: "https://in.investing.com/",
  },
];

export function getMonthName(monthNumber) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  if (typeof monthNumber !== "number" || monthNumber < 1 || monthNumber > 12) {
    throw new Error(
      "Invalid month number. Month number should be between 1 and 12."
    );
  }
  return months[monthNumber - 1];
}
