import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Formik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { addProduct, getProducts } from "../../reducers/commonReducer";

//{"name": "muster oil", "description": "description for mustered oil","status":true, "category": "64004aa4a33f5833df8ae0ec"}
const schema = yup.object().shape({
  name: yup.string().required(),
  status: yup.string().required(),
  category: yup.string().required(),
});

function Add(props) {
  const dispatch = useDispatch();

  const { getEnabledCategoriesData } = useSelector(
    (state) => state.commonReducer
  );

  return (
    <>
      <Modal show={props.showhide} onHide={props.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Formik
          validationSchema={schema}
          onSubmit={async (values, { resetForm }) => {
            dispatch(addProduct(values));
            resetForm({ values: "" });
            dispatch(getProducts());
          }}
          initialValues={{
            name: "",
            description: "",
            category: "",
            status: true,
          }}
        >
          {({ handleSubmit, handleChange, values, setFieldValue, errors }) => (
            <Form className="container" onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Name"
                      name="name"
                      value={values.name}
                      onChange={handleChange}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12} sm={12}>
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      isInvalid={!!errors.description}
                      as="textarea"
                      rows={3}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Label>Select Category</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={handleChange}
                    name="category"
                    className="form-control"
                    value={values.category}
                    isInvalid={!!errors.category}
                  >
                    <option value="">Select Category</option>
                    {!!getEnabledCategoriesData?.list &&
                      getEnabledCategoriesData.list.map((data, index) => {
                        return <option value={data.id} key={index}>{data.name}</option>;
                      })}
                  </Form.Control>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col md={12}>
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    as="select"
                    onChange={async (e) => {
                      setFieldValue("status", e.target.value);
                    }}
                    name="status"
                    className="form-control"
                    value={values.status}
                    isInvalid={!!errors.status}
                  >
                    <option value="">Select Status</option>
                    <option value={true} selected>
                      True
                    </option>
                    <option value={false}>False</option>
                  </Form.Control>
                </Col>
              </Row>
              <Modal.Footer>
                <Button variant="success" type="submit">
                  Submit
                </Button>
              </Modal.Footer>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}

export default Add;
