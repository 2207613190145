import React, { useEffect } from "react";
import { Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import * as yup from "yup";
import SBK from "../../images/sbka-munafa.png";
import {
  addNewProductRequest,
  getCatWiseProducts,
  getFrontEndbrokers,
  getStates,
  transpoterSignup,
} from "../../reducers/commonReducer";
import AppHeader from "../../Components/AppHeader";

const schema = yup.object().shape({
  name: yup.string().required(),
  phone: yup.string().min(10).max(10).required(),
  email: yup.string().email("Invalid email address").required(),
  product: yup.string().required(),
  discretion: yup.string().required(),
  category: yup.string().required(),
});

const RequestNewProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(getStates());
    dispatch(getFrontEndbrokers());
    dispatch(getCatWiseProducts());
  }, []);

  return (
    <>
      <AppHeader />
      <div className="content sign-up">
        <div className="row sign-up">
          <div className="col-md-5  login-form p-4">
            <div className="text-center mt-2">
              <img src={SBK} alt="logo" />
            </div>
            <div className="text-center">
              <p>
                <b>Request For New Product</b>
              </p>
            </div>

            <Formik
              validationSchema={schema}
              onSubmit={(values, { resetForm }) => {
                dispatch(addNewProductRequest(values));
                resetForm({ values: "" });
              }}
              initialValues={{
                product: "",
                discretion: "",
                name: "",
                phone: "",
                email: "",
                category: "",
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="col-12 mt-4">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-person-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!errors.name}
                          className="form-control"
                          placeholder="Enter Name"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12 mt-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-envelope-fill"></i>
                        </div>
                        <Form.Control
                          type="email"
                          name="email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email}
                          className="form-control"
                          placeholder="Enter Email"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12 mt-2 mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-phone-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="phone"
                          value={values.phone}
                          onChange={handleChange}
                          isInvalid={!!errors.phone}
                          className="form-control"
                          placeholder="Enter Phone"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.phone}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12  mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-bag-check-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="product"
                          value={values.product}
                          onChange={handleChange}
                          isInvalid={!!errors.product}
                          className="form-control"
                          placeholder="Enter Product Name"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.product}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12  mb-2">
                    <Form.Group as={Col} md="12">
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-bookmark-plus-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="category"
                          value={values.category}
                          onChange={handleChange}
                          isInvalid={!!errors.category}
                          className="form-control"
                          placeholder="Product Category"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.category}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12  mb-2">
                    <div className="input-group">
                      <div className="input-group-text">
                        <i className="bi bi-pen-fill"></i>
                      </div>
                      <Form.Control
                        type="text"
                        name="discretion"
                        value={values.discretion}
                        onChange={handleChange}
                        isInvalid={!!errors.discretion}
                        className="form-control"
                        placeholder="Write Product desc...."
                        as="textarea"
                        rows={3}
                        required
                      />
                    </div>
                    <Form.Control.Feedback type="invalid">
                      {errors.discretion}
                    </Form.Control.Feedback>
                  </div>
                  <div className="col-12 text-center mt-5">
                    <Button className="btn-color w-100" type="submit">
                      Submit
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
          <div className="col-12 text-center">
            <p>
              <Link to={"/"}>
                <b>
                  <i className="bi bi-arrow-left"></i> Back to Home
                </b>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequestNewProduct;
