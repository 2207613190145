import React, { useEffect } from "react";
import { Col, Button } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import * as yup from "yup";
import SBK from "../../images/sbka-munafa.png";
import { useDispatch, useSelector } from "react-redux";
import { agentDetails } from "../../reducers/commonReducer";
import AppHeader from "../../Components/AppHeader";

const schema = yup.object().shape({
  id: yup.string().required(),
  name: yup.string().required(),
  bankname: yup.string().required(),
  acountNo: yup.string().required(),
  ifsc: yup.string().required(),
});

const AgentDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const apiresp = async (values) => {
    const apiResponse = await dispatch(agentDetails(values));
    if (apiResponse?.payload?.status === 1) {
      return setTimeout(() => {
        window.location.replace(
          "https://play.google.com/store/apps/details?id=com.havfly.live_score"
        );
      }, 1000);
    }
    return null;
  };
  const { userProfileData } = useSelector((state) => state.commonReducer);
  useEffect(
    () =>
      !!userProfileData?.acountNo &&
      userProfileData?.acountNo?.length &&
      window.location.replace(
        "https://play.google.com/store/apps/details?id=com.havfly.live_score"
      ),
    [!!userProfileData?.acountNo && userProfileData?.acountNo?.length > 0]
  );
  return (
    <>
      <AppHeader />
      <div className="content login-bg">
        <div className="row">
          <div className="col-md-5  login-form p-4">
            <div className="text-center mt-2">
              <img src={SBK} alt="logo" />
            </div>
            <div className="text-center">
              <p>
                <b> Agent Details </b>
              </p>
            </div>

            <Formik
              validationSchema={schema}
              onSubmit={(values, { resetForm }) => {
                apiresp(values);
                resetForm({ values: "" });
              }}
              initialValues={{
                id: id,
                name: "",
                bankname: "",
                acountNo: "",
                ifsc: "",
              }}
            >
              {({ handleSubmit, handleChange, values, errors }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <div className="col-12 mb-2">
                    <Form.Group as={Col} md="12">
                      <Form.Label>Account Holder Name</Form.Label>
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-person-circle"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!errors.name}
                          className="form-control"
                          placeholder="Account Holder Name"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12 mb-2">
                    <Form.Group as={Col} md="12">
                      <Form.Label>Bank Name</Form.Label>
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-bank2"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="bankname"
                          value={values.bankname}
                          onChange={handleChange}
                          isInvalid={!!errors.bankname}
                          className="form-control"
                          placeholder="Bank Name"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.bankname}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12 mb-2">
                    <Form.Group as={Col} md="12">
                      <Form.Label>Acount No.</Form.Label>
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-0-circle-fill"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="acountNo"
                          value={values.acountNo}
                          onChange={handleChange}
                          isInvalid={!!errors.acountNo}
                          className="form-control"
                          placeholder="Account Number"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.acountNo}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12 mb-2">
                    <Form.Group as={Col} md="12">
                      <Form.Label>IFSC</Form.Label>
                      <div className="input-group">
                        <div className="input-group-text">
                          <i className="bi bi-code"></i>
                        </div>
                        <Form.Control
                          type="text"
                          name="ifsc"
                          value={values.ifsc}
                          onChange={handleChange}
                          isInvalid={!!errors.ifsc}
                          className="form-control"
                          placeholder="Enter IFSC"
                          required
                        />
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.ifsc}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="col-12 text-center mt-3">
                    <Button className="btn-color w-100" type="submit">
                      Submit Details
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentDetails;
