import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "react-bootstrap/Table";
import Header from "../../Components/Header";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { getPosts, getEnabledProducts, restRecord } from "../../reducers/commonReducer";
import Add from "./Add";
import moment from "moment";
import red from "../../images/red.png";
import green from "../../images/green.png";

const List = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const queryParameters = new URLSearchParams(window.location.search);
  const product = queryParameters.get("product");
  const category = queryParameters.get("category");
  const userid = queryParameters.get("userid");
  const type = queryParameters.get("type");

  useEffect(() => {
    dispatch(restRecord())
    dispatch(getPosts({product, category, userid, type}));
    dispatch(getEnabledProducts());
    if (!localStorage.getItem("x-auth-token")) {
      navigate("/");
    }
  }, []);
  const { getPostsData, getPostsBlank } = useSelector(
    (state) => state.commonReducer
  );
  const [show, setShow] = useState(false);
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [search, setSearch] = useState();
  const [postType, setPostType] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };
  return (
    <>
      <Header />
      <div className="container">
        <Row className="mt-4 mb-2 user-table">
          <div className="col-md-6 col-6">
            <h3>Posts List</h3>
          </div>
          <div className="col-md-6 text-end col-6">
            <Button
              className="mb-3 mobile-width submit-buttom"
              onClick={handleShow}
            >
              Add Product
            </Button>
          </div>
          <div className="col-md-12">
                  <Row className=" mb-3 border pt-3 pb-3 justify-content-between">
                    <Col md={2} sm={12}>
                      <Form.Label>Product Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Search by Product"
                        name="search"
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                      />
                    </Col>
                    <Col md={2} sm={12}>
                      <Form.Label>Type</Form.Label>
                      <Form.Control
                        as="select"
                        name="type"
                        value={postType}
                        onChange={(e) => setPostType(e.target.value)}
                        className="form-control"
                      >
                        <option value={""}>Select Type</option>
                        <option value="general">General</option>
                        <option value="sell">Sell</option>
                        <option value="buy">Buy</option>
                      </Form.Control>
                    </Col>
                    <Col md={2} sm={12}>
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="startDate"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                      />
                    </Col>
                    <Col md={2} sm={12}>
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="endDate"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                      />
                    </Col>
                    <Col md={2} sm={12}>
                      <Form.Label>Click to filter</Form.Label>
                      <Form.Control
                        type="submit"
                        placeholder="Filter"
                        name="filter"
                        className="btn submit-buttom"
                        onClick={() => {
                          dispatch(restRecord())
                          dispatch(getPosts({
                            startDate, endDate, search, postType
                          }));
                        }}
                      />
                    </Col>
                    <Col md={2} sm={12}>
                      <Form.Label className="d-none">Clear</Form.Label><br/>
                      <Button onClick={() => window.location.reload()} variant="danger" className="mt-2"><i className="bi bi-arrow-clockwise"></i> Clear</Button>
                    </Col>
                  </Row>
          </div>
          <div className="overflow-auto mt-4 border">
            <Table striped hover size="sm" className="overflow-scroll">
              <thead>
                <tr>
                  <th>Sr</th>
                  <th>Title</th>
                  <th>Type</th>
                  <th>User</th>
                  <th>Qty</th>
                  <th>Start</th>
                  <th>End</th>
                  <th>City</th>
                  <th>State</th>
                  <th>Orders</th>
                  <th>Product</th>
                  <th>Status</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {!!getPostsData &&
                  getPostsData.map((data, index) => {
                    return (
                      <tr key={data.id}>
                        <td>{index + 1}.</td>
                        <td><b onClick={() => openInNewTab(`/blog-details?postid=${data?.id}`)}>{data?.title}</b></td>
                        <td>{data?.type}</td>
                        <td>{data?.user?.username}</td>
                        <td>{data?.qty}</td>
                        <td>{moment(data?.start_time).format("DD-MM-YY")}</td>
                        <td>{moment(data?.end_time).format("DD-MM-YY")}</td>
                         <td>{data?.city}</td>
                        <td>{data?.state}</td>
                        <td>{data?.orders}</td>
                        <td>{data?.product?.name}</td>
                        <td className="table-img">
                          {data?.status === true ? (
                            <img src={green} />
                          ) : (
                            <img src={red} />
                          )}
                        </td>
                        {/* <td>
                          <Update
                            title="Update Post"
                            // name={data?.name}
                            // description={data?.description}
                            // category={data?.category.id}
                            // status={data?.status}
                            // id={data?.id}
                          />
                        </td> */}
                      </tr>
                    );
                  })}
              </tbody>
            </Table>
            {!!getPostsBlank && getPostsBlank.length > 0 ? (
                <Row>
                  <Button
                    className="w-100"
                    onClick={() => {
                      setPage(page + 1);
                      dispatch(getPosts({
                        search, postType, endDate, startDate, page: page + 1,
                      }));
                    }}
                  >
                    Load More
                  </Button>
                </Row>
              ) : (
                <p className="text-center">
                  <b>No record found 😔.</b>
                </p>
              )}
          </div>
        </Row>
      </div>

      <Add showhide={show} handleClose={handleClose} />
    </>
  );
};

export default List;
